import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
// import 'assets/scss/chatscope.scss';
import { Avatar, ConversationList, Conversation } from "@chatscope/chat-ui-kit-react";
import { redirect, useNavigate, useRouteLoaderData } from "react-router-dom";
import { getChatList } from "api/chat/getChatList";
import styles from "../components/pages/Profile/profile.module.css";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "Root";
import { DeviceContext } from "context/DeviceContext";
import ChatHeader from "components/header/parts/Mobile/Parts/ChatHeader";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import React from "react";
import { Helmet } from "react-helmet";

export async function loader() {
	const localUser = JSON.parse(localStorage.getItem("user"));

	if (localUser) {
		const response = await getChatList(localUser.basic);
		if (response?.success) {
			const list = response.chat_list;
			return { list };
		} else {
			return redirect(`/?show_login=true&from=${window.location.pathname}`);
		}
	} else {
		return redirect(`/?show_login=true&from=${window.location.pathname}`);
	}
}

export default function ChatList() {
	const { list } = useRouteLoaderData("chat");
	const [state, setState] = useState(list);

	const navigate = useNavigate();
	const localUser = JSON.parse(localStorage.getItem("user"));
	const socket = useContext(SocketContext);

	const device = useContext(DeviceContext);

	useEffect(() => {
		socket.on("newUnread", () => {
			getChatList(localUser.basic)
				.then(data => {
					if (data.success) {
						setState(data.chat_list);
					}
				});
		});
	}, [socket, localUser]);

	return (
		<>
			{device === "Mobile" && <ChatHeader />}
			<Helmet>
				<title>Личный кабинет - Сообщения</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<div className={styles.right}>
				<div className={styles.chatList}>
					{device === "Desktop" && <h2 className={styles.title}>Сообщения</h2>}
					{state.length === 0 && <div style={{fontSize: 20, color: "var(--main-color-placeholder)", padding: device === "Mobile" ? "8px 16px" : 0}}>У вас пока нету чатов</div>}
					{state.length !== 0 && <ConversationList>
						{state.sort((a, b) => {
							let aDate = new Date(Date.parse(a?.last_message?.date));
							let bDate = new Date(Date.parse(b?.last_message?.date));
							return bDate - aDate;
						}).map(item => {
							return (
								<Conversation key={item.id} onClick={() => navigate(`/profile/chat/${item.id}`)} unreadCnt={item.unread_messages}>
									<Avatar src={item.conversation.avatar} name="Lilly" />
									<Conversation.Content>
										<div style={{
											fontWeight: 700,
											marginBottom: 4
										}}>{item.conversation.name}</div>
										<div style={{
											marginBottom: 4
										}}>{item.conversation.title}</div>
										<div style={{
											fontSize: 14,
											color: "var(--main-color-placeholder)"
										}}>{item.last_message && parse(DOMPurify.sanitize(`${parseInt(item.last_message.sender_id) === parseInt(localUser.id) ? "Вы" : item.conversation.name}: ${item.last_message.message}`))}</div>
									</Conversation.Content>
								</Conversation>
							);
						})}
					</ConversationList>}
				</div>
			</div>
		</>
	);
}