import React from "react";
import styles from "../form.module.css";

export default function TextareaGroup({ state, name, onChange, text, type, placeholder, maxHeight = false }) {
	function changeHandler(e) {
		onChange(e);
		e.target.style.height = "auto";
		e.target.style.height = `${e.target.scrollHeight}px`;
	}

	return (
		<label className={styles.inputGroup}>{text}
			<textarea style={{ maxHeight: maxHeight ? maxHeight : "none" }} type={type} name={name} onChange={(e) => changeHandler(e)} value={state[name].value} placeholder={placeholder} />
			{state[name].showError && <span className={styles.errorText}>{state[name].errorText}</span>}
		</label>
	);
}