import React from "react";
import { useContext } from "react";
import styles from "../../create-ads.module.css";
import { StepContext } from "../../CreateAdsPage";

export default function StepNav() {
	const { step, setStep, stepList } = useContext(StepContext);
	const navJsx = [];

	for (let item in stepList) {
		navJsx.push(<div onClick={() => { if (!stepList[item].isBlock) setStep(item); }} key={item} className={styles.navlink + (step === item ? ` ${styles.current}` : "") + (!stepList[item].isBlock ? ` ${styles.active}` : "")}><span>{item}</span>{stepList[item].name}</div>);
	}

	return (
		<nav className={styles.nav}>
			{navJsx}
		</nav>
	);
}