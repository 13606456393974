import React from "react";
import styles from "../ads.module.css";

import locationIcon from "assets/images/location-blue.svg";
import eyeIcon from "assets/images/eye.svg";
import calendarIcon from "assets/images/calendar.svg";
import clockIcon from "assets/images/clock.svg";

export default function AdsInfoBlock({ data }) {
	const address = JSON.parse(data.address);

	const date = new Date(data.gmt_date * 1000);
	const time = `0${date.getHours()}`.slice(-2) + ":" + `0${date.getMinutes()}`.slice(-2);

	return (
		<div className={styles.infoBlock}>
			<div className={`${styles.infoItem} ${styles.location}`} onClick={() => window.scrollTo({
				top: document.getElementById("map").getBoundingClientRect().top - document.body.getBoundingClientRect().top - 150
			})}>
				<img src={locationIcon} alt="Location icon" />
				{address.value}
			</div>

			<div className={`${styles.infoItem}`}>
				<img src={calendarIcon} alt="Calendar icon" />
				{data.post_date}
			</div>
			<div className={`${styles.infoItem}`}>
				<img src={clockIcon} alt="Clock icon" />
				{time}
			</div>
			<div className={`${styles.infoItem}`}>
				<img src={eyeIcon} alt="Eye icon" />
				{data.views} просмотра
			</div>
		</div>
	);
}