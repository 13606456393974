import React from "react";
import AdsList from "components/ads/AdsList";
import styles from "./main.module.css";
import MainCategories from "./parts/MainCategories";
import { useContext } from "react";
import { DeviceContext } from "context/DeviceContext";
import AdsLast from "components/ads/AdsLast";
import AdsFavorites from "components/ads/AdsFavorites";
import { useLoaderData } from "react-router-dom";
import { FiltersDrawerContext } from "routes/main";
import StoriesList from "components/stories/StoriesList";

export default function MainPage() {
	const device = useContext(DeviceContext);
	const { categories } = useLoaderData();
	const filtersDrawer = useContext(FiltersDrawerContext);

	return (
		<div className={`container ${styles.container}`}>
			<div className={styles.left}>
				<div className={styles.section}>
					<h3 className={styles.title}>Категории</h3>
					<MainCategories categories={categories} />
				</div>
				<div className={styles.section}>
					<h3 className={styles.title}>Это интересно</h3>
					<StoriesList />
				</div>
				<AdsList parentStyles={styles} category={0} propSort={filtersDrawer.sort} />
			</div>
			{device === "Desktop" &&
				<div className={styles.right}>
					<AdsFavorites styles={styles} />
					<AdsLast styles={styles} />
				</div>
			}
		</div>
	);
}