import React from "react";
import { useState, useEffect } from "react";

import styles from "./catalog.module.css";

import CategoryLink from "./parts/CategoryLink";
import Subcategory from "./parts/Subcategory";

export default function Catalog({ showCatalog, setShowCatalog }) {
	const [category, setCategory] = useState("");
	const [categoryList, setCategoryList] = useState({});

	const fetchCategoryList = () => {
		fetch("https://api.funtik.su/wp-json/funtik/v1/categories")
			.then(response => response.json())
			.then(data => setCategoryList(data));
	};

	useEffect(() => {
		fetchCategoryList();
	}, []);

	// Выбираем первую категорию (возоможно нужна проверка)
	useEffect(() => {
		setCategory(Object.keys(categoryList)[0]);
	}, [categoryList]);

	// Список ссылок
	let categoryListJsx = [];
	for (let item in categoryList) {
		categoryListJsx.push(
			<CategoryLink
				key={item}
				name={categoryList[item].name}
				icon={categoryList[item].icon}
				category={item}
				changeCategory={setCategory}
				selectedCategory={category}
			/>
		);
	}

	return (
		<div
			className={styles.catalogWrapper + (showCatalog ? ` ${styles.show}` : "")}
			onClick={() => setShowCatalog(false)}
		>
			<div
				className={styles.catalog}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={styles.catalogContainer + " container"}>
					<ul className={styles.categories}>
						{categoryListJsx}
					</ul>
					<div className={styles.line}></div>
					{categoryList[category] && <Subcategory category={category} categoryObj={categoryList[category]} />}
				</div>
			</div>
		</div>
	);
}