import React from "react";
import { useState } from "react";
import styles from "./subcategory.module.css";
import { NavLink } from "react-router-dom";

export default function SubcategorySidebar({ name, categories, subcategory }) {
	const [openCategory, setOpenCategory] = useState(subcategory);
	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>{name}</h3>
			<div className={styles.categories}>
				{categories.map((category) =>
					<div key={category.term_id} className={`${styles.category} ${openCategory.term_id === category.term_id ? styles.open : ""}`} onClick={() => setOpenCategory(category)}>
						<div className={styles.categoryName}>
							{category.name}
						</div>
						<div className={styles.subcategories} onClick={e => e.stopPropagation()}>
							{category.children.map(subcat =>
								<NavLink
									// reloadDocument={true}
									to={subcat.link}
									key={subcat.term_id}
									className={({ isActive }) =>
										isActive ? `${styles.subcategory} ${styles.selected}` : `${styles.subcategory}`
									}
								>
									{subcat.name}
								</NavLink>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}