import React from "react";
import styles from "../User/user.module.css";
import adsStyles from "../../ads/ads.module.css";
import AdsCard from "components/ads/parts/AdsCard";
import { useContext } from "react";
import { DeviceContext } from "context/DeviceContext";
import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { getUserAds } from "api/getUserAds";
import arrowIcon from "assets/images/arrow-left-blue.svg";
import { Helmet } from "react-helmet";
import { getUserPage } from "api/getUserPage";

export async function loader({ params }) {

	const response = await getUserAds(params.id);
	const activeProfilePosts = response.active_ads;
	const user = await getUserPage(params.id)
		.then(data => {
			if(data.success) {
				return data.user;
			} else {
				throw new Error("Пользователь не найден");
			}
		})
		.catch(e => {
			throw new Error(e);
		});

	return { activeProfilePosts, user };
}

export default function UserAds() {
	const { activeProfilePosts, user } = useLoaderData();
	const device = useContext(DeviceContext);
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<>
			<Helmet>
				<title>{user.name} - Объявления пользователя | Фунтик</title>
				<meta name="description" content={`Профиль пользователя ${user.name} в сервисе Фунтик. Объявления и отзывы пользователя ${user.name}`}/>
				<meta property="og:description" content={`Профиль пользователя ${user.name} в сервисе Фунтик. Объявления и отзывы пользователя ${user.name}`}/>
			</Helmet>
			{
				device === "Desktop" ? (
					<div className={`container ${styles.container}`}>
						<div className={styles.left}>
							<div className={styles.stickyContainer}>
								<Link to={location.pathname.replace("/ads", "")} className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться в пользователя</Link>
							</div>
						</div>
						<div className={`${styles.right} ${styles.profileAds}`}>
							<h2 className={styles.name}>Объявления пользователя</h2>
							<div className={styles.profileAdsContainer}>
								<div className={`${styles.profileAdsList} ${adsStyles.grid}`}>
									{activeProfilePosts.map(item => <AdsCard post={item} key={item.id} useLayout={false} />)}
								</div>
								{activeProfilePosts.length === 0 && <div>У пользователя нет объявлений</div>}
							</div>
						</div>
					</div>
				) : (
					<>
						<div className={styles.profileAdsHeader}>
							<div className={styles.back} onClick={() => navigate(location.pathname.replace("/ads", ""))}></div>
							<h2 className={styles.title}>Объявления пользователя</h2>
						</div>
						<div className={styles.profileAdsContent} style={{ marginBottom: 48 }}>
							<div className={styles.profileAdsContainer}>
								<div className={`${styles.profileAdsList} ${adsStyles.horizontal}`}>
									{activeProfilePosts.map(item => <AdsCard post={item} key={item.id} useLayout={true} />)}
								</div>
								{activeProfilePosts.length === 0 && <div>У пользователя нет объявлений</div>}
							</div>
						</div>
					</>
				)
			}
		</>
	);
}