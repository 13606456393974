import React from "react";
import { searchCity } from "api/city";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";

export default function CityForm({ query, styles, callback }) {
	const [state, setState] = useState({
		data: [],
		listJsx: [],
		message: "Ничего не найдено"
	});

	const [loading, setLoading] = useState(false);

	//eslint-disable-next-line
	const debounceFilter = useCallback(
		debounce(query => {
			searchCity(query)
				.then(data => setState(s => { return { ...s, data: JSON.parse(data) }; }))
				.then(d => { !d?.suggestions && setState(s => { return { ...s, message: "Ничего не найдено" }; }); })
				.then(() => setLoading(false));
		}, 500),
		[]
	);

	useEffect(() => {
		let ignore = false;
		query === "" && setState(s => { return { ...s, message: "Начните вводить адрес", data: [] }; });

		const fetching = async () => {
			if (!ignore && query) {
				setLoading(true);
				await debounceFilter(query);
			}
		};

		fetching();

		return () => {
			ignore = true;
		};
	}, [query, debounceFilter]);

	useEffect(() => {
		let list = [];

		for (let item in state.data.suggestions) {
			let obj = state.data.suggestions[item];
			if (obj.data.fias_level === "65") continue;
			list.push(
				<div key={item} className={styles.address} onClick={loading ? () => false : () => callback(obj)}>
					{obj.value}
					{obj.data.fias_level !== "1" && <span className={styles.addressCity}>
						{obj.data.region_with_type}
					</span>}
				</div>);
		}

		setState(s => { return { ...s, listJsx: list }; });
	}, [state.data, styles, callback, loading]);

	return (
		<div className={`${styles.popupContent + (loading ? ` ${styles.loading}` : "")} ${styles.addressContent}`}>
			<div className={styles.addressList}>
				{(query && state.listJsx.length && state.listJsx) || <div className={styles.addressMessage}>{state.message}</div>}
			</div>
		</div>
	);
}