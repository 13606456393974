import { setFormData } from "./methods";

export async function editProfile(body, basic) {
	const data = setFormData({
		name: body.name,
		phone: body.phone,
		description: body.description,
		avatar: body.avatar,
		background: body.background,
	});

	return fetch("https://api.funtik.su/wp-json/funtik/v1/profile", {
		method: "POST",
		body: data,
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => { return data; })
		.catch(e => {
			return e;
		});
}