import React from "react";
import styles from "../catalog.module.css";

export default function CategoryLink({ icon, name, category, changeCategory, selectedCategory }) {
	let isSelect = (selectedCategory === category);

	return (
		<li className={styles.category + (isSelect ? ` ${styles.selected}` : "")} onClick={() => changeCategory(category)}>
			<img src={icon} alt="Icon" />
			{name}
		</li>
	);
}