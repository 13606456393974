import React from "react";
import { useState, useEffect } from "react";

import Button from "components/buttons/Button";
import burgerIcon from "assets/images/catalog-button.svg";
import crossIcon from "assets/images/cross.svg";

export default function CatalogButton({ showCatalog, onClick }) {
	const [icon, setIcon] = useState(burgerIcon);

	useEffect(() => {
		setIcon(!showCatalog ? burgerIcon : crossIcon);
	}, [showCatalog]);

	return (
		<Button name="Каталог" color="blue" icon={icon} onClick={onClick} />
	);
}