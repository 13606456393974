// Работает только в newChat.jsx

export async function getConversation(adsId) {

	return fetch(`https://api.funtik.su/wp-json/funtik/v1/conversation/${adsId}`, {
		method: "GET",
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}