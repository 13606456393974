import React, { useEffect, useState } from "react";
import Overlay from "./Overlay";
import { getCategories } from "api/getCategories";
import styles from "./overlay.module.css";
import arrowIcon from "assets/images/arrow-black-down.svg";
import arrowBlueIcon from "assets/images/arrow-blue-up.svg";
import { Link } from "react-router-dom";

export default function CategoryOverlay({ closeEvent }) {
	const [state, setState] = useState({
		jsx: <div style={{ padding: "16px 32px", fontWeight: 700, color: "var(--main-color-green)" }}>Загрузка...</div>,
		opens: []
	});

	useEffect(() => {
		let ignore = false;

		getCategories()
			.then(categories => {
				if (categories && !ignore) {
					let jsx =
						<div className={styles.categories}>
							{Object.keys(categories).map(cat => {

								let isOpen = state.opens.includes(cat);
								let catObj = categories[cat];

								return (
									<div
										key={catObj.term_id}
										className={`${styles.category} ${isOpen ? styles.open : ""}`}
										onClick={(e) => {
											e.stopPropagation();
											setState(s => {
												return {
													...s,
													opens: isOpen ? s.opens.filter(item => item !== cat) : [...s.opens, cat] // Открывает или закрывает категорию
												};
											});
										}}
									>
										<div className={styles.categoryContent}>
											{catObj.name}
											<img className={styles.categoryArrow} src={isOpen ? arrowBlueIcon : arrowIcon} alt="" />
										</div>
										{isOpen &&
											<div className={styles.subcategories}>
												{Object.keys(catObj.children).map(subcategory => {
													let subcatObj = catObj.children[subcategory];
													let isOpen = state.opens.includes(subcategory);
													return (
														<React.Fragment key={subcatObj.term_id}>
															<div
																className={styles.subcategory}
																onClick={(e) => {
																	e.stopPropagation();
																	setState(s => {
																		return {
																			...s,
																			opens: [...s.opens, subcategory]
																		};
																	});
																}}
															>
																{subcatObj.name}</div>
															{isOpen &&
																<Overlay
																	title={`${catObj.name} / ${subcatObj.name}`}
																	backEvent={() => {
																		console.log(state);
																		setState(s => {
																			return {
																				...s,
																				opens: s.opens.filter(item => item !== subcategory)
																			};
																		});
																	}}
																	stopPropogation={true}
																	container={styles.categoriesContainer}
																>
																	<div
																		className={styles.subcatLinksList}
																	>
																		<Link
																			to={subcatObj.link[1].to}
																			className={styles.subcatLinks}
																		>
																			{subcatObj.name}
																		</Link>
																		{
																			Object.keys(subcatObj.children).map(item => {
																				let obj = subcatObj.children[item];
																				return (
																					<Link
																						to={obj.link[2].to}
																						className={styles.subcatLinks}
																						key={obj.term_id}
																					>
																						{obj.name}
																					</Link>
																				);
																			})
																		}
																	</div>
																</Overlay>
															}
														</React.Fragment>
													);
												})}
											</div>
										}
									</div>
								);
							}
							)}
						</div>;
					setState(s => { return { ...s, jsx }; });
				}
			});

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
	}, [state.opens])

	return (
		<Overlay container={styles.categoriesContainer} backEvent={closeEvent} title='Категории / Все'>
			{state.jsx}
		</Overlay>
	);
}