import { getProfile } from "api/getProfile";
import ProfileSidebar from "components/pages/Profile/parts/ProfileSidebar";
import { Outlet, redirect, useRouteLoaderData } from "react-router-dom";
import styles from "../components/pages/User/user.module.css";
import { DeviceContext } from "context/DeviceContext";
import { createContext, useContext, useEffect, useState } from "react";
import ArchiveAds from "components/popups/ArchiveAds";
import React from "react";
import { Helmet } from "react-helmet";

export const ArchiveAdsContext = createContext();

export async function loader() {
	const parseUser = JSON.parse(localStorage.getItem("user"));

	if (parseUser) {		
		const response = await getProfile(parseUser.basic);

		if (response.success) {
			const userReviews = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${parseUser?.id}/reviews`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						return data.data;
					}
					throw new Error("Произошла ошибка");
				})
				.catch((e) => {
					throw new Error(e);
				});

			const user = response.user;
			return { user, userReviews};
		} else if (response?.data?.status === 401) {
			localStorage.removeItem("user");
			return redirect("/?show_login=true&from=/profile");
		} else {
			throw new Error(response.message);
		}
	} else {
		return redirect("/login");
	}
}

export default function Profile() {
	const { user } = useRouteLoaderData("profile");
	const device = useContext(DeviceContext);
	const [isArchiveAdsShow, setIsArchiveAdsShow] = useState(false);

	// Для попапа статистки
	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		if (showPopup) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflow = "";
		}
	}, [showPopup]);

	const [status, setStatus] = useState(null);

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Профиль</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			{device === "Desktop" ? (
				<ArchiveAdsContext.Provider value={{ isArchiveAdsShow, setIsArchiveAdsShow, status, setStatus, showPopup, setShowPopup }}>
					<div className={`container ${styles.container}`}>
						<div className={styles.left}>
							<ProfileSidebar user={user} />
						</div>
						<Outlet />
						{isArchiveAdsShow && <ArchiveAds />}
					</div>
				</ArchiveAdsContext.Provider>
			) : (
				<>
					<ArchiveAdsContext.Provider value={{ isArchiveAdsShow, setIsArchiveAdsShow, status, setStatus, showPopup, setShowPopup }}>
						<Outlet />
						{isArchiveAdsShow && <ArchiveAds />}
					</ArchiveAdsContext.Provider>
				</>
			)}
		</>
	);
    
    
    
}

