import React from "react";
import { login as loginAction } from "api/login";
import Button from "components/buttons/Button";

import buttonStyles from "components/buttons/button.module.css";
import { useAuth } from "hooks/useAuth";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from "../parts/InputGroup";

import styles from "../form.module.css";
import popupStyles from "../../popups/popup.module.css";
import formStyles from "../../forms/form.module.css";
import { DeviceContext } from "context/DeviceContext";
import PopupContainer from "components/popups/parts/PopupContainer";
import { setFormData } from "api/methods";
import { validateField } from "api/validation";
import { Link } from "react-router-dom";

export default function UserLogin({ closeEvent, changeForm, isAfterRegistration }) {
	const [showSuccessText, setShowSuccessText] = useState(isAfterRegistration);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { login } = useAuth();
	const device = useContext(DeviceContext);

	useEffect(() => {
		setShowSuccessText(isAfterRegistration);
	}, [isAfterRegistration]);

	const [state, setState] = useState({
		email: {
			value: "",
			name: "Email",
			required: true,
			type: "email",
			showError: false,
			errorText: "",
		},
		password: {
			value: "",
			name: "Пароль",
			required: true,
			type: "password",
			showError: false,
			errorText: "",
		}
	});

	const setError = (state, field, error) => {
		setState({
			...state,
			[field]: {
				...state[field],
				errorText: error,
				showError: true
			}
		});
		setLoading(false);
	};

	const validate = () => {
		setLoading(true);

		const data = {};

		for (let item in state) {
			let value = state[item].value;
			let required = state[item].required;

			if (value.length === 0 && required === true) {
				setError(state, item, `Поле ${state[item].name} является обязательным`);
				return false;
			} else {
				data[item] = state[item].value;
			}
		}
		return data;
	};

	const onChange = (e) => {
		setState({
			...state,
			[e.target.name]: {
				...state[e.target.name],
				value: e.target.value,
				showError: false
			}
		});
	};

	const onSubmit = async () => {
		setShowSuccessText(false);
		let data = validate(state);

		data && await loginAction(data)
			.then(async data => {
				if (data.success === true) {
					login(data.user);
					navigate(new URLSearchParams(window.location.search).get("from") || "/profile", { replace: true });
					closeEvent && closeEvent();
				} else {
					setError(state, "password", data.message);
				}
			})
			.catch(error => {
				setError(state, "password", error);
			});

	};

	// Восстановление пароля
	const [showResetForm, setShowResetForm] = useState(false);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [emailLoading, setEmailLoading] = useState(false);
	const [showCompleteText, setShowCompleteText] = useState(false);

	async function resetPassword() {
		setEmailLoading(true);
		if(!validateField("email", email.trim())) {
			setEmailError(true);
			setEmailLoading(false);
			return;
		} else {
			setEmailError(false);
		}

		fetch("https://api.funtik.su/wp-json/funtik/v1/user/reset_password", {
			method: "POST",
			body: setFormData({email})
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setEmailLoading(false);
					setShowResetForm(false);
					setShowCompleteText(true);
				} else {
					setEmailLoading(false);
					throw new Error(data.message);
				}
			})
			.catch((e) => {
				throw new Error(e);
			});
	}

	return (
		<>
			<div className={styles.inputs}>
				<InputGroup state={state} name="email" onChange={onChange} text="Email" type="email" placeholder="Введите Email" />
				<InputGroup state={state} name="password" onChange={onChange} text="Пароль" type="password" placeholder="Введите Пароль" />
				{showSuccessText && <span className={styles.successText}>Теперь Вы можете авторизироваться на сайте</span>}
				{showCompleteText && <span className={styles.successText}>Письмо для восстановлния пароля отправлено на Email</span>}
			</div>
			{device === "Mobile" && <div className="mobileGap"></div>}
			<Button name="Войти в аккаунт" className={buttonStyles.submitButton} onClick={onSubmit} loading={loading} />
			<div className={styles.formLink} onClick={changeForm}>Нет аккаунта? Зарегистрируйтесь</div>
			<div className={styles.formLink} onClick={() => setShowResetForm(true)}>Забыли пароль?</div>
			{device === "Mobile" && <Link to="/privacy-policy" className={styles.formLink} >Политика конфиденциальности</Link>}
			{/* Reset password */}
			{showResetForm && <div className={popupStyles.popupContainer} onClick={() => setShowResetForm(false)}>
				<PopupContainer title="Восстановление пароля" closeEvent={() => setShowResetForm(false)} style={{maxWidth: 440}}>
					<form onSubmit={e => {
						e.preventDefault();
						resetPassword();
					}}>
						<label className={formStyles.inputGroup} style={{marginBottom: 16}}>
							<input onChange={e => setEmail(e.target.value)} value={email} placeholder="Введите Email" />
							{emailError && <span className={formStyles.errorText}>Укажите действительный Email</span>}
						</label>
						<div className={styles.helpText}>Введите Email указанный при регистрации, на данный Email придет письмо с инструкцией по восстановлению пароля</div>
						<Button name="Отправить" showPreloader={emailLoading} className={buttonStyles.submitButton} onClick={resetPassword}/>
					</form>
				</PopupContainer>
			</div>}
		</>
	);
}