import { setFormData } from "../methods";

export async function createChat(adsId, basic) {
	const body = setFormData({
		adsId: adsId,
	});

	return fetch("https://api.funtik.su/wp-json/funtik/v1/chat/create", {
		method: "POST",
		body: body,
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}