import { Link } from "react-router-dom";
import styles from "./breadcrumbs.module.css";
import React from "react";

export default function Breadcrumbs({ links, current }) {
	return (

		<div className={styles.breadcrumbs}>
			{links.map((link, key) => <React.Fragment key={key}><Link to={link.to} className={styles.breadcrumbsLink}>{link.name}</Link> /</React.Fragment>)}
			<div className={`${styles.breadcrumbsLink} ${styles.current}`}>{current}</div>
		</div>
	);
}