import React, { useState } from "react";
import styles from "./user.module.css";

import userIcon from "assets/images/User-placeholder.svg";
import eyeIcon from "assets/images/eye-placeholder.svg";
import starIcon from "assets/images/star-fill.svg";
import commentIcon from "assets/images/comment-blue.svg";
import placeholder from "assets/images/User-gray.svg";
import backgroundPlaceholder from "assets/images/background-placeholder.png";

import Button from "components/buttons/Button";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { checkUser } from "api/checkUser";
// import commentActiveIcon from 'assets/images/comment-blue.svg';

export default function UserCard({ user, type = "" }) {
	const navigate = useNavigate();
	const {subscriptionList} = useLoaderData();
	const [subscribed, setSubscribed] = useState(subscriptionList?.includes(user.id));
	const [loading, setLoading] = useState(false);

	function subscribeHandler() {
		const localUser = JSON.parse(localStorage.getItem("user"));
		setLoading(true);
		
		checkUser(localUser?.basic)
			.then(data => {
				if (data.success) {
					fetch(`https://api.funtik.su/wp-json/funtik/v1/subscribe/${user.id}`, {
						method: "GET",
						headers: {
							Authorization: `Basic ${window.btoa(localUser?.basic)}`,
						},
					})
						.then((response) => response.json())
						.then((data) => {
							if (data.success) {
								setSubscribed(data.data.includes(user.id));
								setLoading(false);
							}
							throw new Error("Произошла ошибка");
						})
						.catch((e) => {
							throw new Error(e);
						});
				} else {
					navigate(`/?show_login=true&from=/user/${user.id}`);
				}
			})
			.catch(e => {throw new Error(e);});
	}

	return (
		<div className={`${styles.wrapper} ${type === "subscriber" ? styles.subscriber : ""}`}>
			{user.background ? <img src={user.background} className={styles.background} alt="" /> : <img src={backgroundPlaceholder} className={styles.background} alt="" />}
			{user.avatar ? <img src={user.avatar} className={styles.avatar} alt="" /> : <img src={placeholder} className={`${styles.avatar} ${styles.placeholder}`} alt="" />}
			<div className={styles.content}>
				<div className={styles.text}>
					<h4 className={styles.name}>{user.name}</h4>
					<div className={styles.info}>
						{type !== "subscriber" && <div className={styles.infoItem}>
							<img src={userIcon} alt="" />
							{user.subscribers} подписчиков
						</div>}
						{type !== "subscriber" && <div className={styles.infoItem}>
							<img src={eyeIcon} alt="" />
							{user.subscriptions} подписок
						</div>}
						<div className={styles.infoItem}>
							<img src={starIcon} alt="" />
							{user.rating} рейтинг
						</div>
						<Link to={`/user/${user.id}/reviews`} className={styles.infoItem} style={{color: "var(--main-color-blue)"}}>
							<img src={commentIcon} alt="" />
							{user.reviews} отзывов
						</Link>
					</div>
					<div className={styles.description}>{type === "subscriber" && user.description}</div>
				</div>
				{type === "profile" ? <Link to="/profile/edit" className={styles.editButton}>Редактировать профиль</Link> : 
					!subscribed ? <Button name="Подписаться" showPreloader={loading} onClick={subscribeHandler} color="blue" className={styles.button} /> : <Button name="Отписаться" onClick={subscribeHandler} showPreloader={loading} color="gray" className={styles.button} />}
			</div>
		</div>
	);
}