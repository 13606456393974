import { AuthContext } from "context/AuthContext";
import { useContext } from "react";

export const useAuth = () => {
	const { user, setUser } = useContext(AuthContext);

	const login = (data) => {
		setUser(data);
		localStorage.setItem("user", JSON.stringify(data));
	};

	const logout = () => {
		setUser(null);
		localStorage.removeItem("user");
	};

	return { user, login, logout };
};