import React from "react";
import { useLoaderData } from "react-router-dom";
import FilterGroup from "./parts/FilterGroup";

import styles from "./filters.module.css";
import { useContext } from "react";
import { FilterContext } from "routes/subcategory";
import Button from "components/buttons/Button";

export default function FiltersSidebar({ setListFilters }) {
	const { attributes } = useLoaderData();
	const filters = useContext(FilterContext);

	function renderFilters(attributes) {
		let list = [];

		for (let attr in attributes) {
			let attribute = attributes[attr];
			list.push(<FilterGroup key={attribute.id} attribute={attribute} />);
		}

		return list;
	}

	async function resetHandler() {
		filters.resetFilters();
		setListFilters(false);
	}

	return (
		<div className={styles.filter}>
			<h3 className={styles.filterTitle}>Фильтры</h3>
			<div className={styles.filterList}>
				<div className={styles.filterGroup}>
					<h4 className={styles.filterGroupTitle}>Цена</h4>
					<div className={styles.filterPrice}>
						<div className={styles.filterPriceInput}>
							<input type="text" value={filters.get.priceFrom.value} placeholder="От" onChange={(e) => filters.set(s => { return { ...s, priceFrom: { value: e.target.value } }; })} />
						</div>
						<div className={styles.filterPriceInput}>
							<input type="text" value={filters.get.priceTo.value} placeholder="до" className={styles.filterPriceInput} onChange={(e) => filters.set(s => { return { ...s, priceTo: { value: e.target.value } }; })} />
						</div>
					</div>
				</div>
				{renderFilters(attributes)}
				<div className={styles.filterButtons}>
					{filters.foundPosts !== 0 && <Button name={filters.loading ? "Загрузка" : `Показать ${filters.foundPosts} объявлений`} showPreloader={filters.loading} className={styles.filterButton} onClick={() => setListFilters(filters.setFiltersData())} />}
					{filters.foundPosts === 0 && <Button name="Ничего не найдено" color='disable' className={styles.disabledButton} showPreloader={filters.loading} />}
					<Button name="Очистить" color="white" className={styles.filterButton} onClick={resetHandler} />
				</div>
			</div>
		</div>
	);
}