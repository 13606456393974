import React from "react";
import { Link } from "react-router-dom";
import { useRouteLoaderData } from "react-router-dom";
import styles from "../profile.module.css";
import penIcon from "assets/images/pen-blue.svg";
import megaphoneIcon from "assets/images/Megaphone.svg";
import statsIcon from "assets/images/stats-blue.svg";
import { useContext } from "react";
import { ArchiveAdsContext } from "routes/profile";

import viewsIcon from "assets/images/views.svg";
import contactsIcon from "assets/images/contacts.svg";
import favoritesIcon from "assets/images/favorites.svg";

export default function ProfileMyAdsSidebar() {
	const { data } = useRouteLoaderData("profileMyAds");
	const { setIsArchiveAdsShow, status, setShowPopup } = useContext(ArchiveAdsContext);

	return (
		<>
			<div className={styles.sidebarButtons}>
				<Link to={`/profile/ads/${data.id}/edit`} className={styles.sidebarWhiteButton}><img src={penIcon} alt="" />Редактировать</Link>
				<div className={styles.sidebarWhiteButton} onClick={() => setShowPopup(true)}><img src={statsIcon} alt="" />Статистика</div>
				<div className={styles.sidebarWhiteButton} onClick={() => setIsArchiveAdsShow(true)}><img src={megaphoneIcon} alt="" />{status === "draft" ? "Опубликовать" : "Снять с публикации"}</div>
			</div>
			<div style={{display: "flex", flexDirection: "column", gap: 8}}>
				<div style={{display: "flex", alignItems: "center", gap: 8, color: "var(--main-color-blue)"}}>
					<img src={viewsIcon} alt='' width={16}/> {data.stats.views} просмотра
				</div>
				<div style={{display: "flex", alignItems: "center", gap: 8, color: "var(--main-color-blue)"}}>
					<img src={contactsIcon} alt='' width={16} /> {data.stats.contacts} контактов
				</div>
				<div style={{display: "flex", alignItems: "center", gap: 8, color: "var(--main-color-blue)"}}>
					<img src={favoritesIcon} alt='' width={16} /> {data.stats.favorites} добавили в избранное
				</div>
			</div>
		</>
	);
}