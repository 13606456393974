import "assets/scss/chatscope.scss";
import { MainContainer, ChatContainer, MessageList, MessageInput, Avatar, ConversationHeader } from "@chatscope/chat-ui-kit-react";
import styles from "../components/pages/Profile/profile.module.css";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { SocketContext } from "Root";
import { createChat } from "api/chat/createChat";
import { getConversation } from "api/chat/getConversation";
import { getChatByAds } from "api/chat/getChatByAds";
import React from "react";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const localUser = JSON.parse(localStorage.getItem("user"));
	const checkChat = await getChatByAds(params.id, localUser.basic);

	if (checkChat.success) {
		return redirect(`/profile/chat/${checkChat.chat.id}`);
	} else if (checkChat?.type === "error") {
		return redirect(`/ads/${params.id}`);
	}

	const response = await getConversation(params.id);
	if (response.success) {
		const conversation = response.data;
		const adsId = params.id;
		return { conversation, adsId };
	} else {
		throw new Error("Произошла ошибка");
	}
}

export default function NewChat() {
	const [messageInputValue, setMessageInputValue] = useState("");
	const { conversation, adsId } = useLoaderData();
	const navigate = useNavigate();
	const localUser = JSON.parse(localStorage.getItem("user"));
	const socket = useContext(SocketContext);

	async function handleSubmit(message) {
		if(message.length > 5000) return;
		const chat = await createChat(adsId, localUser.basic);

		if (message.trim()) {
			socket.emit("message", {
				text: message,
				user: localUser,
				chat: chat.chat.id,
				socketID: socket.id
			});
		}
		setMessageInputValue("");

		navigate(`/profile/chat/${chat.chat.id}`);
	}

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Сообщения</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<div className={styles.right}>
				<div className={styles.chatContainer}>
					<MainContainer responsive>
						<ChatContainer>
							<ConversationHeader>
								<ConversationHeader.Back onClick={() => navigate("/profile/chat")} />
								<Avatar src={conversation.post_image} name='' onClick={() => navigate(`/ads/${conversation.ads_id}`)} />
								<ConversationHeader.Content userName={conversation.title} info={conversation.name} />
							</ConversationHeader>
							<MessageList>

							</MessageList>
							<MessageInput attachButton={false} placeholder="Введите сообщение..." value={messageInputValue} onSend={handleSubmit} onChange={val => setMessageInputValue(val)} />
						</ChatContainer>
					</MainContainer>
				</div>
			</div>
		</>
	);
}