import React from "react";
import { useContext, useState } from "react";

import styles from "./popup.module.css";
import overlayStyles from "../overlay/overlay.module.css";

import { DeviceContext } from "context/DeviceContext";
import PopupContainer from "./parts/PopupContainer";
import Overlay from "components/overlay/Overlay";

import starEmptyIcon from "assets/images/star-empty.svg";
import starFillIcon from "assets/images/star-fill.svg";
import TextareaGroup from "components/forms/parts/TextareaGroup";
import Button from "components/buttons/Button";
import { setFormData } from "api/methods";
import { useNavigate } from "react-router-dom";

export default function NewReview({ setShowPopup, rating, id }) {
	const device = useContext(DeviceContext);
	const navigate = useNavigate();

	const [state, setState] = useState({
		rating,
		id,
		status: 0,
		comment: {
			value: "",
			showError: false,
			errorText: ""
		},
		loading: false
	});

	async function submitHandler() {
		const user = JSON.parse(localStorage.getItem("user"));

		setState(s => { return { ...s, comment: { ...s.comment, showError: false }, loading: true }; });

		if (state.comment.value.length === 0) {
			setState(s => { return { ...s, comment: { ...s.comment, showError: true, errorText: "Введите комментарий" }, loading: false }; });
			return;
		}

		if (state.comment.value.length > 2000) {
			setState(s => { return { ...s, comment: { ...s.comment, showError: true, errorText: "Количество символов в комментарии не должно превышать 2000" }, loading: false }; });
			return;
		}

		fetch("https://api.funtik.su/wp-json/funtik/v1/reviews/new", {
			method: "POST",
			headers: {
				"Authorization": `Basic ${window.btoa(user.basic)}`
			},
			body: setFormData({
				ads_id: state.id,
				rating: state.rating,
				status: state.status === 0 ? "complete" : "not complete",
				comment: state.comment.value
			})
		})
			.then(response => response.json())
			.then(data => {
				setState(s => { return { ...s, loading: false }; });
				if (data.success) {
					navigate("/profile/reviews/outcoming");
				} else {
					setState(s => { return { ...s, comment: { ...s.comment, showError: true, errorText: data.message, loading: false } }; });
				}
			})
			.catch(e => {
				setState(s => { return { ...s, comment: { ...s.comment, showError: true, errorText: e, loading: false } }; });
			});
	}

	return device === "Desktop" ? (
		<div className={`${styles.popupContainer}`} onClick={() => setShowPopup(false)}>
			<PopupContainer closeEvent={() => setShowPopup(false)} title="Новый отзыв" style={{ width: 660 }}>
				<div style={{ display: "flex", gap: 16, flexDirection: "column", marginTop: "-8px" }}>
					<div style={{ display: "flex", gap: 16 }}>
						{
							[1, 2, 3, 4, 5].map(value =>
								<img
									key={value}
									src={value <= state.rating ? starFillIcon : starEmptyIcon}
									alt=""
									className={`star-${value}`}
									onClick={() => {
										setState(s => { return { ...s, rating: value }; });
									}}
									style={{ width: 32, height: 32, cursor: "pointer" }}
								/>
							)
						}
					</div>
					<div>
						<div onClick={() => setState(s => { return { ...s, status: 0 }; })} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: 16, height: 48 }}>
							{state.status === 0 ? <div className={`${styles.radio} ${styles.selected}`}><div></div></div> : <div className={styles.radio}></div>}
                            Сделка состоялась
						</div>
						<div onClick={() => setState(s => { return { ...s, status: 1 }; })} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: 16, height: 48 }}>
							{state.status === 1 ? <div className={`${styles.radio} ${styles.selected}`}><div></div></div> : <div className={styles.radio}></div>}
                            Сделка не состоялась
						</div>
					</div>
					<TextareaGroup maxHeight={200} state={state} name="comment" text="Комментарий" type="text" placeholder="Введите комментарий" onChange={(e) => setState(s => { return { ...s, comment: { ...s.comment, value: e.target.value } }; })} />
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Button onClick={submitHandler} showPreloader={state.loading} name="Добавить отзыв" color="green" className={styles.submitReview} />
						<div style={{ color: "var(--main-color-placeholder)", fontSize: 18, lineHeight: 1.4, fontWeight: 500 }}><span style={{ color: state.comment.value.length > 2000 ? "var(--main-color-red)" : "var(--main-color-black)" }}>{state.comment.value.length}</span> / 2000</div>
					</div>
				</div>
			</PopupContainer>
		</div>
	) : (
		<Overlay backEvent={() => setShowPopup(false)} title="Новый отзыв" container={overlayStyles.attributes}>
			<div style={{ display: "flex", gap: 16, flexDirection: "column" }}>
				<div style={{ display: "flex", gap: 16 }}>
					{
						[1, 2, 3, 4, 5].map(value =>
							<img
								key={value}
								src={value <= state.rating ? starFillIcon : starEmptyIcon}
								alt=""
								className={`star-${value}`}
								onClick={() => {
									setState(s => { return { ...s, rating: value }; });
								}}
								style={{ width: 32, height: 32, cursor: "pointer" }}
							/>
						)
					}
				</div>
				<div>
					<div onClick={() => setState(s => { return { ...s, status: 0 }; })} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: 16, height: 48 }}>
						{state.status === 0 ? <div className={`${styles.radio} ${styles.selected}`}><div></div></div> : <div className={styles.radio}></div>}
                            Сделка состоялась
					</div>
					<div onClick={() => setState(s => { return { ...s, status: 1 }; })} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: 16, height: 48 }}>
						{state.status === 1 ? <div className={`${styles.radio} ${styles.selected}`}><div></div></div> : <div className={styles.radio}></div>}
                            Сделка не состоялась
					</div>
				</div>
				<TextareaGroup maxHeight={200} state={state} name="comment" text="Комментарий" type="text" placeholder="Введите комментарий" onChange={(e) => setState(s => { return { ...s, comment: { ...s.comment, value: e.target.value } }; })} />
				<div style={{ display: "flex", justifyContent: "end" }}>
					<div style={{ color: "var(--main-color-placeholder)", fontSize: 18, lineHeight: 1.4, fontWeight: 500 }}><span style={{ color: state.comment.value.length > 2000 ? "var(--main-color-red)" : "var(--main-color-black)" }}>{state.comment.value.length}</span> / 2000</div>
				</div>
				<div style={{position: "absolute", bottom: 0, left: 0, width: "100%", padding: 16, background: "var(--main-color-white)", borderTop: "1px solid var(--main-color-gray)"}}>
					<Button onClick={submitHandler} showPreloader={state.loading} name="Добавить отзыв" color="green" className={styles.submitReview} />
				</div>
			</div>
		</Overlay>
	);
}