import { setFormData } from "./methods";

export function login(data) {
	const body = setFormData(data);

	return fetch("https://api.funtik.su/wp-json/funtik/v1/login", {
		method: "POST",
		body: body
	})
		.then(response => response.json())
		.then(data => {
			return data;
		});
}