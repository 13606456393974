import React, { useContext, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

import logo from "assets/images/notification-logo.png";
import crossIcon from "assets/images/popup-cross.svg";
import { Link } from "react-router-dom";

import styles from "../User/user.module.css";
import StoriesItem from "components/stories/parts/StoriesItem";
import { DeviceContext } from "context/DeviceContext";
import headerStyles from "../../header/parts/Mobile/header-mobile.module.css";
import { Helmet } from "react-helmet";

export async function loader() {
	const user = JSON.parse(localStorage.getItem("user"));

	const notifications = await fetch("https://api.funtik.su/wp-json/funtik/v1/notifications", {
		method: "GET",
		headers: {
			Authorization: `Basic ${window.btoa(user.basic)}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error(data.message);
		})
		.catch((e) => {
			throw new Error(e);
		});

	return {notifications};
}

export default function ProfileNotifications() {
	const {notifications} = useLoaderData();
	const [state, setState] = useState(notifications);
	const [activeStory, setActiveStory] = useState(null);
	const device = useContext(DeviceContext);
	const navigate = useNavigate();

	function removeHandler(item) {
		console.log(item);
		const user = JSON.parse(localStorage.getItem("user"));
		setState(s => {return s.filter(i => i.id !== item.id);});
		
		fetch(`https://api.funtik.su/wp-json/funtik/v1/notifications/${item.id}/delete`, {
			method: "GET",
			headers: {
				Authorization: `Basic ${window.btoa(user.basic)}`,
			},
		});
	}

	return (<>
		<Helmet>
			<title>Личный кабинет - Оповещения</title>
			<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
		</Helmet>
    
		{device === "Mobile" && 
			<header className={headerStyles.header}>
				<div className={`${headerStyles.headerContainer} ${styles.line}`}>
					<div className={headerStyles.back} onClick={() => { window.history.length === 1 ? navigate("/") : navigate(-1); }}></div>
					<h2 className={`${headerStyles.headerTitle}`}>Оповещения</h2>
				</div>
			</header>}
		<div className={`${styles.right} ${styles.profileAds}`} style={device === "Mobile" ? {padding: "24px 16px", marginBottom: 48} : {}}>
			{device === "Desktop" && <h2 className={styles.name}>Оповещения</h2>}
			<div style={{display: "flex", flexDirection: "column", gap: 24}}>
				{state.map(item => 
					<div key={item.id} style={{display: "flex", alignItems: "center", gap: 12}}>
						{item.type === "stories" ? 
							<img src={logo} alt="" style={{width: 48, height: 48, objectFit: "cover", borderRadius: 24, border: "1px solid var(--main-color-gray)"}} /> : 
							<img src={item.user.avatar} alt="" style={{width: 48, height: 48, borderRadius: 24, objectFit: "cover"}} /> 
						}
						<div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: 48}}>
							{(()=>{
								switch(item.type) {
								case "stories": 
									return <div style={{fontWeight: 300, lineHeight: 1.4}}>Это интересно: <span onClick={() => setActiveStory(item.entity_id)} style={{fontWeight: 600, color: "var(--main-color-blue)", cursor: "pointer", textDecoration: "underline"}} >{item.name}</span></div>;
								case "ads":
									return <div style={{fontWeight: 300, lineHeight: 1.4}}><Link to={`/user/${item.user.id}`} style={{fontWeight: 600}}>{item.user.name}</Link> разместил-а новое объявление <Link style={{fontWeight: 600, color: "var(--main-color-blue)", cursor: "pointer", textDecoration: "underline"}} to={`/ads/${item.entity_id}`}>{item.name}</Link></div>;
								case "review": 
									return <div style={{fontWeight: 300, lineHeight: 1.4}}><Link to={`/user/${item.user.id}`} style={{fontWeight: 600}}>{item.user.name}</Link>  <Link style={{fontWeight: 600, color: "var(--main-color-blue)", cursor: "pointer", textDecoration: "underline"}}  to={`/profile/reviews/my/${item.entity_id}`}>оставил-а отзыв</Link> на ваше объявление</div>;
								case "answer":
									return <div style={{fontWeight: 300, lineHeight: 1.4}}><Link to={`/user/${item.user.id}`} style={{fontWeight: 600}}>{item.user.name}</Link>  ответил-а на <Link style={{fontWeight: 600, color: "var(--main-color-blue)", cursor: "pointer", textDecoration: "underline"}}  to={`/profile/reviews/outcoming/${item.entity_id}`}>ваш отзыв</Link></div>;
								}

							})()}
							<div style={{color: "var(--main-color-placeholder)", fontSize: 14, lineHeight: 1.4}}>
								{(() => {
									let d = new Date(Date.parse(item.date));
									return `${d.getDate()}.${(d.getMonth() + 1).toString().length === 1 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}.${d.getFullYear()} в ${d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes().toString().length === 1 ? `0${d.getMinutes()}` : d.getMinutes()}`;
								})()}
							</div>
						</div>
						<img src={crossIcon} alt="" style={{alignSelf: "flex-start", marginLeft: "auto", cursor: "pointer"}} onClick={() => removeHandler(item)}/>
						{item.type === "stories" && activeStory === item.entity_id && <StoriesItem setShowStory={() => setActiveStory(null)} id={item.entity_id} />}
					</div>
				)}
				{state.length === 0 && <div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>У вас пока нету оповещений</div>}
			</div>
		</div>
	</>
		
	);
}