import React from "react";
import styles from "../header-mobile.module.css";

export default function ChatHeader() {
	return (
		<header className={styles.header}>
			<div className={`${styles.headerContainer} ${styles.line}`}>
				<h2 className={`${styles.headerTitle}`}>Сообщения</h2>
			</div>
		</header>
	);
}