import { useNavigate } from "react-router-dom";

export function useChat() {
	const navigate = useNavigate();

	// Прикрепляем к кнопкам "Написать"
	function buttonHandler(adsId) {
		return new Promise((resolve) => {
			const localUser = JSON.parse(localStorage.getItem("user"));

			if (!localUser) {
				resolve(
					navigate(`${window.location.pathname}?show_login=true&from=${window.location.pathname}`)
				);
			} else {
				navigate(`/profile/chat/new/${adsId}`);
			}
		});
	}

	return { buttonHandler };
}