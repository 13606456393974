import React from "react";
import cameraIcon from "assets/images/camera.svg";
import crossIcon from "assets/images/cross-black.svg";
import { useContext, useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import Button from "components/buttons/Button";
import { DataContext, StepContext } from "components/pages/CreateAds/CreateAdsPage";

Fancybox.bind("[data-fancybox=\"photos\"]");

export default function SelectPhotos({ styles }) {
	const { setStep } = useContext(StepContext);
	const { data, setData } = useContext(DataContext);

	const [images, setImages] = useState([]);

	useEffect(() => {
		setData(s => { return { ...s, photosState: { ...s.photosState, error: null } }; });

		if (images.length < 1) return;

		const newImageUrls = [];
		images.forEach(image => {
			if (image.size > 1024 * 1024 * 3 || (image.type !== "image/jpeg" && image.type !== "image/png")) {
				setData(s => { return { ...s, photosState: { ...s.photosState, error: "Некоторые изображения не были загружены, допустимые форматы изображения: .jpg, .jpeg, .png, максимальный размер файла: 3МБ" } }; });
			} else {
				newImageUrls.push({ file: image, src: URL.createObjectURL(image) });
			}
		});
		setData(s => { return { ...s, photosState: { ...s.photosState, imagesUrls: [...s.photosState.imagesUrls, ...newImageUrls] } }; });
	}, [images, setData]);

	useEffect(() => {
		data.data.photosState.imagesUrls && data.data.photosState.imagesUrls.length > 0 && data.changeData("photos", "value", data.data.photosState.imagesUrls);
		// eslint-disable-next-line 
    }, [data.data.photosState.imagesUrls])

	function clickHandler() {
		setData(s => {
			return {
				...s, photosState: { ...s.photosState, error: null }
			};
		});

		if (data.data.photosState.imagesUrls.length > 10) {
			setData(s => {
				return {
					...s, photosState: { ...s.photosState, error: "Превышено максимальное количество загружаемых изображений, удалите лишние и повторите попытку" }
				};
			});
		} else if (data.data.photosState.imagesUrls.length === 0) {
			setData(s => {
				return {
					...s, photosState: { ...s.photosState, error: "Добавьте как минимум одно изображение" }
				};
			});
		} else {
			setStep("3");
		}
	}

	function removeImage(key) {
		setData(s => {
			return {
				...s, photosState: {
					...s.photosState,
					imagesUrls: s.photosState.imagesUrls.filter((i, k) => k !== key)
				}
			};
		});
	}

	return (
		<div>
			<h3 className={styles.subtitle}>Добавьте фотографии</h3>
			<div className={styles.photos}>
				{data.data.photosState.imagesUrls.map((img, key) => <div key={key} className={styles.photo}><img src={crossIcon} className={styles.deletePhoto} alt="Cross icon" onClick={() => { removeImage(key); }} /><img data-fancybox="photos" src={img.src} alt="" className={styles.photoImage} /></div>)}
				<label className={styles.labelPhoto}>
					<img src={cameraIcon} alt='Camera icon' />
					<span>Добавить фото</span>
					<input type="file" multiple onChange={(e) => setImages([...e.target.files])} accept="image/png,image/jpeg" />
				</label>
			</div>
			{data.data.photosState.error && <div className={styles.errorText}>{data.data.photosState.error}</div>}
			<div className={styles.photosBottom}>
				<div className={styles.infoText}>Параметры изображений: .jpg, .jpeg, .png, размер &lt; 3МБ</div>
				{data.data.photosState.imagesUrls && <div className={styles.photosCount}><span className={data.data.photosState.imagesUrls.length > 10 ? ` ${styles.countError}` : ""}>{data.data.photosState.imagesUrls.length}</span> / 10</div>}
			</div>
			<div className={styles.buttons}><Button name="Назад" className={styles.backButton} onClick={() => setStep("1")} /><Button name="Продолжить" className={styles.nextButton} onClick={clickHandler} /></div>
		</div>
	);
}