export async function setView(id, basic) {
	const data = await fetch(`https://api.funtik.su/wp-json/funtik/v1/post/view/${id}`, {
		method: "POST",
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}