export async function checkUser(basic) {

	return fetch("https://api.funtik.su/wp-json/funtik/v1/check_user", {
		method: "GET",
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}