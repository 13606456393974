import { setFormData } from "./methods";

export async function getAds(id, basic) {
	const data = await fetch(`https://api.funtik.su/wp-json/funtik/v1/post/${id}`, {
		headers: {
			"Authorization": basic ? `Basic ${window.btoa(basic)}` : null
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}

export async function getManyAds(ids) {
	const body = setFormData({
		ids: ids,
	});

	const data = await fetch("https://api.funtik.su/wp-json/funtik/v1/post/list", {
		method: "POST",
		body: body,
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}