import React from "react";
import { useLoaderData } from "react-router-dom";
import styles from "./favorites.module.css";
import adsStyles from "../../ads/ads.module.css";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "assets/images/arrow-left-blue.svg";
import AdsCard from "components/ads/parts/AdsCard";
import { FavoritesContext } from "context/FavoriteContext";
import ProfileSidebar from "../Profile/parts/ProfileSidebar";
import { DeviceContext } from "context/DeviceContext";
import FavoritesHeader from "components/header/parts/Mobile/Parts/FavoritesHeader";

export default function FavoritesPage() {
	const { favorites, adsList, user } = useLoaderData();
	const [favObj, setFavObj] = useState(null);
	const [selected, setSelected] = useState("Все");
	const fav = useContext(FavoritesContext);
	const device = useContext(DeviceContext);

	useEffect(() => {
		fav.set(favorites);
		let favoritesObj = {};

		favoritesObj["Все"] = adsList;

		adsList.forEach(ads => {
			favoritesObj[ads.term_name] = favoritesObj[ads.term_name] ? [...favoritesObj[ads.term_name], ads] : [ads];
		});

		setFavObj(favoritesObj);
		setSelected(Object.keys(favoritesObj)[0]);

		// eslint-disable-next-line
	}, [adsList])

	return (
		<>
			{device === "Mobile" && <FavoritesHeader />}
			<div className={`container ${styles.container}`}>
				{device === "Desktop" && <div className={styles.left}>
					{user ? <ProfileSidebar user={user} /> : <Link to="/" className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться на главную</Link>}
				</div>}
				<div className={styles.right}>
					{device === "Desktop" && <h2 className={styles.title}>Избранное</h2>}
					{adsList.length !== 0 ?
						<>
							<div className={styles.balloons}>
								{favObj && Object.keys(favObj).map(category => <div key={category} className={`${styles.balloon} ${category === selected ? styles.selected : ""}`} onClick={() => setSelected(category)}>{`${category} (${favObj[category].length})`}</div>)}
							</div>
							<div className={`${styles.list} ${device === "Mobile" ? adsStyles.horizontal : adsStyles.grid}`}>
								{favObj && favObj[selected].map(post => <AdsCard key={post.id} post={post} useLayout={false} />)}
							</div>
						</> : "Список избранного пуст"}
				</div>
			</div>
		</>
	);
}