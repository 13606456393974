import React from "react";
import { Form, useRouteLoaderData } from "react-router-dom";

export default function SearchInput({ styles, setShowSearchPopup, showSearchPopup, setS }) {
	const { s } = useRouteLoaderData("root");

	return (
		<div className={styles.searchInputWrapper}>
			<Form method="get" action="/search" className={styles.searchContainer}>
				<input
					className={styles.searchInput + (showSearchPopup ? ` ${styles.focus}` : "")}
					defaultValue={s || ""}
					placeholder="Введите поисковой запрос"
					onFocus={(e) => {
						setS(e.target.value);
						setShowSearchPopup(true);
					}}
					onChange={(e) => setS(e.target.value)}
					type="text"
					autoComplete="off"
					name="s"
				/>
			</Form>
		</div>
	);
}