import { setFormData } from "./methods";

export async function getCategoryPage(category, subcategory = false) {
	const body = setFormData({
		subcategory: subcategory,
	});
	const data = await fetch(`https://api.funtik.su/wp-json/funtik/v1/category/${category}`, {
		method: "POST",
		body: body,
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}