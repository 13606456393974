function validateEmail(email) {
	return !!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/gm);
}

function validatePassword(password) {
	return password.match(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/gm);
}

function validatePhone(phone) {
	// eslint-disable-next-line
    return phone.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gm)
}

export function validateField(type, value) {
	let isValidate = false;

	switch (type) {
	case "email":
		isValidate = validateEmail(value);
		break;
	case "string":
		isValidate = true;
		break;
	case "password":
		isValidate = validatePassword(value);
		break;
	case "phone":
		isValidate = validatePhone(value);
		break;
	default:
		throw new Error("Тип ошибки не найден");
	}

	return isValidate;
}