import React from "react";
import { search } from "api/search";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import searchIcon from "assets/images/search.svg";

export default function SearchPopup({ styles, s }) {
	const [list, setList] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		search(s).then(result => setData(result));
	}, [s]);

	useEffect(() => {
		setList([]);
		if (data.count && s) {
			setList([<Link key={"count"} to={`/search?s=${s}`} className={styles.searchItem}><img src={searchIcon} alt="Icon" className={styles.searchItemIcon} />Показать {data.count} объявлений по запросу &quot;`{s.slice(0, 50)}...&quot;`</Link>]);
		}
		if (data.terms?.length && data.terms.length !== 0) {
			for (let i = 0; i < (data.terms.length < 5 ? data.terms.length : 5); i++) {
				setList(list => [...list, <Link key={data.terms[i].slug} to={data.terms[i].breadcrumbs[data.terms[i].breadcrumbs.length - 1].to} className={styles.searchItem}>{data.terms[i].icon && <img src={data.terms[i].icon} alt="Icon" className={styles.searchItemIcon} />}{data.terms[i].name}</Link>]);
			}
		}
	}, [data, styles, s]);


	return (
		<div className={styles.searchPopup}>
			{list.length !== 0 ? list : <div className={styles.message}>Ничего не найдено</div>}
		</div>
	);
}