import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import styles from "../filter.module.css";

import checkWhiteIcon from "assets/images/check-white.svg";
import { FilterContext } from "routes/subcategory";

import otherIcon from "assets/images/other-icon.png";
import arrowIcon from "assets/images/arrow-black.svg";
import { ForwardOverlay } from "components/overlay/Overlay";
import { MainContext } from "context/MainContext";
import Button from "components/buttons/Button";

export default function FilterGroupMobile({ attribute }) {
	const filters = useContext(FilterContext);
	const [showOverlay, setShowOverlay] = useState(false);

	useEffect(() => {
		// Добавляет объекты вида attribute-${id}: {...terms: {value: checked}}
		filters.set(s => {
			return {
				...s,
				[`attribute-${attribute.id}`]: { ...attribute.terms.reduce((obj, key) => ({ ...obj, [key.slug]: { value: false } }), {}), other: { value: false } }
			};
		});

		// eslint-disable-next-line
    }, [attribute])

	function changeHandler(e, term) {
		if (term === "other") {
			filters.set(s => {
				return {
					...s, [`attribute-${attribute.id}`]: {
						...s[`attribute-${attribute.id}`],
						other: { value: e.target.checked }
					}
				};
			});
		} else {
			filters.set(s => {
				return {
					...s, [`attribute-${attribute.id}`]: {
						...s[`attribute-${attribute.id}`],
						[term.slug]: { value: e.target.checked }
					}
				};
			});
		}
	}

	function openOverlay() {
		setShowOverlay(true);
	}

	function closeOverlay() {
		setShowOverlay(false);
		overlayPlace.current.appendChild(overlayRef.current);
	}

	const overlayRef = useRef();
	const mainContext = useContext(MainContext);

	const overlayPlace = useRef();

	useEffect(() => {
		if (overlayRef.current) {
			if (showOverlay) {
				mainContext.overlayPlace.current.appendChild(overlayRef.current);
			} else {
				overlayPlace.current.appendChild(overlayRef.current);
			}
		}
	}, [showOverlay, mainContext.overlayPlace]);

	function printSelectedFilters() {
		let list = filters.get[`attribute-${attribute.id}`];
		let array = [];

		if (list) {
			Object.keys(list).forEach(item => {
				let attr = list[item];

				if (attr.value === true) {
					if (item === "other") {
						array.push("Другое");
					}
					else {
						attribute.terms.forEach(i => {
							if (i.slug === item) {
								array.push(i.name);
								return;
							}
						});
					}
				}
			});
		}

		return array.length !== 0 ? <span style={{ color: "var(--main-color-blue)" }}>{array.join(", ")}</span> : "Нажмите для выбора";
	}

	return (
		<div className={styles.section}>
			<h3 className={styles.title}>{attribute.name}</h3>
			<div className={styles.filterInput} onClick={openOverlay}>
				<div className={styles.filterContent}>{printSelectedFilters()}</div>
				<img src={arrowIcon} alt="" />
			</div>
			<div ref={overlayPlace} style={{ display: "none" }}>
				{showOverlay && <ForwardOverlay ref={overlayRef} backEvent={closeOverlay} container="filter" title={attribute.name} className={styles.filterOverlay} >
					<div className={styles.filterGroupList}>
						{attribute.terms.map(term =>
							<label key={term.term_id} className={`${styles.checkboxLabel} ${filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value ? styles.checked : ""}`}>
								<input type="checkbox" checked={filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value || false} name={term.slug} hidden onChange={(e) => changeHandler(e, term)} />
								<div className={styles.checkboxValueGroup}>
									{attribute.type === "icon" && <img src={term.icon} alt="" className={styles.iconImage} />}
									<div className={styles.checkboxText}>{term.name}</div>
								</div>
								<div className={styles.checkbox}>
									{filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value && <img src={checkWhiteIcon} alt="" className={styles.checkboxCheck} />}
								</div>
							</label>
						)}
						<label className={`${styles.checkboxLabel} ${filters.get?.[`attribute-${attribute.id}`]?.other.value ? styles.checked : ""}`}>
							<input type="checkbox" checked={filters.get?.[`attribute-${attribute.id}`]?.other.value || false} name="other" hidden onChange={(e) => changeHandler(e, "other")} />
							<div className={styles.checkboxValueGroup}>
								{attribute.type === "icon" && <img src={otherIcon} alt="" className={styles.iconImage} />}
								<div className={styles.checkboxText}>Другое</div>
							</div>
							<div className={styles.checkbox}>
								{filters.get?.[`attribute-${attribute.id}`]?.other.value && <img src={checkWhiteIcon} alt="" className={styles.checkboxCheck} />}
							</div>
						</label>
					</div>
					<Button name="Продолжить" className={styles.nextButton} onClick={closeOverlay} />
				</ForwardOverlay>}
			</div>
		</div>
	);
}