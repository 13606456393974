import React from "react";
import { useContext, useEffect, useState } from "react";
import styles from "../popup.module.css";
import { getStatistics } from "api/getStatistics";

import eyeIcon from "assets/images/eye-black.svg";
import contactIcon from "assets/images/contact-black.svg";
import heartIcon from "assets/images/heart-fill-black.svg";
import { DeviceContext } from "context/DeviceContext";

export default function StatisticsContent({ week, id }) {
	const [state, setState] = useState([
		{
			name: "ПН",
			favorites: 0,
			views: 0,
			contacts: 0
		},
		{
			name: "ВТ",
			favorites: 0,
			views: 0,
			contacts: 0
		},
		{
			name: "СР",
			favorites: 0,
			views: 0,
			contacts: 0
		},
		{
			name: "ЧТ",
			favorites: 0,
			views: 0,
			contacts: 0
		},
		{
			name: "ПТ",
			favorites: 0,
			views: 0,
			contacts: 0
		},
		{
			name: "СБ",
			favorites: 0,
			views: 0,
			contacts: 0
		},
		{
			name: "ВС",
			favorites: 0,
			views: 0,
			contacts: 0
		},
	]);

	const [current, setCurrent] = useState(new Date().getDay() - 1);

	const [maxValue, setMaxValue] = useState(0);

	useEffect(() => {
		let ignore = false;

		const localUser = JSON.parse(localStorage.getItem("user"));

		setState(s => {
			return s.map(item => { return { ...item, favorites: 0, views: 0, contacts: 0 }; });
		});

		setMaxValue(0);

		getStatistics(localUser.basic, {
			week: week.map(item => item.toISOString()),
			id
		})
			.then(response => {
				if (response.success) {
					if (!ignore) {
						response.data.favorites.forEach(item => {
							let date = new Date(Date.parse(item));
							let day = date.getDay() - 1;

							setState(s => {
								return s.map((item, key) => {
									return day === key ? { ...item, favorites: item.favorites + 1 } : item;
								});
							});
						});

						response.data.views.forEach(item => {
							let date = new Date(Date.parse(item));
							let day = date.getDay() - 1;

							setState(s => {
								return s.map((item, key) => {
									return day === key ? { ...item, views: item.views + 1 } : item;
								});
							});
						});

						response.data.contacts.forEach(item => {
							let date = new Date(Date.parse(item));
							let day = date.getDay() - 1;

							setState(s => {
								return s.map((item, key) => {
									return day === key ? { ...item, contacts: item.contacts + 1 } : item;
								});
							});
						});
					}
				}
			});

		return () => {
			ignore = true;
		};
	}, [id, week]);

	const device = useContext(DeviceContext);

	return (
		<div style={{ padding: device === "Desktop" ? "0 24px 32px 24px" : "" }}>
			<div className={styles.dayButtons}>
				{state.map((item, key) => {
					let value = item.favorites + item.views + item.contacts;

					if (value > maxValue) {
						setMaxValue(value);
					}

					return (
						<div className={`${styles.dayButton} ${key === current ? styles.current : ""}`} key={item.name} onClick={() => setCurrent(key)}>
							<div>{value}</div>
							<div className={styles.dayButtonLine} style={{ maxHeight: value === 0 ? 0 : Math.round(value / maxValue * 20) * 10, height: value === 0 ? 0 : Math.round(value / maxValue * 20) * 10 }}></div>
							<div>{item.name}</div>
						</div>
					);
				}
				)}
			</div>
			<div className={styles.statsList}>
				<div className={styles.statsItem}><img src={eyeIcon} alt="" />{`${state[current].views} просмотров`}</div>
				<div className={styles.statsItem}><img src={contactIcon} alt="" />{`${state[current].contacts} контактов`}</div>
				<div className={styles.statsItem}><img src={heartIcon} alt="" />{`${state[current].favorites} раз добавили в избранное`}</div>
			</div>
		</div>
	);
}