import React from "react";
import { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, useRouteLoaderData } from "react-router-dom";

import styles from "../header.module.css";

import plusLogo from "assets/images/plus-white.svg";
import logo from "assets/images/logo.svg";

import MenuLink from "./MenuLink";
import CatalogButton from "./CatalogButton";
import Catalog from "components/catalog/Catalog";
import SearchInput from "./SearchInput";
import AccountButton from "./AccountButton";
import heartIcon from "assets/images/heart-header-fill.svg";
import ProfileMenuButton from "./ProfileMenuButton";
import chatIcon from "assets/images/Message-white-fill.svg";
import chatNewIcon from "assets/images/Message-white-fill-new.svg";
import balloonIcon from "assets/images/Balloon-white-top-menu.svg";
import bellIcon from "assets/images/bell-white.svg";
import { MainContext } from "context/MainContext";


export default function DesktopHeader({ setShowUserPopup }) {
	const [showCatalog, setShowCatalog] = useState(false);
	const [showSearchList, setShowSearchList] = useState(false);

	const searchRef = useRef(false);
	const location = useLocation();

	const user = JSON.parse(localStorage.getItem("user"));

	// Закрываем все окна
	const closePopups = () => {
		setShowCatalog(false);
		setShowSearchList(false);
	};

	// Закрываем окна при изменении маршрута
	useEffect(() => {
		closePopups();
	}, [location]);

	// Открываем каталог
	const catalogButtonHandler = () => {
		closePopups();
		setShowCatalog(!showCatalog);
	};

	// Закрываем всплывающие элементы, если совершен клик вне элемента
	useEffect(() => {
		const documentClickHandler = (e) => {
			let isOver = (rf) => rf?.current?.contains(e.target);
			let checkOutside = function (flag, rp) {
				if (flag && !isOver(rp) && !isOver(rp)) {
					closePopups();
				}
			};
			// Вызываем функции передавая состояние и ref с ссылкой на элемент
			checkOutside(showSearchList, searchRef);
		};
		showSearchList && document.addEventListener("mousedown", documentClickHandler);
		return () => document.removeEventListener("mousedown", documentClickHandler);
	}, [showSearchList, searchRef]);

	const { isHaveUnread } = useRouteLoaderData("root");
	const { haveUnread, setHaveUnread } = useContext(MainContext);

	useEffect(() => {
		setHaveUnread(isHaveUnread);
	}, [setHaveUnread, isHaveUnread]);

	// Список ссылок
	let links = [
		{
			link: "ads/create",
			name: "Разместить объявления",
			icon: plusLogo,
			user: false
		},
		{
			link: "/favorites",
			name: "Избранное",
			icon: heartIcon,
			user: true,
		},
		{
			link: "/profile/chat",
			name: "Сообщения",
			icon: haveUnread ? chatNewIcon : chatIcon,
			user: true
		},
		{
			link: "/profile/notifications",
			name: "Оповещения",
			icon: bellIcon,
			user: true
		}
	];
	let linksJsx = links.map((item, key) => { return ((item.user === true && user) || item.user === false) && (<MenuLink key={key} link={item.link} name={item.name} icon={item.icon} />);});

	const { city, setShowCityPopup } = useContext(MainContext);

	return (
		<header className={styles.header}>
			<div className={styles.top}>
				<div className={styles.topContainer + " container"}>
					<nav className={styles.topMenu}>
						{linksJsx}
					</nav>
					<div className={styles.cityLink} onClick={() => setShowCityPopup(true)}>
						<img src={balloonIcon} alt='' />
						{city.value}
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				<div className={styles.bottomContainer + " container"}>
					<Link to="/" className={styles.homeLink}><img src={logo} alt="Logo" /></Link>
					<CatalogButton
						showCatalog={showCatalog}
						onClick={catalogButtonHandler}
					/>
					<SearchInput
						setShowSearchList={setShowSearchList}
						showSearchList={showSearchList}
						closePopups={closePopups}
						innerRef={searchRef}
					/>
					{!user ? <AccountButton styles={styles} setShowUserPopup={setShowUserPopup} /> : <ProfileMenuButton />}
				</div>
			</div>
			<Catalog showCatalog={showCatalog} setShowCatalog={setShowCatalog} />
		</header>
	);
}