import React from "react";
import crossIcon from "assets/images/popup-cross.svg";
import styles from "../popup.module.css";

export default function PopupContainer({ children, closeEvent, title, smallTitle = false, style = {} }) {
	return (
		<div className={styles.popup} onClick={(e) => e.stopPropagation()} style={style}>
			<div className={styles.popupTop}>
				<h2 className={`${styles.popupTitle} ${smallTitle ? styles.small : ""}`}>{title}</h2>
				<div className={styles.popupCross} onClick={closeEvent}>
					<img src={crossIcon} alt="Icon" />
				</div>
			</div>
			{children}
		</div>
	);
}