import React from "react";
import burgerIcon from "assets/images/menu-burger.svg";
import { useNavigate } from "react-router-dom";
import styles from "../header.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import profileIcon from "assets/images/User-fill-black.svg";
import commentIcon from "assets/images/comment-black.svg";
import chatIcon from "assets/images/chat-black.svg";
import leaveIcon from "assets/images/logout-black.svg";
import { Link } from "react-router-dom";
import LogoutPopup from "components/popups/parts/LogoutPopup";
import { useAuth } from "hooks/useAuth";
import { MainContext } from "context/MainContext";

export default function ProfileMenuButton() {
	const { profileAvatar } = useContext(MainContext);

	const navigate = useNavigate();
	const { logout: leave } = useAuth();
	const [showPopup, setShowPopup] = useState(false);

	function logout() {
		leave();
		navigate("/");
	}

	const [showLogoutPopup, setShowLogoutPopup] = useState(false);

	const menu = [
		{
			name: "Показать профиль",
			icon: profileIcon,
			link: "/profile/me",
		},
		{
			name: "Объявления",
			icon: chatIcon,
			link: "/profile/ads",
		},
		{
			name: "Сообщения",
			icon: chatIcon,
			link: "/profile/chat",
		},
		{
			name: "Отзывы",
			icon: commentIcon,
			link: "/profile/reviews/my",
		},
		{
			name: "Уведомления",
			icon: profileIcon,
			link: "",
		},
		{
			name: "Избранное",
			icon: commentIcon,
			link: "/favorites",
		},
		{
			name: "Выйти из аккаунта",
			icon: leaveIcon,
			link: "",
			action: () => setShowLogoutPopup(true)
		},
	];

	const ref = useRef();

	const { haveUnread } = useContext(MainContext);

	// Закрываем всплывающие элементы, если совершен клик вне элемента
	useEffect(() => {
		const documentClickHandler = (e) => {
			let isOver = (rf) => rf?.current?.contains(e.target);
			let checkOutside = function (flag, rp) {
				if (flag && !isOver(rp) && !isOver(rp)) {
					setShowPopup(false);
				}
			};
			// Вызываем функции передавая состояние и ref с ссылкой на элемент
			checkOutside(showPopup, ref);
		};
		showPopup && document.addEventListener("mousedown", documentClickHandler);
		return () => document.removeEventListener("mousedown", documentClickHandler);
	}, [showPopup, ref]);

	return (
		<div className={styles.profileMenuWrapper}>
			<div ref={ref} className={styles.profileMenuButton} onClick={() => setShowPopup(true)}>
				<img src={burgerIcon} className={styles.profileMenuButtonBurger} alt="" />
				<img src={profileAvatar} className={styles.profileMenuButtonAvatar} alt="" />
			</div>
			{showPopup && <div className={styles.profileMenu} ref={ref} >
				{menu.map((item, key) => {
					return item.link ?
						<Link key={key} to={item.link} className={styles.menuItem}><img src={item.icon} className={styles.menuItemIcon} alt="" />
							{item.name}{item.name === "Сообщения" && haveUnread &&
								<span
									style={{
										width: 8,
										height: 8,
										borderRadius: 4,
										background: "var(--main-color-red)"
									}}>
								</span>
							}
						</Link> :
						<div key={key} className={styles.menuItem} onClick={item.action}><img src={item.icon} className={styles.menuItemIcon} alt="" />
							{item.name}{item.name === "Сообщения" && haveUnread &&
								<span
									style={{
										width: 8,
										height: 8,
										borderRadius: 4,
										background: "var(--main-color-red)"
									}}>
								</span>
							}</div>;
				})}
			</div>}
			{showLogoutPopup && <LogoutPopup closeEvent={() => setShowLogoutPopup(false)} leaveEvent={logout} />}
		</div>
	);
}