import React from "react";
import Overlay from "components/overlay/Overlay";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StepContext } from "../CreateAdsPage";
import SelectCategory from "components/forms/CreateAds/SelectCategory";
import SelectPhotos from "components/forms/CreateAds/SelectPhotos";
import SelectDescription from "components/forms/CreateAds/SelectDescription";
import SelectAttributes from "components/forms/CreateAds/SelectAttribues";
import CreateAds from "components/forms/CreateAds/CreateAds";
import styles from "../create-ads.module.css";
import { AuthContext } from "context/AuthContext";

export default function MobileCreateAdsPage({ selectCategoryState, setSelectCategoryState, selectPhotosState, setSelectPhotosState }) {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const { step } = useContext(StepContext);

	return (
		<Overlay backEvent={() => navigate(-1)} title="Новое объявление">
			<div className={styles.mobileContainer}>
				{!user && <div>Войдите в свой аккаунт</div>}
				{(step === "1" && user) && <SelectCategory state={selectCategoryState} setState={setSelectCategoryState} styles={styles} />}
				{(step === "2" && user) && <SelectPhotos state={selectPhotosState} setState={setSelectPhotosState} styles={styles} />}
				{(step === "3" && user) && <SelectDescription styles={styles} />}
				{(step === "4" && user) && <SelectAttributes styles={styles} />}
				{(step === "5" && user) && <CreateAds styles={styles} />}
			</div>
		</Overlay>
	);
}