import React from "react";
import BottomMenu from "components/bottom/BottomMenu";
import Header from "./components/header/Header";
import User from "components/popups/User";

import { createContext, useEffect, useRef, useState } from "react";
import { Outlet, ScrollRestoration, useLocation, useNavigate, useNavigation } from "react-router-dom";

import { DeviceContext } from "context/DeviceContext";
import { AuthContext } from "context/AuthContext";
import Footer from "components/footer/Footer";

import { FavoritesContext } from "context/FavoriteContext";
import { MainContext } from "context/MainContext";

import socketIO from "socket.io-client";
import City from "components/popups/City";
import { city as defaultCity } from "assets/city";
import { getAvatar } from "api/getAvatar";
const socket = socketIO.connect("https://websocket.funtik.su:5000");
export const SocketContext = createContext(socket);

if (!localStorage.getItem("city")) {
	localStorage.setItem("city", JSON.stringify(defaultCity));
}

export default function Root() {
	const navigation = useNavigation();
	const location = useLocation();
	const navigate = useNavigate();

	// Брейкпоинт для адаптива
	const [device, setDevice] = useState(window.innerWidth < 1260 ? "Mobile" : "Desktop");
	window.addEventListener("resize", () => setDevice(window.innerWidth < 1260 ? "Mobile" : "Desktop"));

	// Попап с авторизацией
	const [showUserPopup, setShowUserPopup] = useState(false);

	// Попап с выбором города
	const [showCityPopup, setShowCityPopup] = useState(false);

	useEffect(() => {
		if (new URLSearchParams(window.location.search).get("show_login") !== null) {
			if (device === "Desktop") {
				setShowUserPopup(true);
			} else {
				navigate("/login");
			}
		}
	}, [location, setShowUserPopup, device, navigate]);

	const [user, setUser] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null);

	let localUser = JSON.parse(localStorage.getItem("user"));

	const [haveUnread, setHaveUnread] = useState(false);

	const [profileAvatar, setProfileAvatar] = useState(null);
	useEffect(() => {
		getAvatar(localUser?.id)
			.then(data => {
				if(data.success) {
					setProfileAvatar(data.avatar);
				}
			});
	}, [localUser]);

	// Websocket - эмитим события для авторизации в чате
	useEffect(() => {
		if (localUser) {
			socket.emit("saveUser", {
				user: localUser,
				socketID: socket.id
			});
		}
		socket.on("getUser", () => {
			let localUser = JSON.parse(localStorage.getItem("user"));
			if (localUser) {
				socket.emit("saveUser", {
					user: localUser,
					socketID: socket.id
				});
			}
		});
		socket.on("newUnread", (data) => {
			setHaveUnread(data.value);
		});
	}, [localUser]);

	const [favorites, setFavorites] = useState([]);

	const overlayPlace = useRef();

	const [city, setCity] = useState(JSON.parse(localStorage.getItem("city")) || defaultCity);

	useEffect(() => {
		localStorage.setItem("city", JSON.stringify(city));
	}, [city]);

	return (
		<>
			<SocketContext.Provider value={socket}>
				<MainContext.Provider value={{ overlayPlace, haveUnread, setHaveUnread, profileAvatar, setProfileAvatar, city, setCity, setShowCityPopup }}>
					<DeviceContext.Provider value={device}>
						<AuthContext.Provider value={{ user, setUser }}>
							<FavoritesContext.Provider value={{ get: () => { return favorites; }, set: setFavorites }}>
								{device === "Desktop" && <Header setShowUserPopup={setShowUserPopup} />}
								<ScrollRestoration />
								<main className={"main" + (navigation.state === "loading" ? " loading" : "")}>
									<Outlet />

									<BottomMenu />
									{showUserPopup && <User setShowUserPopup={setShowUserPopup} />}
									{showCityPopup && <City setShowPopup={setShowCityPopup} />}
									{device === "Mobile" && <div ref={overlayPlace}></div>}
								</main>
								{device === "Desktop" && <Footer />}
							</FavoritesContext.Provider>
						</AuthContext.Provider>
					</DeviceContext.Provider>
				</MainContext.Provider>
			</SocketContext.Provider>
		</>
	);
}