import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { DeviceContext } from "context/DeviceContext";
import DesktopCreateAdsPage from "./Desktop/DesktopCreateAdsPage";
import MobileCreateAdsPage from "./Mobile/MobileCreateAdsPage";
import { useFormData } from "hooks/useFormData";
import { getCategories } from "api/getCategories";
import { getAttibutes } from "api/getAttribues";
import { redirect, useLoaderData } from "react-router-dom";
import { checkUser } from "api/checkUser";
import { Helmet } from "react-helmet";

export async function loader() {
	const data = localStorage.getItem("user");
	if (data) {
		const user = JSON.parse(data);
		const response = await checkUser(user.basic);
		if (response.success) {
			const categories = await getCategories();
			return { categories };
		} else {
			localStorage.removeItem("user");
			return redirect("/?show_login=true&from=/ads/create");
		}
	} else {
		localStorage.removeItem("user");
		return redirect("/?show_login=true&from=/ads/create");
	}
}

export const StepContext = createContext({
	step: null, //Текущий шаг
	setStep: () => { }, // Смена шага
	stepList: {}, // Список шагов
	setStepList: () => { } // Смена списка
});

export const DataContext = createContext({});

export default function CreateAdsPage() {
	const device = useContext(DeviceContext);
	const [step, setStep] = useState("1");

	const [state, setState] = useState({
		title: {
			value: "",
			name: "Название",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Название должно быть заполнено",
		},
		description: {
			value: "",
			name: "Описание",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Описание должно быть заполнено",
		},
		address: {
			value: "",
			name: "Адрес",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Адрес должно быть заполнено",
		},
		price: {
			value: "",
			name: "Цена",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Цена должно быть заполнено",
			freeChecked: false,
			emptyChecked: false
		},
		photosState: {
			error: null,
			imagesUrls: []
		},
		categoriesState: {
			categoriesList: null,
			selectedCategory: null,
			categoriesListJsx: [],
			breadcrumbs: [],
			breadcrumbsJsx: [],
		}
	});

	const formData = useFormData(state, setState);

	// Список шагов
	const [stepList, setStepList] = useState({
		"1": {
			name: "Выбор категории",
			isBlock: false
		},
		"2": {
			name: "Фотографии",
			isBlock: true
		},
		"3": {
			name: "Описание объявления",
			isBlock: true
		},
		"4": {
			name: "Характеристики",
			isBlock: true
		},
		"5": {
			name: "Разместить объявление",
			isBlock: true
		}
	});

	// Получение списка категорий
	const { categories } = useLoaderData();

	// Получение списка аттрибутов
	const [attributes, setAttributes] = useState(null);

	useEffect(() => {
		let ignore = false;

		const fetching = async () => {
			if (!ignore && formData.data?.category?.value?.term_id) {
				await getAttibutes(formData.data.category.value.term_id)
					.then(data => setAttributes(data));
			}
		};

		fetching();

		return () => {
			ignore = true;
		};
	}, [formData.data?.category?.value]);

	useEffect(() => {
		setState(s => { return { ...s, attributes: {} }; });
		for (let a in attributes) {
			setState(s => {
				return {
					...s,
					attributes: {
						...s["attributes"],
						[a]: {
							show: false
						}
					}
				};
			});
		}
	}, [attributes]);

	// Проверка доступности шагов
	useEffect(() => {
		function checkField(fields) {
			for (let field in fields) {
				if (!formData.data[fields[field]] || (!formData.data[fields[field]].value)) {
					return false;
				}
			}

			return true;
		}

		function checkAttributes() {
			if (!attributes || !formData.data?.attributes) {
				return false;
			}

			for (let attribute in attributes) {
				// eslint-disable-next-line
				if (!formData.data.attributes[attribute].hasOwnProperty("id")) {
					return false;
				}
			}

			return true;
		}

		setStepList(sl => {
			return {
				...sl,
				"2": {
					...sl["2"],
					isBlock: checkField(["category"]) ? false : true
				},
				"3": {
					...sl["3"],
					isBlock: checkField(["photos", "category"]) ? false : true
				},
				"4": {
					...sl["4"],
					isBlock: checkField(["photos", "category", "address", "description", "title"]) ? false : true
				},
				"5": {
					...sl["5"],
					isBlock: checkField(["photos", "category", "address", "description", "title"]) && checkAttributes() ? false : true
				}
			};
		});
	}, [formData.data, attributes]);

	return (
		<>
			<Helmet>
				<title>Новое объявление | Фунтик</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<div className='container'>
				<StepContext.Provider value={{ step: step, setStep: setStep, stepList: stepList, setStepList: setStepList }}>
					<DataContext.Provider value={{ data: formData, categories: categories, attributes: attributes, setData: formData.setData }}>
						{device === "Desktop" ? <DesktopCreateAdsPage /> : <MobileCreateAdsPage />}
					</DataContext.Provider>
				</StepContext.Provider>
			</div>
		</>
	);
}