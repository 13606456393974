import "assets/scss/chatscope.scss";
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, Avatar, ConversationHeader, MessageSeparator, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { redirect, useNavigate, useRouteLoaderData } from "react-router-dom";
import { getChatList } from "api/chat/getChatList";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getChat } from "api/chat/getChat";
import { SocketContext } from "Root";
import styles from "../components/pages/Profile/profile.module.css";
import { debounce, throttle } from "lodash";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const localUser = JSON.parse(localStorage.getItem("user"));

	if (localUser) {
		const response = await getChatList(localUser.basic);
		if (response?.success) {
			const list = response.chat_list;
			const chat = await getChat(params.id, localUser.basic);
			return { list, chat };
		} else {
			return redirect(`/?show_login=true&from=${window.location.pathname}`);
		}
	} else {
		return redirect(`/?show_login=true&from=${window.location.pathname}`);
	}
}

export default function Chat() {
	const { chat } = useRouteLoaderData("chatPage");
	const [messageInputValue, setMessageInputValue] = useState("");
	const navigate = useNavigate();
	const localUser = JSON.parse(localStorage.getItem("user"));
	const socket = useContext(SocketContext);
	const [messages, setMessages] = useState(chat.messages);
	const [typing, setTyping] = useState(false);
	const [status, setStatus] = useState(null);
	const [statusMessage, setStatusMessage] = useState("...Загрузка");

	useEffect(() => {
		// Добавляем сообщение в массив и обновляем статус на прочитано
		socket.on("newMessage", (data) => {
			if (data.chat_id === chat.id) {
				setMessages(m => { return [...m, data]; });
			}
		});
		// Отображаем информацию о наборе текста в поле
		socket.on("typing", () => {
			setTyping(true);
			stopTyping.current();
		});
		// Получаем статус recipient-а
		socket.emit("getStatus", {
			user_id: chat.second.id
		});
		// Устанавливаем статус
		socket.on("setStatus", (data) => {
			if (parseInt(data.user_id) !== parseInt(chat.second.id)) return;
			if (data.status === "online") {
				setStatus(data.status);
				setStatusMessage("В сети");
			} else if (data.status === "offline") {
				setStatus(data.status);
				let date = new Date(data.time * 1000);
				let now = new Date(Date.now());
				if (date.getDate() === now.getDate()) {
					let dmy = `${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
					setStatusMessage(`был-а в сети в ${dmy}`);
				} else {
					let dmy = `${("0" + date.getDate()).slice(-2)} ${getMonth(date.getMonth())} ${date.getFullYear()}`;
					setStatusMessage(`был-а в сети ${dmy}`);
				}
			}
		});
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		// Меняем статус сообщений на прочитано
		socket.emit("chat_checked", {
			chat_id: chat.id,
		});
		// eslint-disable-next-line
	}, [messages])

	function getMonth(month) {
		switch (month) {
		case 0:
			return "Января";
		case 1:
			return "Февраля";
		case 2:
			return "Марта";
		case 3:
			return "Апреля";
		case 4:
			return "Мая";
		case 5:
			return "Июня";
		case 6:
			return "Июля";
		case 7:
			return "Августа";
		case 8:
			return "Сентября";
		case 9:
			return "Октбяря";
		case 10:
			return "Ноября";
		case 11:
			return "Декабря";
		default:
			return "";
		}
	}

	function sortMessages(messages) {
		let list = {};

		messages.forEach(message => {
			let date = new Date(message.date * 1000);
			let dmy = `${("0" + date.getDate()).slice(-2)} ${getMonth(date.getMonth())} ${date.getFullYear()}`;
			let time = `${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
			if (!list[dmy]) {
				list[dmy] = [];
			}
			list[dmy] = [
				...list[dmy],
				{
					time,
					message: message.message,
					direction: parseInt(message.sender_id) === parseInt(localUser.id) ? "outgoing" : "incoming"
				},
			];
		});

		return list;
	}

	function handleSubmit(message) {
		if(message.length > 5000) return;
		if (message.trim()) {
			socket.emit("message", {
				text: message.replaceAll("<br>", "\n"),
				user: localUser,
				chat: chat.id,
				socketID: socket.id
			});
		}
		setMessageInputValue("");
	}

	const stopTyping = useRef(debounce(() => {
		setTyping(false);
	}, 1000));

	const handlerTyping = useRef(throttle(() => {
		socket.emit("typing", {
			chat: chat.id,
			socketID: socket.id,
			user: localUser,
			second: chat.second
		});
	}, 500));

	function handleChange(val) {
		setMessageInputValue(val);
		handlerTyping.current();
	}

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Сообщения</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<div className={styles.right}>
				<div className={styles.chatContainer}>
					<MainContainer responsive>
						<ChatContainer>
							<ConversationHeader>
								<ConversationHeader.Back onClick={() => navigate("/profile/chat")} />
								<Avatar src={chat.conversation.avatar} name={chat.conversation.avatar} status={status === "online" ? "available" : "invisible"} onClick={() => navigate(`/ads/${chat.ads_id}`)} />
								<ConversationHeader.Content userName={chat.conversation.title} info={`${chat.second.name} (${statusMessage})`} />
							</ConversationHeader>
							<MessageList typingIndicator={typing && <TypingIndicator content={`${chat.second.name} печатает...`} />}>
								{Object.keys(sortMessages(messages)).map(item => {
									let msgs = sortMessages(messages);
									return [
										<MessageSeparator key={item} content={item} />,
										...msgs[item].map((message, key) =>
											<Message key={key} model={{
												message: message.message,
												sentTime: message.time,
												sender: message.direction === "outgoing" ? chat.current.name : chat.second.name,
												direction: message.direction,
												position: "single"
											}} avatarPosition={message.direction === "outgoing" ? "tr" : "tl"}>
												<Avatar onClick={() => navigate(`/user/${message.direction === "outgoing" ? chat.current.id : chat.second.id}`)} src={message.direction === "outgoing" ? chat.current.avatar : chat.second.avatar} name={message.direction === "outgoing" ? chat.current.name : chat.second.name} />
												<Message.Footer sentTime={message.time} />
											</Message>
										)
									];
								})}
							</MessageList>
							<MessageInput attachButton={false} placeholder="Введите сообщение..." value={messageInputValue} onSend={handleSubmit} onChange={val => handleChange(val)} />
						</ChatContainer>
					</MainContainer>
				</div>
			</div>
		</>
	);
}