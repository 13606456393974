import React, { createContext, useContext } from "react";
import { checkUser } from "api/checkUser";
import { Outlet, redirect, useLoaderData, useLocation, useNavigate, NavLink, Link, } from "react-router-dom";
import { useEffect, useState } from "react";

import starEmptyIcon from "assets/images/star-empty.svg";
import starFillIcon from "assets/images/star-fill.svg";
// import crossIcon from "assets/images/popup-cross.svg";

import NewReview from "components/popups/NewReview";
import styles from "../User/user.module.css";
import ProfileReviewCard from "./parts/ProfileReviewCard";
import ReviewsInfo from "./parts/ReviewsInfo";
import { DeviceContext } from "context/DeviceContext";

import headerStyles from "../../header/parts/Mobile/header-mobile.module.css";
import { Helmet } from "react-helmet";

export async function loader() {
	const localUser = JSON.parse(localStorage.getItem("user"));
	const user = await checkUser(localUser.basic);

	if (!localUser) {
		return redirect("/?show_login=true&from=/profile/reviews");
	}

	if (!user?.success) {
		throw new Error("Эта страница только для авторизированных пользователей");
	}

	return null;
}

export default function ProfileReviews() {
	const location = useLocation();
	const navigate = useNavigate();
	const device = useContext(DeviceContext);

	useEffect(() => {
		if (location.pathname === "/profile/reviews") {
			navigate("/profile/reviews/my");
		}
	}, [location, navigate]);

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Отзывы</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet><div className={`${device === "Desktop" ? styles.right : ""}`} style={device === "Mobile" ? {padding: "24px 16px", marginBottom: 48} : {}}>
				{device === "Mobile" && 
			<header className={headerStyles.header}>
				<div className={`${headerStyles.headerContainer} ${styles.line}`}>
					<div className={headerStyles.back} onClick={() => { window.history.length === 1 ? navigate("/") : navigate(-1); }}></div>
					<h2 className={`${headerStyles.headerTitle}`}>{(() => {
						if(location.pathname.match("/profile/reviews/my")) {
							return "Мои отзывы";
						} else if(location.pathname.match("/profile/reviews/outcoming")) {
							return "Оставленные отзывы";
						} else if (location.pathname.match("/profile/reviews/new")) {
							return "Оставьте оценку";
						}
					})()}</h2>
				</div>
			</header>}
				{device === "Desktop" && <div className={styles.profileAdsBalloons}>
					<NavLink
						to="/profile/reviews/my"
						className={({ isActive }) => `${styles.balloon} ${isActive ? styles.active : ""}`}
					>
                    Мои отзывы
					</NavLink>
					<NavLink
						to="/profile/reviews/outcoming"
						className={({ isActive }) => `${styles.balloon} ${isActive ? styles.active : ""}`}
					>
                    Оставленные
					</NavLink>
					<NavLink
						to="/profile/reviews/new"
						className={({ isActive }) => `${styles.balloon} ${isActive ? styles.active : ""}`}
					>
                    Оставьте оценку
					</NavLink>
				</div>}
				<Outlet />
			</div>
		</>
		
	);
}

export async function myLoader() {
	const user = JSON.parse(localStorage.getItem("user"));

	const myReviews = await fetch("https://api.funtik.su/wp-json/funtik/v1/reviews/my", {
		method: "GET",
		headers: {
			Authorization: `Basic ${window.btoa(user.basic)}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});

	const reviewsInfo = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${user.id}/reviews/info`, {
		method: "GET",
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});

	return { myReviews, reviewsInfo };
}

export const ReviewsContext = createContext();

export function ProfileMyReviews() {
	const {myReviews, reviewsInfo} = useLoaderData();
	const [state, setState] = useState(myReviews);
	const device = useContext(DeviceContext);

	return (
		<ReviewsContext.Provider value={{reviews: state, setReviews: setState, path: "/profile/reviews/my", isMy: false}}>
			<div style={{display: "flex", flexDirection: "column", gap: device === "Desktop" ? 24 : 16}}>
				{state.length !== 0 && <ReviewsInfo reviewsInfo={reviewsInfo} />}
				{state.length !== 0 ? state.map(item => 
					<ProfileReviewCard key={item.review.id} item={item} isMy={false} setListState={setState}/>	
				) :
					<div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>Вам пока не оставляли отзывов</div>		
				}
				<Outlet />
			</div>
		</ReviewsContext.Provider>
	);
}

export async function outLoader() {
	const user = JSON.parse(localStorage.getItem("user"));

	const outReviews = await fetch("https://api.funtik.su/wp-json/funtik/v1/reviews/out", {
		method: "GET",
		headers: {
			Authorization: `Basic ${window.btoa(user.basic)}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});

	return { outReviews };
}

export function ProfileOutReviews() {
	const {outReviews} = useLoaderData();
	const [state, setState] = useState(outReviews);
	const device = useContext(DeviceContext);

	return (
		<ReviewsContext.Provider value={{reviews: state, setReviews: setState, path: "/profile/reviews/outcoming", isMy: true}}>
			<div style={{display: "flex", flexDirection: "column", gap: device === "Desktop" ? 24 : 16}}>
				{state.length !== 0 ? state.map(item => 
					<ProfileReviewCard key={item.review.id} item={item} isMy={true} setListState={setState}/>	
				) :
					<div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>У вас пока нету оставленных отзывов</div>		
				}
				<Outlet />
			</div>
		</ReviewsContext.Provider>
	);
}

export async function newLoader() {
	const user = JSON.parse(localStorage.getItem("user"));

	const newReviews = await fetch("https://api.funtik.su/wp-json/funtik/v1/reviews/new", {
		method: "GET",
		headers: {
			Authorization: `Basic ${window.btoa(user.basic)}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});

	return { newReviews };
}

export function ProfileNewReviews() {
	const { newReviews } = useLoaderData();
	const [showPopup, setShowPopup] = useState(false);
	const [popupAds, setPopupAds] = useState(null);
	const [rating, setRating] = useState(1);

	const [
		reviews, 
		// setReviews
	] = useState(newReviews);

	// const user = JSON.parse(localStorage.getItem("user"));

	// async function removeHandler(id) {
	// 	fetch(`https://api.funtik.su/wp-json/funtik/v1/reviews/new/${id}/remove`, {
	// 		method: "GET",
	// 		headers: {
	// 			"Authorization": `Basic ${window.btoa(user.basic)}`
	// 		}
	// 	})
	// 		.then(response => response.json())
	// 		.then(data => {
	// 			if(data.success) {
	// 				setReviews(reviews.filter(i => i.id != id));
	// 			}
	// 		})
	// 		.catch(error => {
	// 			return error;
	// 		});
	// }

	return (
		<div className={styles.newReviews}>
			{ reviews.length !== 0
				? reviews.map((review) => (
					<div
						key={review.id}
						className={styles.newReview}
					>
						<div className={styles.newReviewTop}>
							<img src={review.photo} alt="" className={styles.newReviewPhoto} />
							<div className={styles.newReviewTopContent}>
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<Link to={`/user/${review.user.id}`} className={styles.newReviewName}>{review.user.name}</Link>
									{/* <img src={crossIcon} onClick={() => removeHandler(review.id)} alt="" style={{ width: 12, height: 12, cursor: "pointer" }} /> */}
								</div>
								<Link to={`/ads/${review.id}`} className={styles.newReviewTitle}>{review.title}</Link>
								<div className={styles.newReviewPrice}>{review.isNew !== "true" ? review.isFree !== "true" ? `${new Intl.NumberFormat("ru-RU", { style: "decimal", currency: "RUB", currencyDisplay: "symbol" }).format(review.price)} ₽` : "Бесплатно" : "Цена не указана"}</div>
							</div>
						</div>
						<div style={{ width: "100%", height: 1, background: "var(--main-color-gray)" }} />
						<div
							className={styles.newReviewStars}
							onMouseLeave={() => {
								for (let i = 1; i <= 5; i++) {
									document.querySelector(`#review-${review.id} .star-${i}`).src = starEmptyIcon;
								}
							}}
							id={`review-${review.id}`}
						>
							{[1, 2, 3, 4, 5].map((value) => (
								<img
									key={value}
									src={starEmptyIcon}
									alt=""
									className={`star-${value}`}
									onClick={() => {
										setPopupAds(review.id);
										setShowPopup(true);
										setRating(value);
									}}
									onMouseEnter={() => {
										for (let i = 1; i <= 5; i++) {
											document.querySelector(`#review-${review.id} .star-${i}`).src = starEmptyIcon;
										}
										for (let i = 1; i <= value; i++) {
											document.querySelector(`#review-${review.id} .star-${i}`).src = starFillIcon;
										}
									}}
								/>
							))}
						</div>
					</div>
				)) : 
				<div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>Вам некому оставлять отзыв</div>
			}
			{showPopup && <NewReview setShowPopup={setShowPopup} id={popupAds} rating={rating} />}
		</div>
	);
}
