import React from "react";
import { useContext, useState } from "react";

import styles from "./popup.module.css";
import overlayStyles from "../overlay/overlay.module.css";

import { DeviceContext } from "context/DeviceContext";
import AddressForm from "components/forms/Address/AddressForm";

import crossIcon from "assets/images/cross-black.svg";

export default function AddressEdit({ setShowPopup, state, setState }) {
	const device = useContext(DeviceContext);
	const [query, setQuery] = useState(state?.address?.value.value || "");

	function setAddress(value) {
		setState(s => {
			return {
				...s, address: {
					...s.address,
					value: value
				}
			};
		});
		setShowPopup(false);
	}

	return device === "Desktop" ? (
		<div className={styles.popupContainer} onClick={() => setShowPopup(false)}>
			<div className={`${styles.popup} ${styles.addressPopup}`} onClick={(e) => e.stopPropagation()}>
				<div className={styles.popupTop}>
					<div className={styles.addressInputWrapper}>
						<input type="text" className={styles.addressInput} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="ул Центральная, д 12" />
					</div>
					<img src={crossIcon} alt="Cross icon" className={styles.addressCross} onClick={() => setShowPopup(false)} />
				</div>
				<AddressForm query={query} styles={styles} callback={setAddress} />
			</div>
		</div>
	) : (
		<div className={overlayStyles.addressOverlay}>
			<div className={overlayStyles.top}>
				<div className={styles.addressInputWrapper}>
					<input type="text" className={styles.addressInput} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="ул Центральная, д 12" />
				</div>
				<img src={crossIcon} alt="Cross icon" className={styles.addressCross} onClick={() => setShowPopup(false)} />
			</div>
			<AddressForm query={query} styles={styles} callback={setAddress} />
		</div>
	);
}