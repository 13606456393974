export async function checkUnreads(basic) {
	return fetch("https://api.funtik.su/wp-json/funtik/v1/user/check_unreads", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}