import { setFormData } from "./methods";

export async function getSubcategoryPage(category, subcategory, selected) {
	const body = setFormData({
		category: category,
		subcategory: subcategory,
	});
	const data = await fetch(`https://api.funtik.su/wp-json/funtik/v1/subcategory/${selected}`, {
		method: "POST",
		body: body,
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}