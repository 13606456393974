import React, { useState } from "react";
import styles from "../User/user.module.css";
import { useContext } from "react";
import { DeviceContext } from "context/DeviceContext";
import { Link, Outlet, useLoaderData, useNavigate } from "react-router-dom";
import arrowIcon from "assets/images/arrow-left-blue.svg";
import { ReviewsContext } from "../Profile/ProfileReviews";
import ReviewsInfo from "../Profile/parts/ReviewsInfo";
import ProfileReviewCard from "../Profile/parts/ProfileReviewCard";
import { getUserPage } from "api/getUserPage";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const userReviews = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${params?.uid}/reviews`, {
		method: "GET",
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});

	const reviewsInfo = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${params?.uid}/reviews/info`, {
		method: "GET",
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});
        
	const user = await getUserPage(params.uid)
		.then(data => {
			if(data.success) {
				return data.user;
			} else {
				throw new Error("Пользователь не найден");
			}
		})
		.catch(e => {
			throw new Error(e);
		});
	const {uid} = params;

	return {uid, userReviews, reviewsInfo, user};
}

export default function UserReviews() {
	const device = useContext(DeviceContext);
	const navigate = useNavigate();
	const {uid, userReviews, reviewsInfo, user} = useLoaderData();

	const [state, setState] = useState(userReviews);

	return (
		<>
			<Helmet>
				<title>{user.name} - Отзывы пользователя | Фунтик</title>
				<meta name="description" content={`Профиль пользователя ${user.name} в сервисе Фунтик. Объявления и отзывы пользователя ${user.name}`}/>
				<meta property="og:description" content={`Профиль пользователя ${user.name} в сервисе Фунтик. Объявления и отзывы пользователя ${user.name}`}/>
			</Helmet>
			{
				device === "Desktop" ? (
					<div className={`container ${styles.container}`}>
						<div className={styles.left}>
							<div className={styles.stickyContainer}>
								<Link to={`/user/${uid}`} className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться в пользователя</Link>
							</div>
						</div>
						<div className={`${styles.right} ${styles.profileAds}`}>
							<h2 className={styles.name}>Отзывы пользователя</h2>
							<ReviewsContext.Provider value={{reviews: state, setReviews: setState, path: `/user/${uid}/reviews`, isMy: false}}>
								<div style={{display: "flex", flexDirection: "column", gap: device === "Desktop" ? 24 : 16}}>
									{state.length !== 0 && <ReviewsInfo reviewsInfo={reviewsInfo} />}
									{state.length !== 0 ? state.map(item => 
										<ProfileReviewCard key={item.review.id} item={item} isMy={false} setListState={setState} isUser={true}/>	
									) :
										<div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>Пользователю пока не оставляли отзывов</div>		
									}
									<Outlet />
								</div>
							</ReviewsContext.Provider>
						</div>
					</div>
				) : (
					<>
						<div className={styles.profileAdsHeader}>
							<div className={styles.back} onClick={() => navigate(-1)}></div>
							<h2 className={styles.title}>Отзывы пользователя</h2>
						</div>
						<div className={styles.profileAdsContent} style={{ marginBottom: 48 }}>
							<div className={styles.profileAdsContainer}>
								<ReviewsContext.Provider value={{reviews: state, setReviews: setState, path: `/user/${uid}/reviews`, isMy: false}}>
									<div style={{display: "flex", flexDirection: "column", gap: device === "Desktop" ? 24 : 16}}>
										{state.length !== 0 && <ReviewsInfo reviewsInfo={reviewsInfo} />}
										{state.length !== 0 ? state.map(item => 
											<ProfileReviewCard key={item.review.id} item={item} isMy={false} setListState={setState} isUser={true} isUserReviews={true}/>	
										) :
											<div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>Пользователю пока не оставляли отзывов</div>		
										}
										<Outlet />
									</div>
								</ReviewsContext.Provider>
							</div>
						</div>
					</>
				)
			}
		</>
	);
}