import React from "react";
import { useState } from "react";

import styles from "./popup.module.css";
import PopupContainer from "./parts/PopupContainer";
import Button from "components/buttons/Button";

export default function DeleteReview({ setShowPopup, id, setListState }) {

	const [state, setState] = useState({
		loading: false
	});

	async function submitHandler() {
		const user = JSON.parse(localStorage.getItem("user"));

		setState(s => { return { ...s, loading: true }; });

		fetch(`https://api.funtik.su/wp-json/funtik/v1/reviews/${id}/delete`, {
			method: "POST",
			headers: {
				"Authorization": `Basic ${window.btoa(user.basic)}`
			},
		})
			.then(response => response.json())
			.then(data => {
				setState(s => { return { ...s, loading: false }; });
				if (data.success) {
					setShowPopup(false);
					setListState(s => {
						return s.filter(item => parseInt(item.review.id) !== parseInt(id));
					});
				} else {
					setState(s => { return { ...s, loading: false }; });
				}
			})
			.catch(() => {
				setState(s => { return { ...s, loading: false }; });
			});
	}

	return (
		<div className={`${styles.popupContainer}`} style={{display: "flex", alignItems: "center"}} onClick={() => setShowPopup(false)}>
			<PopupContainer closeEvent={() => setShowPopup(false)} title="Удалить отзыв?" style={{ maxWidth: 480, width: "100%", margin: "0 16px" }} smallTitle={true}>
				<div style={{ display: "flex", gap: 16, flexDirection: "column"}}>
					<div style={{display: "flex", gap: 8}}>
						<Button name="Да" color="blue" onClick={submitHandler} showPreloader={state.loading} s={{width: "100%", height: 46}} />
						<Button name="Нет" color="white" onClick={() => setShowPopup(false)} showPreloader={state.loading} s={{width: "100%", height: 46}} />
					</div>
				</div>
			</PopupContainer>
		</div>
	);
}