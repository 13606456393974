import { getAds } from "api/getAds";
import { setView } from "api/setView";
import AdsPage from "components/pages/Ads/AdsPage";
import { useLoaderData } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const localUser = JSON.parse(localStorage.getItem("user"));
	if (localUser?.basic) {
		setView(params.adsId, localUser.basic);
	}
	const ads = await getAds(params.adsId);
	if (!ads.success) {
		throw new Error("Объявление не найдено");
	}
	if (ads?.post?.status === "draft") {
		throw new Error("Объявление снятно с публикации");
	}
	return { ads };
}

export default function Ads() {
	const { ads } = useLoaderData();
	const city = JSON.parse(ads.post.address);

	let description = ads.post.description;
	if (description.length > 600) {
		description = `${description.substr(0, 600)}...`;
	}

	return <>
		<Helmet>
			<title>{ads.post.title} - объявление в {city.data.city ? city.data.city_with_type : city.data.region_with_type} | {ads.post.breadcrumbs[0].name} | Фунтик</title>
			<meta property="og:description" content={description}></meta>
			<meta name="description" content={description}></meta>
		</Helmet>

		<AdsPage data={ads.post} />
	</>;
}
