import React from "react";
import { SwipeableDrawer } from "@mui/material";
import { useContext } from "react";
import styles from "./filter.module.css";
import { FiltersDrawerContext } from "routes/main";
import { FilterContext } from "routes/subcategory";
import FiltersList from "./parts/Filters";
import { MainContext } from "context/MainContext";
import arrowIcon from "assets/images/arrow-black.svg";

export default function FilterDrawer() {
	const filtersDrawer = useContext(FiltersDrawerContext);
	const filters = useContext(FilterContext);
	const { city, setShowCityPopup } = useContext(MainContext);

	return (
		<SwipeableDrawer
			open={filtersDrawer.showDrawer}
			onClose={() => {
				filtersDrawer.setShowDrawer(false);
			}}
			onOpen={() => { }}
			anchor='bottom'
			disableSwipeToOpen={true}
			disableEnforceFocus={true}
		>
			<div className={styles.container}>
				<div className={`${styles.section} ${styles.sort}`}>
					<h3 className={styles.title}>Сортировка</h3>
					<div className={styles.sortList}>
						{filtersDrawer.sortList.map(sort =>
							<div className={styles.sortItem} onClick={() => filtersDrawer.setSort(sort)} key={sort.name}>
								{sort.name}
								{sort.name !== filtersDrawer.sort.name ? <div className={styles.radio}></div> : <div className={`${styles.radio} ${styles.selected}`}><div></div></div>}
							</div>
						)}
					</div>
				</div>
				<div className={`${styles.section}`}>
					<h3 className={styles.title}>Укажите город</h3>
					<div className={styles.filterInput} onClick={() => setShowCityPopup(true)}>
						<div className={styles.filterContent}>{city.value}</div>
						<img src={arrowIcon} alt="" />
					</div>
				</div>
				{filters?.get ? <FiltersList /> : <div style={{ padding: 16, color: "var(--main-color-placeholder)", fontSize: 18, lineHeight: 1.4 }}>Фильтры находятся на страницах категорий третьего уровня</div>}
			</div>
		</SwipeableDrawer>
	);
}