import React from "react";
import { DeviceContext } from "context/DeviceContext";
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./ads.module.css";

// import function to register Swiper custom elements
import { register as registerSwiper } from "swiper/element/bundle";

import * as DOMPurify from "dompurify";
import parse from "html-react-parser";

import AdsInfoBlock from "./parts/AdsInfoBlock";
import Button from "components/buttons/Button";

import { Fancybox } from "@fancyapps/ui";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import AdsSameList from "./parts/AdsSameList";

import starIcon from "assets/images/star-fill.svg";
import callIcon from "assets/images/call-white.svg";
import messageIcon from "assets/images/Message-white.svg";
import heartIcon from "assets/images/heart.svg";
import megaphoneIcon from "assets/images/Megaphone.svg";
import { FavoritesContext } from "context/FavoriteContext";

import { setFavorites as favoriteHandler, getFavorites } from "api/favorite";
import AdsHeader from "components/header/parts/Mobile/Parts/AdsHeader";

import balloonIcon from "assets/images/Balloon.svg";
import { Link } from "react-router-dom";
import { useChat } from "hooks/useChat";

import penIcon from "assets/images/pen-blue.svg";
import Report from "components/popups/Report";
Fancybox.bind("[data-fancybox=\"gallery\"]");

export default function AdsPage({ data }) {
	const address = JSON.parse(data.address);
	const localUser = JSON.parse(localStorage.getItem("user"));

	const device = useContext(DeviceContext);
	const favorites = useContext(FavoritesContext);
	const user = JSON.parse(localStorage.getItem("user"));

	// Чат / кнопка "Написать"
	const chat = useChat();
	const [buttonLoading, setButtonLoading] = useState(false);
	const [phoneButton, setPhoneButton] = useState({
		name: "Позвонить",
		color: "green",
		icon: callIcon,
		handler: () => {
			setPhoneButton({
				color: "white",
				name: data.user.phone,
				icon: null,
				handler: () => window.open(`tel:${data.user.phone}`, "_self")
			});
		}
	});

	// const location = useLocation();

	// useEffect(() => {
	//     localStorage.setItem('prevState', JSON.stringify(location.state.prevState))
	// }, [location.state.prevState])

	function setHistory(id) {
		let array = [];
		let history = localStorage.getItem("history");

		if (history) {
			array = JSON.parse(history);
		}

		if (array.indexOf(id) === -1) {
			array.reverse();
			array.push(id);
			array.reverse();
			array.length = 3;
		}

		return JSON.stringify(array);
	}

	useEffect(() => {
		let ignore = false;

		if (!ignore) {
			getFavorites(user?.basic)
				.then(
					list => favorites.set(list)
				);
		}

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
	}, [user?.basic, data])

	async function favbutton(e) {
		e.preventDefault();

		console.log(data);

		if (user) {
			favoriteHandler(data.id, user.basic)
				.then(
					list => favorites.set(list)
				);
		} else {
			const fv = await favoriteHandler(parseInt(data.id));
			favorites.set(fv);
		}
	}

	const mapRef = useRef(null);

	useEffect(() => {

		let loading = false;

		async function init() {
			mapRef.current.innerHTML = "";
			await window.ymaps3.ready;

			if (!loading) {
				let map = new window.ymaps3.YMap(mapRef.current, {
					location: {
						// Координаты центра карты
						// Порядок по умолчанию: «долгота, широта»
						center: [mapRef.current.getAttribute("data-lon"), mapRef.current.getAttribute("data-lat")],

						// Уровень масштабирования
						// Допустимые значения: от 0 (весь мир) до 21.
						zoom: 17
					}
				});

				map.addChild(new window.ymaps3.YMapDefaultSchemeLayer());
				map.addChild(new window.ymaps3.YMapDefaultFeaturesLayer());

				const content = document.createElement("section");
				const marker = new window.ymaps3.YMapMarker({
					coordinates: [mapRef.current.getAttribute("data-lon"), mapRef.current.getAttribute("data-lat")],
					draggable: false
				}, content);

				map.addChild(marker);
				content.innerHTML = `<img src="${balloonIcon}" height="32" alt="balloon" />`;

				const { YMapZoomControl, YMapGeolocationControl } = await window.ymaps3.import("@yandex/ymaps3-controls@0.0.1");
				const controls = new window.ymaps3.YMapControls({ position: "right" });
				controls.addChild(
					new YMapZoomControl({
						easing: "linear"
					})
				);
				controls.addChild(new YMapGeolocationControl());
				map.addChild(controls);
			}
		}

		init(loading);

		return () => {
			loading = true;
		};

	}, [device, data]);

	localStorage.setItem("history", setHistory(data.id));

	// register Swiper custom elements
	registerSwiper();

	function get_price(item) {
		if (item.isFree === "true") {
			return "Бесплатно";
		} else if (item.isEmpty === "true") {
			return "Цена не указана";
		} else {
			return `${new Intl.NumberFormat("ru-RU", { style: "decimal", currency: "RUB", currencyDisplay: "symbol" }).format(item.price)} ₽`;
		}
	}

	const [showReport, setShowReport] = useState(false);

	return (
		<>
			{showReport && <Report setShowPopup={setShowReport} id={data.id} />}
			{
				device === "Desktop" ? (
					<div className={`container ${styles.container}`}>
						<div className={styles.left}>
							<div className={styles.head}>
								<div className={styles.breadcrumbs}><Breadcrumbs current={data.title} links={[{ to: "/", name: "Главная" }, ...data.breadcrumbs]} /></div>
								<h2 className={styles.title}>{data.title}</h2>
							</div>
							<div className={styles.content}>
								<div className={styles.galleryContainer}>
									<swiper-container
										style={{
											"--swiper-navigation-color": "var(--main-color-white)",
											"--swiper-pagination-color": "var(--main-color-white)",
											"--swiper-navigation-size": "24px"
										}}
										slides-per-view="1"
										navigation="true"
										thumbs-swiper={`.${styles.thumbs}`}
										class={styles.gallery}
									>
										{data.photos.map(photo => {
											return <swiper-slide class={styles.slide} key={photo}>
												<img src={photo} alt="" data-fancybox="gallery" className={styles.photo} />
												<img src={photo} alt="" className={styles.photoBackground} />
											</swiper-slide>;
										})}
									</swiper-container>
									<swiper-container
										// watchSlidesProgress
										class={styles.thumbs}
										slides-per-view="auto"
										space-between="8"
									>
										{data.photos.map(photo => {
											return <swiper-slide class={styles.thumb} key={photo}>
												<img src={photo} alt="" />
											</swiper-slide>;
										})}
									</swiper-container>
								</div>
								<div className={styles.section}>
									<h3 className={styles.title}>Описание</h3>
									<p className={styles.description}>{parse(DOMPurify.sanitize(data.description))}</p>
								</div>
								{data.attributes && <div className={styles.section}>
									<h3 className={styles.title}>Характеристики</h3>
									<ul className={styles.attributes}>
										{Object.keys(data.attributes).map(attribute => { return <li key={attribute} className={styles.attribute}>{attribute}:<span>{data.attributes[attribute] ? data.attributes[attribute] : "Другое"}</span></li>; })}
									</ul>
								</div>}
								<div className={styles.section} id="map">
									<h3 className={styles.title}>На карте</h3>
									<div ref={mapRef} data-lat={address.data.geo_lat} data-lon={address.data.geo_lon} style={{ height: 300 }}></div>
								</div>
								<div className={styles.section}>
									<h3 className={styles.title}>Объявления из этой категории</h3>
									<AdsSameList id={data.id} />
								</div>
							</div>
						</div>
						<div className={styles.right}>
							<div className={styles.stickyContainer}>
								<div className={`${styles.title} ${styles.price}`}>{get_price(data)}</div>
								<div className={styles.userBlock}>
									<Link to={`/user/${data.user.id}`} className={styles.cardUser}>
										<img src={data.user.avatar} alt="User avatar" className={styles.cardUserAvatar} />
										<div className={styles.cardUserContent}>
											<div className={styles.cardUserName}>{data.user.name}</div>
											<div className={styles.cardUserReview}>
												{data.user.reviews} отзывов<div className={styles.cardUserRating}><img src={starIcon} alt='Star icon' />{data.user.rating}</div>
											</div>
										</div>
									</Link>
									{parseInt(data.user.id) !== parseInt(localUser?.id) ?
										<div className={styles.userButtons}>
											{data.user.phone && <Button name={phoneButton.name} color={phoneButton.color} icon={phoneButton.icon} onClick={phoneButton.handler} />}
											<Button name="Написать" icon={messageIcon} showPreloader={buttonLoading} onClick={() => {
												setButtonLoading(true);
												chat.buttonHandler(data.id)
													.then(message => {
														setButtonLoading(false);
														console.log(message);
													})
													.catch(message => {
														setButtonLoading(false);
														throw new Error(message);
													});
											}} />
										</div> :
										<>
											<div style={{ fontSize: 14, lineHeight: 1.4 }}>Это ваше объявление, перейти на <Link style={{ color: "var(--main-color-blue)" }} to={`/profile/ads/${data.id}`}>страницу управления?</Link></div>
											<Link to={`/profile/ads/${data.id}/edit`} className={styles.sidebarWhiteButton}><img src={penIcon} alt="" />Редактировать</Link>
										</>
									}
								</div>
								<AdsInfoBlock data={data} />
								<div className={styles.buttonsBlock}>
									<Button name={favorites.get().includes(parseInt(data.id)) ? "Убрать из избранного" : "В избранное"} color="white" className={styles.adsButton} icon={heartIcon} onClick={favbutton} />
									<Button name="Пожаловаться" color="white" className={styles.adsButton} icon={megaphoneIcon} onClick={() => setShowReport(true)}/>
								</div>
							</div>
						</div>
					</div>
				) : (
					<>
						<AdsHeader title={data.title} data={data} favbutton={favbutton} setShowReport={setShowReport} />
						<div className={styles.mobile}>
							<swiper-container
								style={{
									"--swiper-pagination-color": "var(--main-color-placeholder)",
									"--swiper-pagination-bullet-horizontal-gap": "3px",
									"--swiper-pagination-bullet-size": "8px",
									"--swiper-pagination-bullet-inactive-color": "var(--main-color-gray)",
									"--swiper-pagination-bullet-inactive-opacity": "1",
								}}
								slides-per-view="1"
								pagination="true"
								thumbs-swiper={`.${styles.thumbs}`}
								class={styles.gallery}
							>
								{data.photos.map(photo => {
									return <swiper-slide class={styles.slide} key={photo}>
										<img src={photo} alt="" data-fancybox="gallery" className={styles.photo} />
										<img src={photo} alt="" className={styles.photoBackground} />
									</swiper-slide>;
								})}
							</swiper-container>
							<div className={styles.mobileContainer}>
								<div className={styles.mobileTop}>
									<h2 className={styles.title}>{data.title}</h2>
									<div className={styles.price}>{get_price(data)}</div>
									<AdsInfoBlock data={data} />
								</div>
								<div className={styles.line}></div>
								<div className={styles.mobileUser}>
									<Link to={`/user/${data.user.id}`} className={styles.cardUser}>
										<img src={data.user.avatar} alt="User avatar" className={styles.cardUserAvatar} />
										<div className={styles.cardUserContent}>
											<div className={styles.cardUserName}>{data.user.name}</div>
											<div className={styles.cardUserReview}>
												{data.user.reviews} отзывов<div className={styles.cardUserRating}><img src={starIcon} alt='Star icon' />{data.user.rating}</div>
											</div>
										</div>
									</Link>
									{parseInt(data.user.id) !== parseInt(localUser?.id) ?
										<div className={styles.userButtons}>
											{data.user.phone && <Button name={phoneButton.name} color={phoneButton.color} icon={phoneButton.icon} onClick={phoneButton.handler} className={styles.mobileButton} />}
											<Button name="Написать" icon={messageIcon} className={styles.mobileButton} onClick={() => {
												setButtonLoading(true);
												chat.buttonHandler(data.id)
													.then(message => {
														setButtonLoading(false);
														console.log(message);
													})
													.catch(message => {
														setButtonLoading(false);
														throw new Error(message);
													});
											}} />
										</div> :
										<>
											<div style={{ fontSize: 16, lineHeight: 1.4 }}>Это ваше объявление, перейти на <Link style={{ color: "var(--main-color-blue)" }} to={`/profile/ads/${data.id}`}>страницу управления?</Link></div>
											<Link to={`/profile/ads/${data.id}/edit`} className={styles.sidebarWhiteButton}><img src={penIcon} alt="" />Редактировать</Link>
										</>
									}
								</div>
								<div className={styles.line}></div>
								<div className={styles.section}>
									<h3 className={styles.title}>Описание</h3>
									<p className={styles.description}>{parse(DOMPurify.sanitize(data.description))}</p>
								</div>
								<div className={styles.line}></div>
								{data.attributes && <><div className={styles.section}>
									<h3 className={styles.title}>Характеристики</h3>
									<ul className={styles.attributes}>
										{Object.keys(data.attributes).map(attribute => { return <li key={attribute} className={styles.attribute}>{attribute}:<span>{data.attributes[attribute] ? data.attributes[attribute] : "Другое"}</span></li>; })}
									</ul>
								</div>
								<div className={styles.line}></div></>}
								<div className={styles.section} id="map">
									<h3 className={styles.title}>На карте</h3>
									<div ref={mapRef} data-lat={address.data.geo_lat} data-lon={address.data.geo_lon} style={{ height: 250 }}></div>
								</div>
								<div className={styles.line}></div>
								<div className={styles.section}>
									<h3 className={styles.title}>Объявления из этой категории</h3>
									<AdsSameList id={data.id} />
								</div>
							</div>
						</div>
					</>
				)
			}
		</>
	);
}