import React from "react";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./ads.module.css";
import LayoutSwitcher from "./parts/LayoutSwitcher";
import { getAdsList } from "api/getAdsList";
import AdsCard from "./parts/AdsCard";
import Select from "components/select/Select";
import { DeviceContext } from "context/DeviceContext";
import { getFavorites } from "api/favorite";
import { FavoritesContext } from "context/FavoriteContext";
import { useLoaderData } from "react-router-dom";
import { FiltersDrawerContext } from "routes/main";
import { MainContext } from "context/MainContext";

export const LayoutContext = createContext(null);

export default function AdsList({ category = 0, parentStyles, title = "Свежие размещения", filters = false, propSort = false }) {
	const { posts, before, s } = useLoaderData();
	// Контекст
	const device = useContext(DeviceContext);
	const favorites = useContext(FavoritesContext);
	const user = JSON.parse(localStorage.getItem("user"));

	const filtersDrawer = useContext(FiltersDrawerContext);
	const { city } = useContext(MainContext);

	// Состояние
	const [state, setState] = useState({
		page: 2,
		before: before,
		posts: posts,
		postsEnd: posts.length < 16,
		category: category,
		initial: true,
		s: s || "",
		city: city,
		isCityPosts: !(posts.length < 16),
		notCityPosts: []
	});

	// Лайаут
	const [layout, setLayout] = useState(localStorage.getItem("ads-list-layout") || "grid");
	useEffect(() => {
		localStorage.setItem("ads-list-layout", layout);
	}, [layout]);

	// Пересечение
	const [isIntersecting, setIsIntersecting] = useState(false);

	// Сортировка
	const sortList = [
		{
			name: "По умолчанию",
			value: "new"
		},
		{
			name: "Старее",
			value: "old"
		},
		{
			name: "Дешeвле",
			value: "cheap"
		},
		{
			name: "Дороже",
			value: "exp"
		}
	];
	const [sort, setSort] = useState(propSort || sortList[0]);

	const ref = useRef();

	// Список избранных
	useEffect(() => {
		let ignore = false;

		if (!ignore) {
			getFavorites(user?.basic)
				.then(
					list => favorites.set(list)
				);
		}

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
    }, [])

	useEffect(() => {
		setSort(propSort);
	}, [propSort]);

	const fetching = useCallback(async (isNew, ignore, clearList = false, isNoCity = false) => {
		let page = isNew ? 1 : state.page;
		let before = isNew ? 0 : state.before;
		let isCityPosts = isNew ? true : state.isCityPosts;

		if (isNoCity) {
			page = 1;
		}

		document.querySelector("main").classList.add("loading");

		if (clearList) {
			setState(s => { return { ...s, posts: [], notCityPosts: [], isCityPosts: true }; });
		}

		await getAdsList({ before: before, page: page, sort: sort.value, category: category, filters: filters, s: s, city: city, isCityPosts: isCityPosts })
			.then(response => {
				if (!ignore)
					if (response.success === true) {
						let posts = [];
						for (let post in response.posts) {
							posts.push(response.posts[post]);
						}

						setState(s => {
							return {
								...s,
								before: response.before,
								posts: isCityPosts === true ? (isNew ? posts : [...s.posts, ...posts]) : s.posts,
								postsEnd: isCityPosts === false && (posts.length < 16),
								page: (page + 1),
								category: category,
								isCityPosts: isCityPosts === false ? false : !(posts.length < 16),
								notCityPosts: isCityPosts === false ? [...s.notCityPosts, ...posts] : s.notCityPosts
							};
						});
					} else {
						throw new Error("Ошибка");
					}
			});

		document.querySelector("main").classList.remove("loading");

	}, [category, sort, state, filters, s, city]);

	useEffect(() => {
		let ignore = false;

		if (filtersDrawer) {
			filtersDrawer.setSort(sort);
		}
		if (category !== state.category) {
			setState(s => { return { ...s, posts: posts, notCityPosts: [], category: category, postsEnd: posts.length < 16, page: 2, before: before }; });
			fetching(true, ignore, true);
		} else {
			if (!state.initial) {
				fetching(true, ignore, true);
			} else {
				setState(s => { return { ...s, initial: false }; });
			}
		}

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
    }, [sort, category, filters, s, city])

	useEffect(() => {
		let ignore = false;

		if (state.isCityPosts === false) {
			fetching(false, ignore, false, true);
		}
		console.log(state.isCityPosts);

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
    }, [state.isCityPosts])

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setIsIntersecting(entry.isIntersecting);
		});
		observer.observe(ref.current);
		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		if (isIntersecting && !state.postsEnd && state.posts.length > 15) {
			fetching(false, false);
		}
		// eslint-disable-next-line
    }, [isIntersecting]);

	return (
		<LayoutContext.Provider value={layout}>
			<div className={parentStyles.section}>
				<div className={styles.adsListTop}>
					<h3 className={styles.title}>{title}</h3>
					{/* <h3 className={parentStyles.title}>{title}</h3> */}
					<div className={styles.adsListControls}>
						{device === "Desktop" && <Select state={sort} setState={setSort} list={sortList} type="sort" callback={() => { }} />}
						<LayoutSwitcher styles={styles} state={layout} setState={setLayout} />
					</div>
				</div>
				{(state.posts.length !== 0 || state.notCityPosts.length !== 0) && <div className={`${styles.adsList} ${layout === "grid" ? styles.grid : styles.horizontal}`}>
					{state.posts.length !== 0 ? state.posts.map(post =>
						<AdsCard
							key={post.id}
							post={post}
							prevState={state}
						/>) :
						<div className={styles.noPosts}>Объявления в вашем городе не найдены</div>
					}
					{state.notCityPosts.length !== 0 && <>
						<h2 className={styles.listTitle}>Объявления из других городов</h2>
						{state.notCityPosts.map(post => <AdsCard
							key={post.id}
							post={post}
							prevState={state}
						/>)}
					</>}
				</div>}
				<div ref={ref} style={{ display: state.posts.length !== 0 ? "block" : "none" }}></div>
				{state.postsEnd && (state.posts.length !== 0 || state.notCityPosts.length !== 0) && <div className={styles.postsEndMessage}>Вы посмотрели все объявления</div>}
				{!state.posts.length && !state.notCityPosts.length && <div className={styles.haveNotPostsMessage}>Ничего не найдено</div>}
			</div>
		</LayoutContext.Provider >
	);
}