import React from "react";
import { NavLink, useRouteLoaderData } from "react-router-dom";

import styles from "./bottom.module.css";
import BottomMenuLink from "./BottomMenuLink";

import logoGray from "assets/images/logo-icon-placeholder.svg";
import logoBlue from "assets/images/logo-icon-blue.svg";
import favoritesGray from "assets/images/Favorites-gray.svg";
import favoritesBlue from "assets/images/Favorites-blue.svg";
import AdsGray from "assets/images/Ads-gray.svg";
import AdsBlue from "assets/images/Ads-blue.svg";
import messageGray from "assets/images/Message-gray.svg";
import messageGrayNew from "assets/images/Message-gray-new.svg";
import messageBlue from "assets/images/Message-blue.svg";
import profileGray from "assets/images/User-gray.svg";
import profileBlue from "assets/images/User-blue.svg";
import { useContext, useEffect } from "react";
import { DeviceContext } from "context/DeviceContext";
import { MainContext } from "context/MainContext";

export default function BottomMenu() {
	const device = useContext(DeviceContext);

	const { isHaveUnread } = useRouteLoaderData("root");
	const { haveUnread, setHaveUnread } = useContext(MainContext);

	useEffect(() => {
		setHaveUnread(isHaveUnread);
	}, [setHaveUnread, isHaveUnread]);

	const menu = [
		{
			link: "/",
			name: "Главная",
			icon: logoGray,
			activeIcon: logoBlue,
		},
		{
			link: "/favorites",
			name: "Избранное",
			icon: favoritesGray,
			activeIcon: favoritesBlue,
		},
		{
			link: "/profile/ads",
			name: "Объявления",
			icon: AdsGray,
			activeIcon: AdsBlue,
		},
		{
			link: "/profile/chat",
			name: "Сообщения",
			icon: haveUnread ? messageGrayNew : messageGray,
			activeIcon: messageBlue,
		}, {
			link: "/profile",
			name: "Профиль",
			icon: profileGray,
			activeIcon: profileBlue,
		}
	];

	const menuJsx = [];


	menu.forEach(item => {
		menuJsx.push(
			<NavLink key={menuJsx.length} to={item.link} className={({ isActive }) => isActive ? styles.active : ""} end>
				{({ isActive }) => (
					<BottomMenuLink isActive={isActive} item={item} />
				)}
			</NavLink>
		);
	});

	return device === "Mobile" ? (
		<div className={styles.bottomMenu}>{menuJsx}</div>
	) : null;
}