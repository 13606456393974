import styles from "../ads.module.css";
import { register as registerSwiper } from "swiper/element/bundle";

import dotsIcon from "assets/images/Dots.svg";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { LayoutContext } from "../AdsList";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

import starIcon from "assets/images/star-fill.svg";
import fcfIcon from "assets/images/Favorite-card-fill.svg";
import fceIcon from "assets/images/Favorite-card-empty.svg";
import fcbIcon from "assets/images/Favorite-card-blue.svg";
import penIcon from "assets/images/pen-blue.svg";

import viewsIcon from "assets/images/views.svg";
import contactsIcon from "assets/images/contacts.svg";
import favoritesIcon from "assets/images/favorites.svg";

import { setFavorites as favoriteHandler } from "api/favorite";
import { DeviceContext } from "context/DeviceContext";
import { FavoritesContext } from "context/FavoriteContext";
import React from "react";

export default function AdsCard({ post, useLayout = true, isMy = false }) {
	const [showMenu, setShowMenu] = useState(false);

	const layout = useContext(LayoutContext);
	const device = useContext(DeviceContext);
	const favorites = useContext(FavoritesContext);
	
	const date = new Date(post.gmt_date * 1000);
	const time = `0${date.getHours()}`.slice(-2) + ":" + `0${date.getMinutes()}`.slice(-2);

	const ref = useRef();
	const sliderRef = useRef(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.addEventListener("touchstart", e => e.stopPropagation());
			sliderRef.current.style.setProperty("--swiper-pagination-color", "var(--main-color-placeholder)");
			sliderRef.current.style.setProperty("--swiper-pagination-bullet-horizontal-gap", "2px");
			sliderRef.current.style.setProperty("--swiper-pagination-bullet-size", "6px");
			sliderRef.current.style.setProperty("--swiper-pagination-bullet-inactive-color", "var(--main-color-gray)");
			sliderRef.current.style.setProperty("--swiper-pagination-bullet-inactive-opacity", "1");
			post.photos.length !== 0 && sliderRef.current.initialize();
		}
	}, [sliderRef, post]);

	async function favbutton(e) {
		e.preventDefault();
		e.stopPropagation();
		let user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			favoriteHandler(post.id, user.basic)
				.then(
					list => favorites.set(list)
				);
		} else {
			const fv = await favoriteHandler(post.id);
			favorites.set(fv);
		}
	}

	// register Swiper custom elements
	registerSwiper();

	// Закрываем всплывающие элементы, если совершен клик вне элемента
	useEffect(() => {
		const documentClickHandler = (e) => {
			let isOver = (rf) => rf?.current?.contains(e.target);
			let checkOutside = function (flag, rp) {
				if (flag && !isOver(rp) && !isOver(rp)) {
					setShowMenu(false);
				}
			};
			// Вызываем функции передавая состояние и ref с ссылкой на элемент
			checkOutside(showMenu, ref);
		};
		showMenu && document.addEventListener("mousedown", documentClickHandler);
		return () => document.removeEventListener("mousedown", documentClickHandler);
	}, [showMenu, ref]);

	const navigation = useNavigation();

	function get_price(item) {
		if (item.isFree === "true") {
			return "Бесплатно";
		} else if (item.isEmpty === "true") {
			return "Цена не указана";
		} else {
			return `${new Intl.NumberFormat("ru-RU", { style: "decimal", currency: "RUB", currencyDisplay: "symbol" }).format(item.price)} ₽`;
		}
	}

	return (
		<div
			className={styles.card}
			onClick={() => {
				if (navigation.state === "idle") {
					navigate(isMy ? `/profile/ads/${post.id}` : `/ads/${post.id}`);
				}
			}}
		>
			<swiper-container
				slides-per-view="1"
				pagination="true"
				class={styles.gallery}
				ref={sliderRef}
				speed={device === "Mobile" ? 400 : 0}
				touch-events-target='wrapper'
				touch-start-prevent-default={true}
				init={false}
			>
				{post.photos.map(photo => {
					return <swiper-slide class={styles.slide} key={photo}>
						<img src={photo} alt="" className={styles.photo} loading="lazy" />
					</swiper-slide>;
				})}
			</swiper-container>
			<div className={styles.hoverBox} onMouseLeave={() => {
				sliderRef.current?.swiper?.slideTo(0);
			}}>
				{post.photos.map((item, key) => <div key={key} onMouseEnter={() => { sliderRef.current?.swiper?.slideTo(key); }}></div>)}
			</div>
			<div className={styles.cardContent}>
				<div className={styles.cardTop}>
					<Link to={isMy ? `/profile/ads/${post.id}` : `/ads/${post.id}`}><h4 className={styles.cardName}>{post.title}</h4></Link>
					<div className={styles.cardPrice}>{get_price(post)}</div>
					{layout === "horizontal" && device === "Desktop" && useLayout && <div className={styles.cardDescription}>{parse(DOMPurify.sanitize(post.description))}</div>}
				</div>
				<div className={styles.cardBottom}>
					<div className={styles.cardBottomContent}>
						<div>{post.address}</div>
						<div>{`${post.post_date}, ${time}`}</div>
					</div>
					<div className={styles.cardButtons}>
						{!isMy ?
							<div className={styles.cardFavoriteButton}>
								<img
									src={favorites.get().includes(post.id) ? fcfIcon : (layout === "grid" || !useLayout ? fceIcon : fcbIcon)}
									onClick={favbutton}
									alt="fav icon" />
							</div>
							:
							<Link to={`/profile/ads/${post.id}/edit`} className={styles.penButton} onClick={(e) => e.stopPropagation()}><img src={penIcon} alt="" /></Link>}
						<div
							className={styles.cardButton}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowMenu(true);
							}}>
							<img src={dotsIcon} alt="Dots icon" />
						</div>
					</div>

				</div>
				{isMy &&
					<div className={styles.statsList}>
						<div className={styles.statsItem}>
							<img src={viewsIcon} alt='' /> {post.stats.views}
						</div>
						<div className={styles.statsItem}>
							<img src={contactsIcon} alt='' /> {post.stats.contacts}
						</div>
						<div className={styles.statsItem}>
							<img src={favoritesIcon} alt='' /> {post.stats.favorites}
						</div>
					</div>
				}
				{showMenu &&
					<div className={styles.cardMenu} onClick={e => {
						e.preventDefault();
						e.stopPropagation();
					}} ref={ref}>
						<Link to={isMy ? `/profile/ads/${post.id}` : `/ads/${post.id}`} className={styles.cardMenuButton}>Открыть объявление</Link>
						{!isMy && <Link to={`/user/${post.user.id}`} className={styles.cardMenuButton}>Пользователь</Link>}
						{isMy && <Link to={`/profile/ads/${post.id}/edit`} className={styles.cardMenuButton}>Редактировать</Link>}
						<div onClick={favbutton} className={styles.cardMenuButton}>{favorites.get().includes(post.id) ? "Убрать из избранного" : "В избранное"}</div>
						{/* {!isMy && <div className={styles.cardMenuButton}>Пожаловаться</div>} */}
					</div>
				}
			</div>
			{layout === "horizontal" && useLayout && device === "Desktop" &&
				<Link to={`/user/${post.user.id}`} className={styles.cardUser} onClick={(e) => e.stopPropagation()}>
					<img src={post.user.avatar} alt="User avatar" className={styles.cardUserAvatar} />
					<div className={styles.cardUserContent}>
						<div className={styles.cardUserName}>{post.user.name}</div>
						<div className={styles.cardUserReview}>
							0 отзывов
							<div className={styles.cardUserRating}><img src={starIcon} alt='Star icon' />0.0</div>
						</div>
					</div>
				</Link>
			}
		</div>
	);
}