import React from "react";
import { getAds } from "api/getAds";
import styles from "../User/user.module.css";
import pageStyles from "../Ads/ads.module.css";
import { checkUser } from "api/checkUser";
import { redirect, useNavigate, useRouteLoaderData } from "react-router-dom";

import * as DOMPurify from "dompurify";
import parse from "html-react-parser";
import { useContext, useEffect, useRef } from "react";
import { DeviceContext } from "context/DeviceContext";
import balloonIcon from "assets/images/Balloon.svg";

import { register as registerSwiper } from "swiper/element/bundle";
import { ArchiveAdsContext } from "routes/profile";
import AdsInfoBlock from "../Ads/parts/AdsInfoBlock";
import starIcon from "assets/images/star-fill.svg";
import MyAdsPageHeader from "components/header/parts/Mobile/Parts/MyAdsPageHeader";
import Statistics from "components/popups/Statistics";

import viewsIcon from "assets/images/views.svg";
import contactsIcon from "assets/images/contacts.svg";
import favoritesIcon from "assets/images/favorites.svg";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const localUser = JSON.parse(localStorage.getItem("user"));
	const ads = await getAds(params.id, localUser.basic);
	const user = await checkUser(localUser.basic);

	if (!localUser) {
		return redirect(`/?show_login=true&from=/profile/ads/${params.id}`);
	}

	if (!user?.success) {
		throw new Error("Эта страница только для авторизированных пользователей");
	}

	if (parseInt(ads?.post?.user?.id) === parseInt(user?.id)) {
		const data = ads.post;
		return { data };
	} else {
		throw new Error("Страница не найдена");
	}

}

export default function ProfileMyAds() {
	const { data } = useRouteLoaderData("profileMyAds");
	const { setStatus, setIsArchiveAdsShow, status, showPopup, setShowPopup } = useContext(ArchiveAdsContext);

	const address = JSON.parse(data.address);

	const device = useContext(DeviceContext);

	const navigate = useNavigate();

	// register Swiper custom elements
	registerSwiper();

	useEffect(() => {
		setStatus(data.status);
		// eslint-disable-next-line
    }, [])


	const mapRef = useRef(null);

	useEffect(() => {

		let loading = false;

		async function init() {
			mapRef.current.innerHTML = "";
			await window.ymaps3.ready;

			if (!loading) {
				let map = new window.ymaps3.YMap(mapRef.current, {
					location: {
						// Координаты центра карты
						// Порядок по умолчанию: «долгота, широта»
						center: [mapRef.current.getAttribute("data-lon"), mapRef.current.getAttribute("data-lat")],

						// Уровень масштабирования
						// Допустимые значения: от 0 (весь мир) до 21.
						zoom: 17
					}
				});

				map.addChild(new window.ymaps3.YMapDefaultSchemeLayer());
				map.addChild(new window.ymaps3.YMapDefaultFeaturesLayer());

				const content = document.createElement("section");
				const marker = new window.ymaps3.YMapMarker({
					coordinates: [mapRef.current.getAttribute("data-lon"), mapRef.current.getAttribute("data-lat")],
					draggable: false
				}, content);

				map.addChild(marker);
				content.innerHTML = `<img src="${balloonIcon}" height="32" alt="balloon" />`;

				const { YMapZoomControl, YMapGeolocationControl } = await window.ymaps3.import("@yandex/ymaps3-controls@0.0.1");
				const controls = new window.ymaps3.YMapControls({ position: "right" });
				controls.addChild(
					new YMapZoomControl({
						easing: "linear"
					})
				);
				controls.addChild(new YMapGeolocationControl());
				map.addChild(controls);
			}
		}

		init(loading);

		return () => {
			loading = true;
		};

	}, [device, data]);

	function get_price(item) {
		if (item.isFree === "true") {
			return "Бесплатно";
		} else if (item.isEmpty === "true") {
			return "Цена не указана";
		} else {
			return `${new Intl.NumberFormat("ru-RU", { style: "decimal", currency: "RUB", currencyDisplay: "symbol" }).format(item.price)} ₽`;
		}
	}

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Мои объявления</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			{
				device === "Desktop" ? (
					<>
						<div className={`${styles.right} ${styles.profileMyAds}`}>
							<div className={pageStyles.content}>
								<div className={pageStyles.galleryContainer}>
									<h2 className={`${pageStyles.title} ${styles.profileMyAdsTitle}`}>{data.title}</h2>
									<swiper-container
										style={{
											"--swiper-navigation-color": "var(--main-color-white)",
											"--swiper-pagination-color": "var(--main-color-white)",
											"--swiper-navigation-size": "24px"
										}}
										slides-per-view="1"
										navigation="true"
										thumbs-swiper={`.${pageStyles.thumbs}`}
										class={pageStyles.gallery}
									>
										{data.photos.map(photo => {
											return <swiper-slide class={pageStyles.slide} key={photo}>
												<img src={photo} alt="" data-fancybox="gallery" className={pageStyles.photo} />
												<img src={photo} alt="" className={pageStyles.photoBackground} />
											</swiper-slide>;
										})}
									</swiper-container>
									<swiper-container
										// watchSlidesProgress
										class={pageStyles.thumbs}
										slides-per-view="auto"
										space-between="8"
									>
										{data.photos.map(photo => {
											return <swiper-slide class={pageStyles.thumb} key={photo}>
												<img src={photo} alt="" />
											</swiper-slide>;
										})}
									</swiper-container>
								</div>
								<div className={pageStyles.section}>
									<h3 className={pageStyles.title}>Описание</h3>
									<p className={pageStyles.description}>{parse(DOMPurify.sanitize(data.description))}</p>
								</div>
								<div className={pageStyles.section}>
									<h3 className={pageStyles.title}>Характеристики</h3>
									<ul className={pageStyles.attributes}>
										{Object.keys(data.attributes).map(attribute => { return <li key={attribute} className={pageStyles.attribute}>{attribute}:<span>{data.attributes[attribute] ? data.attributes[attribute] : "Другое"}</span></li>; })}
									</ul>
								</div>
								<div className={pageStyles.section} id="map">
									<h3 className={pageStyles.title}>На карте</h3>
									<div ref={mapRef} data-lat={address.data.geo_lat} data-lon={address.data.geo_lon} style={{ height: 300 }}></div>
								</div>
							</div>
						</div>
						{showPopup && <Statistics showPopup={showPopup} setShowPopup={setShowPopup} id={data.id} />}
					</>
				) : (
					<>
						<div className={styles.profileMyAdsTop}>
							<div className={styles.statsTop}>
								<div className={styles.statsList}>
									<div className={styles.statsItem}>
										<img src={viewsIcon} alt='' /> {data.stats.views}
									</div>
									<div className={styles.statsItem}>
										<img src={contactsIcon} alt='' /> {data.stats.contacts}
									</div>
									<div className={styles.statsItem}>
										<img src={favoritesIcon} alt='' /> {data.stats.favorites}
									</div>
								</div>
								<div className={styles.statsButton} onClick={() => setShowPopup(true)}>Статистика</div>
							</div>
							<div className={styles.blueButton} onClick={() => setIsArchiveAdsShow(true)}>{status === "draft" ? "Опубликовать" : "Снять с публикации"}</div>
						</div>
						<MyAdsPageHeader title={data.title} backEvent={() => navigate(-1)} data={data} />
						<div className={pageStyles.mobile}>
							<swiper-container
								style={{
									"--swiper-pagination-color": "var(--main-color-placeholder)",
									"--swiper-pagination-bullet-horizontal-gap": "3px",
									"--swiper-pagination-bullet-size": "8px",
									"--swiper-pagination-bullet-inactive-color": "var(--main-color-gray)",
									"--swiper-pagination-bullet-inactive-opacity": "1",
								}}
								slides-per-view="1"
								pagination="true"
								thumbs-swiper={`.${pageStyles.thumbs}`}
								class={pageStyles.gallery}
							>
								{data.photos.map(photo => {
									return <swiper-slide class={pageStyles.slide} key={photo}>
										<img src={photo} alt="" data-fancybox="gallery" className={pageStyles.photo} />
										<img src={photo} alt="" className={pageStyles.photoBackground} />
									</swiper-slide>;
								})}
							</swiper-container>
							<div className={pageStyles.mobileContainer}>
								<div className={pageStyles.mobileTop}>
									<h2 className={pageStyles.title}>{data.title}</h2>
									<div className={pageStyles.price}>{get_price(data)}</div>
									<AdsInfoBlock data={data} />
								</div>
								<div className={pageStyles.line}></div>
								<div className={pageStyles.mobileUser}>
									<div className={pageStyles.cardUser}>
										<img src={data.user.avatar} alt="User avatar" className={pageStyles.cardUserAvatar} />
										<div className={pageStyles.cardUserContent}>
											<div className={pageStyles.cardUserName}>{data.user.name}</div>
											<div className={pageStyles.cardUserReview}>
                                    0 отзывов<div className={pageStyles.cardUserRating}><img src={starIcon} alt='Star icon' />0.0</div>
											</div>
										</div>
									</div>
								</div>
								<div className={pageStyles.line}></div>
								<div className={pageStyles.section}>
									<h3 className={pageStyles.title}>Описание</h3>
									<p className={pageStyles.description}>{parse(DOMPurify.sanitize(data.description))}</p>
								</div>
								<div className={pageStyles.line}></div>
								<div className={pageStyles.section}>
									<h3 className={pageStyles.title}>Характеристики</h3>
									<ul className={pageStyles.attributes}>
										{Object.keys(data.attributes).map(attribute => { return <li key={attribute} className={pageStyles.attribute}>{attribute}:<span>{data.attributes[attribute] ? data.attributes[attribute] : "Другое"}</span></li>; })}
									</ul>
								</div>
								<div className={pageStyles.line}></div>
								<div className={pageStyles.section} id="map">
									<h3 className={pageStyles.title}>На карте</h3>
									<div ref={mapRef} data-lat={address.data.geo_lat} data-lon={address.data.geo_lon} style={{ height: 250 }}></div>
								</div>
							</div>
						</div>
						{showPopup && <Statistics showPopup={showPopup} setShowPopup={setShowPopup} id={data.id} />}
					</>
				)
			}
		</>
	);
}