import { DeviceContext } from "context/DeviceContext";
import React, { useContext, useEffect, useRef } from "react";
import headerStyles from "../../header/parts/Mobile/header-mobile.module.css";
import styles from "../User/user.module.css";
import { useLoaderData, useNavigate } from "react-router-dom";
import UserCard from "components/user/UserCard";
import { register as registerSwiper } from "swiper/element/bundle";
import { Helmet } from "react-helmet";


export async function loader() {
	const localUser = JSON.parse(localStorage.getItem("user"));
	const subscriptionsData = localUser?.basic ? await fetch("https://api.funtik.su/wp-json/funtik/v1/subscription/page", {
		method: "GET",
		headers: {
			"Authorization": `Basic ${window.btoa(localUser?.basic)}`
		}
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		}) : [];

	const subscriptionList = localUser?.basic ? await fetch("https://api.funtik.su/wp-json/funtik/v1/subscription/list", {
		method: "GET",
		headers: {
			"Authorization": `Basic ${window.btoa(localUser?.basic)}`
		}
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		}) : [];

	return {subscriptionsData, subscriptionList};
}

export default function ProfileSubscriptions() {
	const device = useContext(DeviceContext);
	const navigate = useNavigate();
	const {subscriptionsData} = useLoaderData();
	registerSwiper();
	const sliderRef = useRef(null);
	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.addEventListener("touchstart", e => e.stopPropagation());
			subscriptionsData.subscriptions.length !== 0 && sliderRef.current.initialize();
		}
	}, [sliderRef]);
	const sliderRef2 = useRef(null);
	useEffect(() => {
		if (sliderRef2.current) {
			sliderRef2.current.addEventListener("touchstart", e => e.stopPropagation());
			subscriptionsData.subscribers.length !== 0 && sliderRef2.current.initialize();
		}
	}, [sliderRef2]);

	return (<>
		<Helmet>
			<title>Личный кабинет - Подписки</title>
			<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
		</Helmet>
		{device === "Mobile" && 
			<header className={headerStyles.header}>
				<div className={`${headerStyles.headerContainer} ${styles.line}`}>
					<div className={headerStyles.back} onClick={() => { window.history.length === 1 ? navigate("/") : navigate(-1); }}></div>
					<h2 className={`${headerStyles.headerTitle}`}>Подписки</h2>
				</div>
			</header>}
		<div className={`${styles.right} ${styles.profileAds}`} style={device === "Mobile" ? {padding: "24px 16px", marginBottom: 48} : {}}>
			{device === "Desktop" && <h2 className={styles.name} style={{marginBottom: 16}}>Подписки</h2>}
			{subscriptionsData.subscriptions.length !== 0 && <div>
				<h3 style={{margin: 0, fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom: 16}}>Мои подписки ({subscriptionsData.subscriptions.length})</h3>
				<swiper-container
					slides-per-view="auto"
					class={styles.gallery}
					ref={sliderRef}
					speed={device === "Mobile" ? 400 : 0}
					touch-events-target='wrapper'
					touch-start-prevent-default={true}
					init={false}
					space-between={16}
					free-mode={true}
				>
					{subscriptionsData.subscriptions.map(item => <swiper-slide style={{width: "fit-content", height: "auto"}} key={item.id} ><UserCard user={item} type="subscriber"/></swiper-slide>)}
				</swiper-container>
			</div>}
			{subscriptionsData.subscribers.length !== 0 && <div>
				<h3 style={{margin: 0, fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom: 16}}>Мои подписчики ({subscriptionsData.subscribers.length})</h3>
				<swiper-container
					slides-per-view="auto"
					class={styles.gallery}
					ref={sliderRef2}
					speed={device === "Mobile" ? 400 : 0}
					touch-events-target='wrapper'
					touch-start-prevent-default={true}
					init={false}
					space-between={16}
					free-mode={true}
				>
					{subscriptionsData.subscribers.map(item => <swiper-slide style={{width: "fit-content", height: "auto"}} key={item.id} ><UserCard user={item} type="subscriber"/></swiper-slide>)}
				</swiper-container>
			</div>}
			{(subscriptionsData.subscribers.length === 0 && subscriptionsData.subscriptions.length === 0) && <div style={{fontSize: 20, color: "var(--main-color-placeholder)"}}>У вас нету подписок или подписчиков</div>}
		</div>
	</>
		
	);
}