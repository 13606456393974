import React from "react";
import Button from "components/buttons/Button";
import { useContext } from "react";
import { useLoaderData } from "react-router-dom";
import { FiltersDrawerContext } from "routes/main";
import { FilterContext } from "routes/subcategory";
import styles from "../filter.module.css";
import FilterGroupMobile from "./FilterGroup";

export default function FiltersList() {
	const filters = useContext(FilterContext);
	const filtersDrawer = useContext(FiltersDrawerContext);
	const { attributes } = useLoaderData();

	function renderFilters(attributes) {
		let list = [];

		for (let attr in attributes) {
			let attribute = attributes[attr];
			list.push(<FilterGroupMobile key={attribute.id} attribute={attribute} />);
		}

		return list;
	}

	async function resetHandler() {
		filters.resetFilters();
		filtersDrawer.setListFilters(false);
	}

	return (
		<div className={styles.filterList}>
			<div className={styles.section}>
				<h3 className={styles.title}>Цена</h3>
				<div className={styles.filterPrice}>
					<div className={styles.filterPriceInput}>
						<input type="text" value={filters.get.priceFrom.value} placeholder="От" onChange={(e) => filters.set(s => { return { ...s, priceFrom: { value: e.target.value } }; })} />
					</div>
					<div className={styles.filterPriceInput}>
						<input type="text" value={filters.get.priceTo.value} placeholder="до" onChange={(e) => filters.set(s => { return { ...s, priceTo: { value: e.target.value } }; })} />
					</div>
				</div>
			</div>
			{renderFilters(attributes)}
			<div className={`${styles.filterButtons} ${styles.section}`}>
				{filters.foundPosts !== 0 && <Button name={filters.loading ? "Загрузка" : `Показать ${filters.foundPosts} объявлений`} showPreloader={filters.loading} className={styles.filterButton} onClick={() => filtersDrawer.setListFilters(filters.setFiltersData())} />}
				{filters.foundPosts === 0 && <Button name="Ничего не найдено" color='disable' className={styles.disabledButton} showPreloader={filters.loading} />}
				<Button name="Очистить" color="white" className={styles.filterButton} onClick={resetHandler} />
			</div>
		</div>
	);
}