import React from "react";
import styles from "../header-mobile.module.css";

import favEmptyIcon from "assets/images/favorite-header-empty.svg";
import favFillIcon from "assets/images/favorite-header-fill.svg";
import callIcon from "assets/images/phone-header.svg";
import messageIcon from "assets/images/message-header.svg";
import shareIcon from "assets/images/share-header.svg";
import megaphoneIcon from "assets/images/Megaphone-black.svg";


import { useContext } from "react";
import { FavoritesContext } from "context/FavoriteContext";
import BackButton from "./BackButton";
import { Link } from "react-router-dom";
import { useChat } from "hooks/useChat";

export default function AdsHeader({ title, data, favbutton, setShowReport }) {
	const favorites = useContext(FavoritesContext);
	const localUser = JSON.parse(localStorage.getItem("user"));
	const chat = useChat();

	return (
		<header className={`${styles.header} ${styles.ads}`}>
			<div className={styles.headerContainer + ` ${styles.line}`}>
				<BackButton styles={styles} />
				<h2 className={`${styles.title}`}>{title}</h2>
				<div className={styles.adsButtons}>
					<div className={styles.adsButton} onClick={(e) => favbutton(e)}>
						<img src={favorites.get().includes(parseInt(data.id)) ? favFillIcon : favEmptyIcon} alt="" className={styles.adsButtonIcon} />
					</div>
					{parseInt(localUser?.id) !== parseInt(data.user.id) && <Link className={styles.adsButton} to={`tel:${data.user.phone}`}>
						<img src={callIcon} alt="" className={styles.adsButtonIcon} />
					</Link>}
					{parseInt(localUser?.id) !== parseInt(data.user.id) && <div className={styles.adsButton} onClick={() => {
						chat.buttonHandler(data.id)
							.then(message => {
								console.log(message);
							})
							.catch(message => {
								throw new Error(message);
							});
					}} >
						<img src={messageIcon} alt="" className={styles.adsButtonIcon} />
					</div>}
					<div className={styles.adsButton} onClick={() => window.navigator.share({ title: "Поделиться объявлением", text: `Funtik: ${data.title}`, url: `${window.location.origin}/ads/${data.id}` })}>
						<img src={shareIcon} alt="" className={styles.adsButtonIcon} />
					</div>
					{localUser && parseInt(localUser?.id) !== parseInt(data.user.id) && <div className={styles.adsButton} onClick={() => setShowReport(true)}>
						<img src={megaphoneIcon} alt="" className={styles.adsButtonIcon} />
					</div>}
				</div>
			</div>
		</header>
	);
}