import React from "react";
import { getManyAds } from "api/getAds";
import { useContext, useEffect, useState } from "react";
import adsStyles from "./ads.module.css";
import { Link } from "react-router-dom";
import { FavoritesContext } from "context/FavoriteContext";
import { setFavorites as favoriteHandler } from "api/favorite";
import fcbIcon from "assets/images/Favorite-card-blue.svg";
import fcfIcon from "assets/images/Favorite-card-fill.svg";

export default function AdsLast({ styles }) {
	const [state] = useState(localStorage.getItem("history") || false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const user = JSON.parse(localStorage.getItem("user"));

	const favorites = useContext(FavoritesContext);

	useEffect(() => {
		let ignore = false;

		if (!ignore) {
			let ids = JSON.parse(state);
			if (ids) {
				getManyAds(ids)
					.then(data => {
						if (data.list.length !== 0) {
							setLoading(true);
						} else {
							setLoading(false);
						}
						setData(data.list);
					});
			}
		}

		return () => {
			ignore = true;
		};
	}, [state]);

	function get_price(item) {
		if (item.isFree === "true") {
			return "Бесплатно";
		} else if (item.isEmpty === "true") {
			return "Цена не указана";
		} else {
			return `${new Intl.NumberFormat("ru-RU", { style: "decimal", currency: "RUB", currencyDisplay: "symbol" }).format(item.price)} ₽`;
		}
	}

	return loading && (
		<div className={adsStyles.smallAdsContainer}>
			<h3 className={styles.subtitle}>Вы недавно смотрели</h3>
			<div className={adsStyles.smallCardList}>
				{data.map(item =>
					<Link to={`/ads/${item.id}`} key={item.id} className={adsStyles.smallCard}>
						<img src={item.photos[0]} alt={item.title} className={adsStyles.smallCardImg} />
						<div className={adsStyles.smallCardContent}>
							<div className={adsStyles.smallCardName}>{item.title}</div>
							<div className={adsStyles.smallCardPrice}>{get_price(item)}</div>
						</div>
						<div className={adsStyles.smallFavoriteButton}>
							<img
								src={favorites.get().includes(item.id) ? fcfIcon : fcbIcon}
								onClick={
									async (e) => {
										e.preventDefault();
										if (user) {
											favoriteHandler(item.id, user.basic)
												.then(
													list => favorites.set(list)
												);
										} else {
											const fv = await favoriteHandler(item.id);
											favorites.set(fv);
										}
									}
								}
								alt="fav icon" />
						</div>
					</Link>
				)}
			</div>
		</div>
	);
}