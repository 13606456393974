import React from "react";
import ProfileHeader from "components/header/parts/Mobile/Parts/ProfileHeader";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import styles from "../profile.module.css";
import { Link } from "react-router-dom";

import profileIcon from "assets/images/User-fill-black.svg";
import editIcon from "assets/images/pen-black.svg";
import commentIcon from "assets/images/comment-black.svg";
import chatIcon from "assets/images/chat-black.svg";
import starIcon from "assets/images/star-black.svg";
import leaveIcon from "assets/images/logout-black.svg";
import { useState } from "react";
import LogoutPopup from "components/popups/parts/LogoutPopup";

export default function ProfileMobileMenu() {
	const { user } = useRouteLoaderData("profile");
	const navigate = useNavigate();

	function logout() {
		localStorage.removeItem("user");
		navigate("/login");
	}

	const [showLogoutPopup, setShowLogoutPopup] = useState(false);

	const menu = [
		{
			name: "Показать профиль",
			icon: profileIcon,
			link: "/profile/me",
		},
		{
			name: "Редактирование профиля",
			icon: editIcon,
			link: "/profile/edit",
		},
		{
			name: "Объявления",
			icon: chatIcon,
			link: "/profile/ads",
		},
		{
			name: "Мои отзывы",
			icon: commentIcon,
			link: "/profile/reviews/my",
		},
		{
			name: "Оставленные отзывы",
			icon: commentIcon,
			link: "/profile/reviews/outcoming",
		},
		{
			name: "Оставьте оценку",
			icon: starIcon,
			link: "/profile/reviews/new",
		},
		{
			name: "Подписки",
			icon: profileIcon,
			link: "/profile/subscriptions",
		},
		{
			name: "Политика конфиденциальности",
			icon: profileIcon,
			link: "/privacy-policy",
		},
		{
			name: "Выйти из аккаунта",
			icon: leaveIcon,
			link: "",
			action: () => setShowLogoutPopup(true)
		},
	];
	return (
		<>
			<ProfileHeader title={user.name} />
			<div className={styles.profileMenuMobile}>
				{menu.map((item, key) => {
					return item.link ?
						<Link key={key} to={item.link} className={styles.menuItem}><img src={item.icon} className={styles.menuItemIcon} alt="" />{item.name}</Link> :
						<div key={key} className={styles.menuItem} onClick={item.action}><img src={item.icon} className={styles.menuItemIcon} alt="" />{item.name}</div>;
				})}
			</div>
			{showLogoutPopup && <LogoutPopup closeEvent={() => setShowLogoutPopup(false)} leaveEvent={logout} />}
		</>
	);
}