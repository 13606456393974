import { getPrivacyPage } from "api/getPrivacyPage";
import StandartPage from "components/pages/Standart/StandartPage";
import React from "react";


export async function loader() {
	const response = await getPrivacyPage();
	if (response.success) {
		const data = {
			title: response.title,
			content: response.content
		};
		return { data };
	} else {
		throw new Error(response.message);
	}
}

export default function Standart() {
	return <StandartPage />;
}