export async function getProfileAds(basic) {
	return fetch("https://api.funtik.su/wp-json/funtik/v1/profile/post", {
		method: "GET",
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => { return data; })
		.catch(e => {
			return e;
		});
}