import { setFormData } from "./methods";

export async function changeAdsStatus(basic, id, status) {
	const data = setFormData({
		id: id,
		status: status,
	});

	return fetch("https://api.funtik.su/wp-json/funtik/v1/post/change_status", {
		method: "POST",
		body: data,
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => { return data; })
		.catch(e => {
			return e;
		});
}