import { getStandartPage } from "api/getStandartPage";
import StandartPage from "components/pages/Standart/StandartPage";
import React from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";


export async function loader({ params }) {
	const response = await getStandartPage(params.id);
	if (response.success) {
		const data = {
			title: response.title,
			content: response.content
		};
		return { data };
	} else {
		throw new Error(response.message);
	}
}

export default function Standart() {
	const {data} = useLoaderData();
	let description = data.content.replace(/(<([^>]+)>)/gi, "");
	if (description.length > 600) {
		description = `${description.substr(0, 600)}...`;
	}
	return (
		<>
			<Helmet>
				<title>{data.title} | Фунтик</title>
				<meta name="description" content={description}/>
				<meta property="og:description"content={description}/>
			</Helmet>
			<StandartPage />
		</>
	);
}