import React, { useContext } from "react";
import starIcon from "assets/images/star-black.svg";
import commentIcon from "assets/images/comment-black.svg";
import styles from "../../User/user.module.css";

import starEmptyIcon from "assets/images/star-empty.svg";
import starFillIcon from "assets/images/star-fill.svg";
import { DeviceContext } from "context/DeviceContext";

export default function ReviewsInfo({reviewsInfo}) {
	const device = useContext(DeviceContext);
	return (
		<div>
			<div className={styles.reviewsTitle} style={{marginBottom: 16}}>
				<div className={styles.reviewsTitleItem}>
					<img src={starIcon} alt="" />
					{reviewsInfo.length !== 0 ? (reviewsInfo.reduce((sum, current) => sum + current, 0) / reviewsInfo.length).toFixed(1) : "0.0"}
				</div>
				<div className={styles.reviewsTitleItem}>
					<img src={commentIcon} alt="" />
					{reviewsInfo.length} отзыв{
						(() => {
							let last = reviewsInfo.length.toString().slice(-1);
							switch(last) {
							case "1":
								return "";
							case "2": 
								return "а";
							case "3":
								return "а";
							case "4":
								return "а";
							default:
								return "ов";
							}
						})()
					}
				</div>
			</div>
			{reviewsInfo.length !== 0 && <div style={{display: "flex", flexDirection: "column", gap: 8}}>
				{[5, 4, 3, 2, 1].map(value => {
					let count = reviewsInfo.filter(val => parseInt(val) === parseInt(value)).length;
					let length = reviewsInfo.length;
					return (
						<div key={value} style={{display: "flex", gap: device === "Desktop" ? 10 : 4, alignItems: "center", fontSize: device === "Desktop" ? 16 : 13}}>
							{[1, 2, 3, 4, 5].map(i => <img key={i} src={i <=  value ? starFillIcon : starEmptyIcon} alt="" style={{width: 16, height: 16}} />)}
							<div style={{margin: device === "Mobile" ? "0 6px" : "", borderRadius: 4, height: 4, background: "var(--main-color-gray)", position: "relative", width: "100%"}}>
								<div style={{width: `${count / length * 100}%`, borderRadius: 4, height: 4, background: "var(--main-color-placeholder)", position: "absolute", top: 0, left: 0}}></div>
							</div>
							{count}
						</div>
					);
				}
				)}
			</div>}
		</div>
	);
}