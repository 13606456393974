import { setFormData } from "./methods";

export async function searchCity(s, clean = false) {
	const data = setFormData({
		query: s,
		clean: clean
	});

	return fetch("https://api.funtik.su/wp-json/funtik/v1/city", {
		method: "POST",
		body: data,
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}