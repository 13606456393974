import { getAdsList } from "api/getAdsList";
import { getMainCategories } from "api/getMainCategories";
import SearchHeader from "components/header/parts/Mobile/Parts/SearchHeader";
import MainPage from "components/pages/MainPage/MainPage";
import { DeviceContext } from "context/DeviceContext";
import { createContext, useContext, useEffect, useState } from "react";
import FilterDrawer from "components/drawer/FilterDrawer";
import { getStoriesList } from "api/getStoriesList";
import React from "react";
import { Helmet } from "react-helmet";

export const FiltersDrawerContext = createContext();

export async function loader({ params }) {
	const categoryResponse = await getMainCategories(params.category);
	const city = JSON.parse(localStorage.getItem("city"));
	const postsResponse = await getAdsList({ before: 0, page: 1, sort: "new", category: 0, city: city, isCityPosts: true });
	const stories = await getStoriesList();

	if (!postsResponse.success || !categoryResponse.success) {
		throw new Error("Произошла ошибка");
	} else {
		const categories = categoryResponse.categories;
		const posts = postsResponse.posts;
		const before = postsResponse.before;
		const current = { term_id: 0 };
		return { categories, posts, before, current, stories };
	}
}

export default function Main() {
	const device = useContext(DeviceContext);
	const [showDrawer, setShowDrawer] = useState(false);

	useEffect(() => {
		if (showDrawer) {
			document.body.style.position = "fixed";
		} else {
			document.body.style.position = "static";
		}
	}, [showDrawer]);

	// Сортировка
	const sortList = [
		{
			name: "По умолчанию",
			value: "new"
		},
		{
			name: "Старее",
			value: "old"
		},
		{
			name: "Дешeвле",
			value: "cheap"
		},
		{
			name: "Дороже",
			value: "exp"
		}
	];
	const [sort, setSort] = useState(sortList[0]);

	return (
		<>
			<Helmet>
				<title>Фунтик - объявления для питомцев</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<FiltersDrawerContext.Provider value={{ showDrawer: showDrawer, setShowDrawer: setShowDrawer, sort: sort, setSort: setSort, sortList: sortList }}>
				{device === "Mobile" && <SearchHeader />}
				{device === "Mobile" && <FilterDrawer />}
				<MainPage />
			</FiltersDrawerContext.Provider>
		</>
	);
}