import React from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import styles from "./footer.module.css";

export default function Footer() {
	const { siteOptions } = useRouteLoaderData("root");

	return (
		<footer>
			<div className={styles.footerTop}>
				<div className={`container ${styles.footerContainer}`}>
					{siteOptions.footer_links && siteOptions.footer_links.map((item, key) => { return <Link key={key} className={styles.footerLink} to={item.footer_links_url}>{item.footer_links_name}</Link>; })}
					<Link className={styles.footerLink} to="/privacy-policy">Политика конфиденциальности</Link>
				</div>
			</div>
			<div className={styles.footerBottom}>
				<div className={`container ${styles.footerContainer}`}>
					<div className={styles.footerItem}>Разработка и продвижение: <a className={styles.footerLink} href="https://lukevium.ru/">Lukevium</a></div>
					<div className={styles.footerItem}>По всем вопросам: <a className={styles.footerLink} href={`mailto:${siteOptions.footer_email}`}>{siteOptions.footer_email}</a></div>
					<div className={styles.footerCopy}>© {new Date().getFullYear()} Все права защищены</div>
				</div>
			</div>
		</footer>
	);
}