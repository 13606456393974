import React from "react";
import { useEffect, useState } from "react";
import styles from "../header-mobile.module.css";
import SearchInput from "./SearchInput";
import SearchPopup from "./SearchPopup";
import SearchInputCross from "./SearchInputCross";
import { useLocation } from "react-router-dom";
import FilterButton from "components/buttons/FilterButton";
import BackButton from "./BackButton";

export default function CategoryHeader() {
	const [showSearchPopup, setShowSearchPopup] = useState(false);
	const [s, setS] = useState("");
	const location = useLocation();

	useEffect(() => {
		closePopups();
	}, [location]);

	const closePopups = () => {
		setShowSearchPopup(false);
	};

	return (
		<header className={`${styles.header} ${styles.category}`}>
			<div className={styles.headerContainer + (showSearchPopup ? ` ${styles.line}` : "")}>
				<BackButton styles={styles} />
				<div className={styles.searchInputGroup}>
					<SearchInput styles={styles} setShowSearchPopup={setShowSearchPopup} showSearchPopup={showSearchPopup} setS={setS} />
					{showSearchPopup && <SearchInputCross styles={styles} setShowSearchPopup={setShowSearchPopup} />}
				</div>
				{!showSearchPopup && <FilterButton />}
			</div>
			{showSearchPopup && <SearchPopup s={s} styles={styles} />}
		</header >
	);
}