import React from "react";
import { useContext, useEffect } from "react";
import styles from "../filters.module.css";

import checkWhiteIcon from "assets/images/check-white.svg";
import checkBlueIcon from "assets/images/check-blue.svg";
import { FilterContext } from "routes/subcategory";

import otherIcon from "assets/images/other-icon.png";

export default function FilterGroup({ attribute }) {
	const filters = useContext(FilterContext);

	useEffect(() => {
		// Добавляет объекты вида attribute-${id}: {...terms: {value: checked}}
		filters.set(s => {
			return {
				...s,
				[`attribute-${attribute.id}`]: { ...attribute.terms.reduce((obj, key) => ({ ...obj, [key.slug]: { value: false } }), {}), other: { value: false } }
			};
		});

		// eslint-disable-next-line
}, [attribute])

	function changeHandler(e, term) {
		if (term === "other") {
			filters.set(s => {
				return {
					...s, [`attribute-${attribute.id}`]: {
						...s[`attribute-${attribute.id}`],
						other: { value: e.target.checked }
					}
				};
			});
		} else {
			filters.set(s => {
				return {
					...s, [`attribute-${attribute.id}`]: {
						...s[`attribute-${attribute.id}`],
						[term.slug]: { value: e.target.checked }
					}
				};
			});
		}
	}

	return (
		<div className={styles.filterGroup}>
			<h4 className={styles.filterGroupTitle}>{attribute.name}</h4>
			<div className={styles.filterGroupList}>
				{attribute.terms.map(term =>
					<div key={term.term_id} >
						<label className={`${styles.checkboxLabel} ${filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value ? styles.checked : ""}`}>
							{attribute.type === "checkbox" &&
								<div className={styles.checkbox}>
									{filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value && <img src={checkWhiteIcon} alt="" />}
								</div>
							}
							{attribute.type === "icon" &&
								<div className={styles.icon}>
									<img src={term.icon} alt="" className={styles.iconImage} />
									{filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value &&
								<div className={styles.iconCheck}>
									<img src={checkBlueIcon} alt="" />
								</div>
									}
								</div>
							}
							<input type="checkbox" checked={filters.get?.[`attribute-${attribute.id}`]?.[term.slug].value || false} name={term.slug} hidden onChange={(e) => changeHandler(e, term)} />
							<div className={styles.checkboxText}>{term.name}</div>
						</label>
					</div>
				)}
				<div>
					<label className={`${styles.checkboxLabel} ${filters.get?.[`attribute-${attribute.id}`]?.other.value ? styles.checked : ""}`}>
						{attribute.type === "checkbox" &&
							<div className={styles.checkbox}>
								{filters.get?.[`attribute-${attribute.id}`]?.other.value && <img src={checkWhiteIcon} alt="" />}
							</div>
						}
						{attribute.type === "icon" &&
							<div className={styles.icon}>
								<img src={otherIcon} alt="" className={styles.iconImage} />
								{filters.get?.[`attribute-${attribute.id}`]?.other.value &&
							<div className={styles.iconCheck}>
								<img src={checkBlueIcon} alt="" />
							</div>
								}
							</div>
						}
						<input type="checkbox" checked={filters.get?.[`attribute-${attribute.id}`]?.other.value || false} name="other" hidden onChange={(e) => changeHandler(e, "other")} />
						<div className={styles.checkboxText}>Другое</div>
					</label>
				</div>
			</div>
		</div>
	);
}