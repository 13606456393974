import React from "react";
import { Link } from "react-router-dom";
import styles from "../header.module.css";
import { useEffect, useState } from "react";

import { search } from "api/search";

export default function SearchList({ showSearchList, s }) {
	const [list, setList] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		search(s).then(result => setData(result));
	}, [s]);
	
	useEffect(() => {
		setList([]);
		if (data.count && s) {
			setList([<Link key={"count"} to={`/search?s=${s}`} className={styles.searchItem}>Показать {data.count} объявлений по запросу &quot;{s.length > 47 ? `${s.slice(0, 50)}...` : s}&quot;</Link>]);
		}
		if (data.terms?.length && data.terms.length !== 0) {
			for (let i = 0; i < (data.terms.length < 5 ? data.terms.length : 5); i++) {
				setList(list => [...list, <Link key={data.terms[i].slug} to={data.terms[i].breadcrumbs[data.terms[i].breadcrumbs.length - 1].to} className={styles.searchItem}>{data.terms[i].name}</Link>]);
			}
		}
	}, [data, s]);

	return <div className={styles.searchList + (showSearchList ? "" : ` ${styles.hidden}`)}>{list.length !== 0 ? list : <div className={styles.searchListMessage}>Ничего не найдено</div>}</div>;
}