import React from "react";
import { useNavigate, useNavigation } from "react-router-dom";

export default function BackButton({ styles }) {
	const navigate = useNavigate();
	const navigation = useNavigation();
	return (
		<div className={styles.back} onClick={() => {
			if (navigation.state === "idle") {
				window.history.length === 1 ? navigate("/", { replace: true }) : navigate(-1, { replace: true });
			}
		}
		}></div>
	);
}