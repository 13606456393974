import { setFormData } from "./methods";

export async function getStatistics(basic, data) {
	const body = setFormData(data);

	return fetch(`https://api.funtik.su/wp-json/funtik/v1/statistics/${data.id}`, {
		method: "POST",
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		},
		body
	})
		.then(response => response.json())
		.then(data => { return data; })
		.catch(e => {
			return e;
		});
}