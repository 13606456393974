import React from "react";
import { Link } from "react-router-dom";

import styles from "../catalog.module.css";

export default function SubcategoryList({ category, categoryObj, parent }) {
	let subcategoryListJsx = [];

	for (let subcat in categoryObj.children) {
		subcategoryListJsx.push(<Link key={subcat} to={`/category/${parent}/${category}/${subcat}`}>{categoryObj.children[subcat].name}</Link>);
	}

	return (
		<div className={styles.subcategoryList}>
			<Link className={styles.subcategoryLink} to={`/category/${parent}/${category}`}>{categoryObj.name}</Link>
			{subcategoryListJsx}
		</div>
	);
}