import { checkUser } from "api/checkUser";
import { getUserPage } from "api/getUserPage";
import UserPage from "components/pages/User/UserPage";
import { redirect, useLoaderData } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const localUser = JSON.parse(localStorage.getItem("user"));
	if (localUser) {
		const checkResponse = await checkUser(localUser?.basic);
		if (checkResponse.success) {
			if (parseInt(params.id) === parseInt(localUser?.id)) {
				return redirect("/profile");
			}
		}
	}

	const response = await getUserPage(params.id);

	if (response.success) {
		const reviewsInfo = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${params.id}/reviews/info`, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					return data.data;
				}
				throw new Error("Произошла ошибка");
			})
			.catch((e) => {
				throw new Error(e);
			});

		const userReviews = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${params.id}/reviews`, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					return data.data;
				}
				throw new Error("Произошла ошибка");
			})
			.catch((e) => {
				throw new Error(e);
			});
	
		const subscriptionList = localUser?.basic ? await fetch("https://api.funtik.su/wp-json/funtik/v1/subscription/list", {
			method: "GET",
			headers: {
				"Authorization": `Basic ${window.btoa(localUser?.basic)}`
			}
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					return data.data;
				}
				throw new Error("Произошла ошибка");
			})
			.catch((e) => {
				throw new Error(e);
			}) : [];

		const user = response.user;
		return { user, reviewsInfo, userReviews, subscriptionList };
	} else {
		throw new Error(response.message);
	}
}

export default function User() {
	const {user} = useLoaderData();
	return (<>
		<Helmet>
			<title>{user.name} - Профиль пользователя | Фунтик</title>
			<meta name="description" content={`Профиль пользователя ${user.name} в сервисе Фунтик. Объявления и отзывы пользователя ${user.name}`}/>
			<meta property="og:description" content={`Профиль пользователя ${user.name} в сервисе Фунтик. Объявления и отзывы пользователя ${user.name}`}/>
		</Helmet>
		<UserPage />
	</>);
}