export const useFormData = (data, setData) => {
	// Обработчик на инпут, меняет данные при смене значения в инпуте, также убирает ошибку, цепляется через name атрибут
	const onChange = (e) => {
		setData({
			...data,
			[e.target.name]: {
				...data[e.target.name],
				value: e.target.value,
				showError: false
			}
		});
	};

	// Устанавливает ошибку на выбранный параметр
	const setError = (field, error, callback) => {
		setData({
			...data,
			[field]: {
				...data[field],
				errorText: error,
				showError: true
			}
		});
		callback && callback();
	};

	const changeData = (field, param, value) => {
		setData({
			...data,
			[field]: {
				...data[field],
				[param]: value
			}
		});
	};

	// Действие при отправке
	const onSubmit = async (action, callback, errorAction) => {
		await action(data)
			.then(async data => {
				if (data.success === true) {
					callback(data);
				} else {
					errorAction(data.message);
				}
			})
			.catch(error => {
				errorAction(error);
			});

	};

	return { data, setData, onChange, setError, onSubmit, changeData };
};