import React from "react";
import { Form, useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "../header.module.css";
import Button from "components/buttons/Button";
import SearchList from "./SearchList";

export default function SearchInput({ showSearchList, setShowSearchList, closePopups, innerRef }) {
	const { s } = useLoaderData();
	const [query, setQuery] = useState(s);

	const inputHandler = (e) => {
		closePopups();
		setQuery(e.target.value);
		setShowSearchList(true);
	};

	useEffect(() => {
		document.getElementById("search").value = s;
	}, [s]);

	return (
		<Form method="get" action="/search" className={styles.searchContainer} ref={innerRef}>
			<div className={styles.searchInputWrapper}>
				<input
					type="text"
					name="s"
					id="search"
					placeholder="Введите поисковой запрос"
					defaultValue={s}
					className={styles.searchInput}
					onChange={inputHandler}
					onFocus={inputHandler}
					autoComplete="off"
				/>
				<Button name={"Найти"} color={"green"} type="submit" />
			</div>
			{<SearchList showSearchList={showSearchList} s={query} />}
		</Form>
	);
}