import React from "react";
import styles from "./popup.module.css";
import PopupContainer from "./parts/PopupContainer";
import { useContext, useState } from "react";
import { ArchiveAdsContext } from "routes/profile";
import { useRouteLoaderData } from "react-router-dom";
import Button from "components/buttons/Button";
import { AuthContext } from "context/AuthContext";
import { changeAdsStatus } from "api/changeAdsStatus";

export default function ArchiveAds() {
	const { setIsArchiveAdsShow, status, setStatus } = useContext(ArchiveAdsContext);
	const { data } = useRouteLoaderData("profileMyAds");
	const { user } = useContext(AuthContext);
	const [loader, setLoader] = useState(false);

	async function changeStatus() {
		setLoader(true);
		let paramStatus = status === "draft" ? "publish" : "draft";
		console.log(data);
		const response = await changeAdsStatus(user.basic, data.id, paramStatus);
		if (response.success) {
			setStatus(response.status);
		}
		setLoader(false);
		setIsArchiveAdsShow(false);
	}

	return (
		<div className={`${styles.popupContainer} ${styles.ArchiveAds}`} onClick={() => setIsArchiveAdsShow(false)}>
			<PopupContainer closeEvent={() => setIsArchiveAdsShow(false)} title={status === "draft" ? "Опубликовать объявление?" : "Снять объявление с публикации?"}>
				<div className={styles.archiveAdsButtons}>
					<Button name='Да' onClick={changeStatus} showPreloader={loader} />
					<Button name='Нет' color='white' onClick={() => setIsArchiveAdsShow(false)} showPreloader={loader} />
				</div>
			</PopupContainer>
		</div>);
}