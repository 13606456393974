export async function getChatList(basic) {

	return fetch("https://api.funtik.su/wp-json/funtik/v1/chat/list", {
		method: "POST",
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}