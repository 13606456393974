import React from "react";
import { useContext, useState } from "react";

import styles from "./popup.module.css";
import overlayStyles from "../overlay/overlay.module.css";

import { DeviceContext } from "context/DeviceContext";
import PopupContainer from "./parts/PopupContainer";
import Overlay from "components/overlay/Overlay";

import TextareaGroup from "components/forms/parts/TextareaGroup";
import Button from "components/buttons/Button";
import { setFormData } from "api/methods";

export default function Report({ setShowPopup, id }) {
	const device = useContext(DeviceContext);

	const [state, setState] = useState({
		content: {
			value: "",
			showError: false,
			errorText: ""
		},
		loading: false
	});

	async function submitHandler() {
		const user = JSON.parse(localStorage.getItem("user"));

		setState(s => { return { ...s, content: { ...s.content, showError: false }, loading: true }; });

		if (state.content.value.length === 0) {
			setState(s => { return { ...s, content: { ...s.content, showError: true, errorText: "Напишите причину жалобы" }, loading: false }; });
			return;
		}

		if (state.content.value.length > 2000) {
			setState(s => { return { ...s, content: { ...s.content, showError: true, errorText: "Количество символов в ответе не должно превышать 2000" }, loading: false }; });
			return;
		}

		fetch(`https://api.funtik.su/wp-json/funtik/v1/reports/${id}/create`, {
			method: "POST",
			headers: {
				"Authorization": `Basic ${window.btoa(user.basic)}`
			},
			body: setFormData({
				content: state.content.value
			})
		})
			.then(response => response.json())
			.then(data => {
				setState(s => { return { ...s, loading: false }; });
				if (data.success) {
					setShowPopup(false);
				} else {
					setState(s => { return { ...s, content: { ...s.content, showError: true, errorText: data.message}, loading: false  }; });
				}
			})
			.catch(e => {
				setState(s => { return { ...s, content: { ...s.content, showError: true, errorText: e}, loading: false  }; });
			});
	}

	return device === "Desktop" ? (
		<div className={`${styles.popupContainer}`} onClick={() => setShowPopup(false)}>
			<PopupContainer closeEvent={() => setShowPopup(false)} title="Жалоба на объявление" style={{ width: 660 }}>
				<div style={{ display: "flex", gap: 16, flexDirection: "column"}}>
					<TextareaGroup maxHeight={200} state={state} name="content" type="text" placeholder="Напишите причину" onChange={(e) => setState(s => { return { ...s, content: { ...s.content, value: e.target.value } }; })} />
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Button onClick={submitHandler} showPreloader={state.loading} name="Отправить" color="green" className={styles.submitReview} />
						<div style={{ color: "var(--main-color-placeholder)", fontSize: 18, lineHeight: 1.4, fontWeight: 500 }}><span style={{ color: state.content.value.length > 2000 ? "var(--main-color-red)" : "var(--main-color-black)" }}>{state.content.value.length}</span> / 2000</div>
					</div>
				</div>
			</PopupContainer>
		</div>
	) : (
		<Overlay backEvent={() => setShowPopup(false)} title="Жалоба на объявление" container={overlayStyles.attributes}>
			<div style={{ display: "flex", gap: 16, flexDirection: "column"}}>
				<TextareaGroup maxHeight={200} state={state} name="content" type="text" placeholder="Напишите причину" onChange={(e) => setState(s => { return { ...s, content: { ...s.content, value: e.target.value } }; })} />
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div style={{ marginLeft: "auto", color: "var(--main-color-placeholder)", fontSize: 18, lineHeight: 1.4, fontWeight: 500 }}><span style={{ color: state.content.value.length > 2000 ? "var(--main-color-red)" : "var(--main-color-black)" }}>{state.content.value.length}</span> / 2000</div>
				</div>
				<div style={{position: "absolute", bottom: 0, left: 0, padding: 16, background: "var(--main-color-white)", borderTop: "1px solid var(--main-color-gray)", width: "100%"}}>
					<Button onClick={submitHandler} showPreloader={state.loading} name="Отправить" color="green" className={styles.submitReview} />
				</div>
			</div>
		</Overlay>
	);
}