export async function getAvatar(id) {
	const data = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/avatar/${id}`)
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}