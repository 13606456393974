import React from "react";
import ProfileHeader from "components/header/parts/Mobile/Parts/ProfileHeader";
import UserAuth from "./parts/UserAuth";
import styles from "./profile.module.css";
import { useContext } from "react";
import { DeviceContext } from "context/DeviceContext";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ProfileLogin() {
	const device = useContext(DeviceContext);
	return device === "Mobile" ? (
		<>
			<Helmet>
				<title>Личный кабинет - вход</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<ProfileHeader title={"Профиль"} />
			<div className={styles.mobileContainer}>
				<UserAuth />
			</div>
		</>
	) : (
		<Navigate to="/?show_login=true" />
	);
}