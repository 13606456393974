import { setFormData } from "./methods";

export async function getUserAds(id) {
	const body = setFormData({
		id: id
	});
	return fetch("https://api.funtik.su/wp-json/funtik/v1/user/post", {
		method: "POST",
		body: body,
	})
		.then(response => response.json())
		.then(data => { return data; })
		.catch(e => {
			return e;
		});
}