import { DataContext } from "components/pages/CreateAds/CreateAdsPage";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../form.module.css";
import Button from "components/buttons/Button";
import { StepContext } from "components/pages/CreateAds/CreateAdsPage";
import Select from "components/popups/Select";

export default function SelectAttributes({ styles }) {
	const { data, attributes } = useContext(DataContext);
	const { setStep, stepList } = useContext(StepContext);

	const [selects, setSelects] = useState(null);
	const [popups, setPopups] = useState(null);

	const [showError, setShowError] = useState(false);

	function clickHandler() {
		if (!stepList["5"].isBlock) {
			setStep("5");
		} else {
			setShowError(true);
		}
	}

	useEffect(() => {
		let selectList = [];
		let popupList = [];

		for (let attribute in attributes) {
			selectList.push(
				<label
					key={attribute}
					className={inputStyles.inputGroup}
					onClick={() => {
						data.setData(d => { return { ...d, attributes: { ...d["attributes"], [attribute]: { ...d["attributes"][attribute], show: true } } }; });
					}}
				>
					{attributes[attribute].name}
					<div className={inputStyles.inputSelect}>
						{data.data.attributes[attribute].name || "Нажмите чтобы указать"}
					</div>
				</label>
			);
			popupList.push(<React.Fragment key={attribute}>
				{
					data.data.attributes[attribute].show &&
					<Select
						attribute={attributes[attribute]}
						setShowPopup={() => {
							data.setData(d => { return { ...d, attributes: { ...d["attributes"], [attribute]: { ...d["attributes"][attribute], show: false } } }; });
						}}
						setS={data.setData}
						s={data.data}
					/>
				}
			</React.Fragment>);
		}

		setSelects(selectList);
		setPopups(popupList);

	}, [attributes, data]);


	return (
		<>
			<div className={styles.selectsContainer}>
				{selects}
				{showError && <span className={inputStyles.errorText}>Нужно указать значение у всех характеристик</span>}
			</div>
			{popups}
			<div className={styles.buttons}><Button name="Назад" className={styles.backButton} onClick={() => setStep("3")} /><Button name="Продолжить" className={styles.nextButton} onClick={clickHandler} /></div>
		</>
	);
}