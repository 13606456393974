import React from "react";
import AdsList from "components/ads/AdsList";
import styles from "../Category/category.module.css";
import { useLoaderData } from "react-router-dom";
import { useContext } from "react";
import { FiltersDrawerContext } from "routes/main";

export default function SearchPage() {
	const { s } = useLoaderData();
	const filtersDrawer = useContext(FiltersDrawerContext);

	return (
		<div className={`container ${styles.container}`}>
			<div className={styles.left}>
				<h2 className={styles.title}>Поиск по запросу: &quot;{s}&quot;</h2>
				<AdsList parentStyles={styles} propSort={filtersDrawer.sort} title='Результаты поиска' />
			</div>
		</div>
	);
}