import React from "react";
import InputGroup from "components/forms/parts/InputGroup";
import TextareaGroup from "components/forms/parts/TextareaGroup";
import InputMask from "comigo-tech-react-input-mask";
import Button from "components/buttons/Button";

import cameraIcon from "assets/images/camera-gray.svg";
import inputStyles from "../../forms/form.module.css";

export default function EditProfile({ styles, state, uploadHandler, changeHandler, clickHandler, showMask, showPreloader }) {
	return (
		<div className={styles.form}>
			<div>
				<h3 className={styles.subtitle}>Фото профиля и обложка</h3>
				<div className={styles.photos}>
					<label className={styles.photo}>
						<img src={state.avatar.value} alt="avatar" className={styles.photoImage} />
						<img src={cameraIcon} alt='Camera icon' className={styles.photoIcon} />
						<input type='file' hidden name='avatar' onChange={uploadHandler} accept="image/png,image/jpeg" />
					</label>
					<label className={styles.photo}>
						<img src={state.background.value} alt="background" className={styles.photoImage} />
						<img src={cameraIcon} alt='Camera icon' className={styles.photoIcon} />
						<input type='file' hidden name='background' onChange={uploadHandler} accept="image/png,image/jpeg" />
					</label>
				</div>
				<div className={styles.errors}>
					{state.avatar.showError && <span className={styles.errorText}>{state.avatar.errorText}</span>}
					{state.background.showError && <span className={styles.errorText}>{state.background.errorText}</span>}
				</div>
			</div>
			<div>
				<h3 className={styles.subtitle}>Основная информация</h3>
				<div className={styles.inputs}>
					<InputGroup state={state} name="name" text="Имя" type="text" placeholder="Введите имя" onChange={changeHandler} />
					<label className={inputStyles.inputGroup}>Телефон
						<InputMask mask={showMask ? "+7 (999) 999-99-99" : ""} type='text' name='phone' onChange={changeHandler} value={state.phone.value} placeholder="Введите телефон" />
						{state.phone.showError && <span className={inputStyles.errorText}>{state.phone.errorText}</span>}
					</label>
					<TextareaGroup state={state} name="description" text="Описание" type="text" placeholder="Введите описание" onChange={changeHandler} />
				</div>
			</div>
			{state.showSuccessText && <div className={inputStyles.successText}>Изменения успешно применены</div>}
			<div className={styles.buttons}>
				<Button name="Сохранить изменения" color="green" className={styles.button} onClick={clickHandler} showPreloader={showPreloader} />
			</div>
		</div>
	);
}