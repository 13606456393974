import { getAdsList } from "api/getAdsList";
import { getCategoryPage } from "api/getCategoryPage";
import CategoryPage from "components/pages/Category/CategoryPage";
import { FiltersDrawerContext } from "./main";
import FilterDrawer from "components/drawer/FilterDrawer";
import { useContext, useEffect, useState } from "react";
import { DeviceContext } from "context/DeviceContext";
import React from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";
import { MainContext } from "context/MainContext";

export async function loader({ params }) {
	const response = await getCategoryPage(params.category, params.subcategory);
	if (response.success) {
		const category = response.category;
		const categories = response.categories;
		const subcategory = response.subcategory;
		const breadcrumbs = response.breadcrumbs;

		const city = JSON.parse(localStorage.getItem("city"));
		const postsResponse = await getAdsList({ before: 0, page: 1, sort: "new", category: subcategory ? subcategory.term_id : category.term_id, city: city, isCityPosts: true });

		const posts = postsResponse.posts;
		const before = postsResponse.before;

		let selectedCategory = null;

		categories.forEach(cat => {
			if (subcategory?.term_id === cat.term_id) {
				selectedCategory = cat;
				return;
			}
		});

		return { categories, category, posts, before, subcategory, breadcrumbs, selectedCategory };
	} else {
		throw new Error(response.message);
	}
}

export default function Category() {
	const device = useContext(DeviceContext);
	const [showDrawer, setShowDrawer] = useState(false);
	const { category, subcategory } = useLoaderData();

	useEffect(() => {
		if (showDrawer) {
			document.body.style.position = "fixed";
		} else {
			document.body.style.position = "static";
		}
	}, [showDrawer]);

	// Сортировка
	const sortList = [
		{
			name: "По умолчанию",
			value: "new"
		},
		{
			name: "Старее",
			value: "old"
		},
		{
			name: "Дешeвле",
			value: "cheap"
		},
		{
			name: "Дороже",
			value: "exp"
		}
	];
	const [sort, setSort] = useState(sortList[0]);
	const { city } = useContext(MainContext);
	const [cityState, setCityState] = useState(city);
	let description = subcategory ? subcategory.description : category.description;
	if (description.length > 600) {
		description = `${description.substr(0, 600)}...`;
	}

	useEffect(() => {
		setCityState(city);
	}, [city]);

	return (
		<>
			<Helmet>
				<title>{subcategory ? subcategory.name : category.name} в {cityState.data.city ? cityState.data.city_with_type : cityState.data.region_with_type} | Фунтик</title>
				<meta property="og:description" content={description}></meta>
				<meta name="description" content={description}></meta>
			</Helmet>
			<FiltersDrawerContext.Provider value={{ showDrawer: showDrawer, setShowDrawer: setShowDrawer, sort: sort, setSort: setSort, sortList: sortList }}>
				{device === "Mobile" && <FilterDrawer />}
				<CategoryPage />
			</FiltersDrawerContext.Provider>
		</>
	);
}
