import React from "react";
import { useContext, useState } from "react";

import styles from "./popup.module.css";
import overlayStyles from "../overlay/overlay.module.css";

import { DeviceContext } from "context/DeviceContext";

import crossIcon from "assets/images/cross-black.svg";
import { MainContext } from "context/MainContext";
import CityForm from "components/forms/City/CityForm";

export default function City({ setShowPopup }) {
	const device = useContext(DeviceContext);
	const { city, setCity } = useContext(MainContext);

	const [query, setQuery] = useState(city.value);

	function setAddress(value) {
		setCity(value);
		console.log(value);
		setShowPopup(false);
	}

	return device === "Desktop" ? (
		<div className={styles.popupContainer} onClick={() => setShowPopup(false)}>
			<div className={`${styles.popup} ${styles.addressPopup}`} onClick={(e) => e.stopPropagation()}>
				<div className={styles.popupTop}>
					<div className={styles.addressInputWrapper}>
						<input type="text" className={styles.addressInput} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="ул Центральная, д 12" />
					</div>
					<img src={crossIcon} alt="Cross icon" className={styles.addressCross} onClick={() => setShowPopup(false)} />
				</div>
				<CityForm query={query} styles={styles} callback={setAddress} />
			</div>
		</div>
	) : (
		<div className={overlayStyles.addressOverlay} style={{ zIndex: 10000 }}>
			<div className={overlayStyles.top}>
				<div className={styles.addressInputWrapper}>
					<input type="text" className={styles.addressInput} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="ул Центральная, д 12" />
				</div>
				<img src={crossIcon} alt="Cross icon" className={styles.addressCross} onClick={() => setShowPopup(false)} />
			</div>
			<CityForm query={query} styles={styles} callback={setAddress} />
		</div>
	);
}