import React from "react";
import { useContext, useState } from "react";

import styles from "./popup.module.css";

import UserLogin from "../forms/User/UserLogin";
import UserRegistration from "../forms/User/UserRegistartion";
import { DeviceContext } from "context/DeviceContext";
import PopupContainer from "./parts/PopupContainer";

export default function User({ setShowUserPopup }) {
	const [showLoginForm, setShowLoginForm] = useState(true);
	const [showRegistrationForm, setShowRegistrationForm] = useState(false);
	const [isAfterRegistration, setIsAfterRegistration] = useState(false);
	const device = useContext(DeviceContext);


	const hideForms = () => {
		setShowLoginForm(false);
		setShowRegistrationForm(false);
	};

	const afterRegistration = () => {
		hideForms();
		setIsAfterRegistration(true);
		setShowLoginForm(true);
	};

	const changeForm = () => {
		hideForms();
		setShowRegistrationForm(true);
	};

	const hidePopup = () => {
		setShowUserPopup(false);
	};

	return device === "Desktop" && (
		<div className={styles.popupContainer} onClick={() => setShowUserPopup(false)}>
			{showLoginForm &&
				<PopupContainer closeEvent={hidePopup} title="Авторизация">
					<UserLogin
						closeEvent={hidePopup}
						changeForm={changeForm}
						isAfterRegistration={isAfterRegistration}
					/>
				</PopupContainer>
			}
			{showRegistrationForm &&
				<PopupContainer closeEvent={hidePopup} title="Регистрация">
					<UserRegistration
						styles={styles}
						closeEvent={hidePopup}
						afterRegistration={afterRegistration}
					/>
				</PopupContainer>
			}
		</div>
	);
}