import React from "react";
import Button from "components/buttons/Button";
import { DataContext, StepContext } from "components/pages/CreateAds/CreateAdsPage";
import { useContext, useEffect } from "react";

export default function SelectCategory({ styles }) {
	const { data, categories, setData } = useContext(DataContext);
	const { setStep } = useContext(StepContext);

	useEffect(() => {
		if (data.data.categoriesState.categoriesList === null) {
			setData(s => {
				return {
					...s,
					categoriesState: {
						...s.categoriesState,
						categoriesList: categories
					}
				};
			});
		}
	}, [categories, data.data.categoriesState.categoriesList, setData]);

	useEffect(() => {
		function clickHandler(item) {
			setData(s => {
				return {
					...s,
					categoriesState: {
						...s.categoriesState,
						categoriesList: s.categoriesState.breadcrumbs[item][0],
						breadcrumbs: s.categoriesState.breadcrumbs.slice(0, item),
						selectedCategory: null,
					},
					category: {
						...s.categoriesState.category,
						value: null
					}
				};
			});
		}

		let list = [];

		for (let item in data.data.categoriesState.breadcrumbs) {
			list.push(<div key={item} className={styles.breadcrumbLink} onClick={() => clickHandler(item)}>{item === "0" ? "Все категории" : data.data.categoriesState.breadcrumbs[item - 1][1].name}</div>);
		}

		setData(s => {
			return {
				...s,
				categoriesState: {
					...s.categoriesState,
					breadcrumbsJsx: <div className={styles.breadcrumbs}>{list}</div>
				}
			};
		});
	}, [styles, data.data.categoriesState.breadcrumbs, setData]);

	useEffect(() => {
		function clickHandler(cat) {
			if (Object.keys(cat.children).length !== 0) {
				setData(s => {
					return {
						...s,
						categoriesState: {
							...s.categoriesState,
							breadcrumbs: (cat.parent === 0) ? [[s.categoriesState.categoriesList, cat]] : [...s.categoriesState.breadcrumbs, [s.categoriesState.categoriesList, cat]],
							categoriesList: cat.children
						}
					};
				});
			} else {
				setData(s => {
					return {
						...s,
						categoriesState: {
							...s.categoriesState,
							selectedCategory: cat
						},
						category: {
							...s.category,
							value: cat
						}
					};
				});
			}
		}

		let list = [];

		for (let cat in data.data.categoriesState.categoriesList) {
			let item = data.data.categoriesState.categoriesList[cat];

			list.push(<div key={cat} onClick={() => clickHandler(item)} className={styles.categoryItem + (data.data.categoriesState.selectedCategory === item ? ` ${styles.selected}` : "")}><img src={item.icon} alt="" />{item.name}</div>);
		}
		setData(s => {
			return {
				...s,
				categoriesState: {
					...s.categoriesState,
					categoriesListJsx: <div className={styles.categoriesList}>{list}</div>
				},
			};
		});

	}, [styles, data.data.categoriesState.categoriesList, data.data.categoriesState.selectedCategory, setData, setStep]);

	return (
		<>
			<h3 className={styles.subtitle}>Укажите категорию</h3>
			{(categories && data.data.categoriesState.breadcrumbs.length !== 0) ? <div>{data.data.categoriesState.breadcrumbsJsx}</div> : false}
			{!categories ? "Loading..." :
				data.data.categoriesState.categoriesListJsx
			}
			{data.data?.category?.value && <div className={styles.buttons}><Button onClick={() => setStep("2")} className={styles.nextButton} name={"Продолжить"} /></div>}
		</>
	);
}