import React from "react";
import { useContext, useEffect, useState } from "react";
import styles from "./subcategory.module.css";
import { DeviceContext } from "context/DeviceContext";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import AdsList from "components/ads/AdsList";
import SubcategorySidebar from "components/sidebar/Subcategory";
import { useLoaderData, useNavigate } from "react-router-dom";
import CategoryHeader from "components/header/parts/Mobile/Parts/CategoryHeader";
import FiltersSidebar from "components/sidebar/Filters";
import { FiltersDrawerContext } from "routes/main";

export default function SubcategoryPage({ propsListFilters }) {
	const device = useContext(DeviceContext);
	const navigate = useNavigate();
	const [listFilters, setListFilters] = useState(propsListFilters);

	const filtersDrawer = useContext(FiltersDrawerContext);

	useEffect(() => {
		if (propsListFilters) {
			setListFilters(propsListFilters);
			filtersDrawer.setShowDrawer(false);
		}
		// eslint-disable-next-line
}, [propsListFilters])

	const { category, subcategory, selected, categories, breadcrumbs } = useLoaderData();

	function mobileBackEvent() {
		navigate(-1);
	}

	return (
		<>
			{device === "Mobile" && <CategoryHeader backEvent={mobileBackEvent} />}
			<div className={`container ${styles.subcategoryContainer}`}>
				<div className={styles.top}>
					{device === "Desktop" &&
						<Breadcrumbs
							links={[
								{
									to: "/",
									name: "Главная"
								},
								...breadcrumbs
							]}
							current={selected.name}
						/>}
					{device === "Desktop" && <h2 className={styles.title}>{selected.name}</h2>}
					<div className={styles.container}>
						{device === "Desktop" && <div className={styles.left}>
							<SubcategorySidebar name={category.name} styles={styles} categories={categories} subcategory={subcategory} mainCategory={category} />
							<FiltersSidebar setListFilters={setListFilters} />
						</div>}
						<div className={styles.right}>
							<AdsList category={selected.term_id} parentStyles={styles} title={selected.name} filters={listFilters} propSort={filtersDrawer.sort} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}