import { getAdsList } from "api/getAdsList";
import { search } from "api/search";
import SearchPage from "components/pages/Search/SearchPage";
import { FiltersDrawerContext } from "./main";
import SearchHeader from "components/header/parts/Mobile/Parts/SearchHeader";
import FilterDrawer from "components/drawer/FilterDrawer";
import { useContext, useEffect, useState } from "react";
import { DeviceContext } from "context/DeviceContext";
import { redirect, useLoaderData } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";

export async function loader({ request }) {
	const url = new URL(request.url);
	const s = url.searchParams.get("s");
	const data = s !== null ? await search(s) : [];

	if (!s) {
		return redirect("/");
	}

	const response = await getAdsList({ before: 0, page: 1, sort: "new", category: 0, s: s });
	const posts = response.posts;
	const before = response.before;

	return { data, s, posts, before };
}

export default function Search() {
	const device = useContext(DeviceContext);
	const [showDrawer, setShowDrawer] = useState(false);
	const {s} = useLoaderData();

	useEffect(() => {
		if (showDrawer) {
			document.body.style.position = "fixed";
		} else {
			document.body.style.position = "static";
		}
	}, [showDrawer]);

	// Сортировка
	const sortList = [
		{
			name: "По умолчанию",
			value: "new"
		},
		{
			name: "Старее",
			value: "old"
		},
		{
			name: "Дешeвле",
			value: "cheap"
		},
		{
			name: "Дороже",
			value: "exp"
		}
	];
	const [sort, setSort] = useState(sortList[0]);

	return (
		<>
			<Helmet>
				<title>Поиск по запросу &quot;{s.length > 20 ? `${s.substr(0, 20)}...` : s}&quot; | Фунтик</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<FiltersDrawerContext.Provider value={{ showDrawer: showDrawer, setShowDrawer: setShowDrawer, sort: sort, setSort: setSort, sortList: sortList }}>
				{device === "Mobile" && <SearchHeader />}
				{device === "Mobile" && <FilterDrawer />}
				<SearchPage />
			</FiltersDrawerContext.Provider>
		</>
	);
}