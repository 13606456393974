import React from "react";
import lhIcon from "assets/images/layout-h-black.svg";
import lhActiveIcon from "assets/images/layout-h-blue.svg";
import lgIcon from "assets/images/layout-g-black.svg";
import lgActiveIcon from "assets/images/layout-g-blue.svg";

export default function LayoutSwitcher({ styles, state, setState }) {
	return (
		<div className={styles.layoutSwitch}>
			<div onClick={() => setState("grid")} className={`${styles.layoutSwitchButton} ${state === "grid" ? styles.active : ""}`}>
				<img src={state === "grid" ? lgActiveIcon : lgIcon} alt="icon" />
			</div>
			<div onClick={() => setState("horizontal")} className={`${styles.layoutSwitchButton} ${state === "horizontal" ? styles.active : ""}`}>
				<img src={state === "horizontal" ? lhActiveIcon : lhIcon} alt="icon" />
			</div>
		</div>
	);
}