import { setFormData } from "./methods";

export function registrationStep1(data) {
	const body = setFormData(data);

	return fetch("https://api.funtik.su/wp-json/funtik/v1/registration/check_email", {
		method: "POST",
		body: body
	})
		.then(response => response.json())
		.then(data => {
			return data;
		});
}

export function registrationStep2(data) {
	const body = setFormData(data);

	return fetch("https://api.funtik.su/wp-json/funtik/v1/registration/check_code", {
		method: "POST",
		body: body
	})
		.then(response => response.json())
		.then(data => {
			return data;
		});
}

export function registrationStep3(data) {
	const body = setFormData(data);

	return fetch("https://api.funtik.su/wp-json/funtik/v1/registration/confirm", {
		method: "POST",
		body: body
	})
		.then(response => response.json())
		.then(data => {
			return data;
		});
}