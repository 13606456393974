import React from "react";
import { getSameList } from "api/getSameList";
import AdsCard from "components/ads/parts/AdsCard";
import { useCallback, useEffect, useState } from "react";
import styles from "../ads.module.css";
import adsStyles from "../../../ads/ads.module.css";
import Button from "components/buttons/Button";

export default function AdsSameList({ id }) {
	const [state, setState] = useState({
		before: 0,
		page: 1,
		posts: [],
		isHaveMorePosts: false,
		loading: false,
		id: id
	});

	const fetching = useCallback(async (ignore, isNew) => {
		await getSameList({
			id: id,
			page: isNew ? 1 : state.page,
			before: isNew ? 0 : state.before,
			per_page: 12
		})
			.then(response => {
				if (ignore) return;
				if (response.success) {
					setState(s => {
						return {
							...s,
							posts: state.page === 1 || isNew ? response.posts : [...s.posts, ...response.posts],
							page: isNew ? 2 : (s.page + 1),
							before: response.before,
							isHaveMorePosts: !(response.posts.length < 12),
							loading: false,
							id: id
						};
					});
				}
			});
	}, [id, state.page, state.before]);

	useEffect(() => {
		let ignore = false;

		if (id === state.id) {
			fetching(ignore);
		} else {
			fetching(ignore, true);
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
	}, [id])

	return (
		<>
			{state.posts.length !== 0 ?
				<>
					<div className={`${adsStyles.grid} ${styles.same}`}>
						{state.posts.map(post => <AdsCard key={post.id} post={post} useLayout={false} />)}
					</div>
					{state.isHaveMorePosts ? <Button
						showPreloader={state.loading}
						className={styles.sameButton}
						onClick={() => {
							setState(s => {
								return { ...s, loading: true };
							});
							fetching();
						}}
						name="Показать еще" /> : <div>Вы посмотрели все объявления</div>}
				</>
				:
				<div>Вы посмотрели все объявления</div>
			}
		</>
	);
}