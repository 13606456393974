import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";
import styles from "./standart.module.css";
import { Link } from "react-router-dom";
import arrowIcon from "assets/images/arrow-left-blue.svg";
import { useContext } from "react";
import { DeviceContext } from "context/DeviceContext";

export default function StandartPage() {
	const { data } = useLoaderData();
	const device = useContext(DeviceContext);
	const navigate = useNavigate();

	return (
		<>
			{device === "Mobile" &&
				<header className={styles.header}>
					<div className={styles.back} onClick={() => navigate(-1)}></div>
					<h2 className={styles.headerTitle}>{data.title}</h2>
				</header>
			}
			<div className={`${styles.container} container`} >
				{device === "Desktop" && <div className={styles.left}>
					<div className={styles.stickyContainer}>
						<Link to="/" className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться на главную</Link>
					</div>
				</div>}
				<div className={styles.right}>
					{device === "Desktop" && <h2 className={styles.title}>{data.title}</h2>}
					<div className={styles.content}>
						{parse(DOMPurify.sanitize(data.content))}
					</div>
				</div>
			</div>
		</>
	);
}