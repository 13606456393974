import { setFormData } from "./methods";

export async function createAds(body, basic) {
	const data = setFormData(body);

	return fetch("https://api.funtik.su/wp-json/funtik/v1/post/create", {
		method: "POST",
		body: data,
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}