import React from "react";
import styles from "./popup.module.css";
import PopupContainer from "./parts/PopupContainer";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

export default function CategoryPopup({ description, title, closeEvent }) {

	return (
		<div className={`${styles.popupContainer}`} onClick={closeEvent}>
			<PopupContainer closeEvent={closeEvent} title={title}>
				<div className={styles.description}>
					{parse(DOMPurify.sanitize(description))}
				</div>
			</PopupContainer>
		</div>
	);
}