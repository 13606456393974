import { setFormData } from "./methods";

export async function getSameList(params) {
	const body = setFormData({
		page: params.page,
		before: params.before,
		per_page: params.per_page
	});
	const data = await fetch(`https://api.funtik.su/wp-json/funtik/v1/post/same/${params.id}`, {
		method: "POST",
		body: body,
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
	return data;
}