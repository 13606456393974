export const city = {
	"value": "г Челябинск",
	"unrestricted_value": "454000, Челябинская обл, г Челябинск",
	"data": {
		"postal_code": "454000",
		"country": "Россия",
		"country_iso_code": "RU",
		"federal_district": "Уральский",
		"region_fias_id": "27eb7c10-a234-44da-a59c-8b1f864966de",
		"region_kladr_id": "7400000000000",
		"region_iso_code": "RU-CHE",
		"region_with_type": "Челябинская обл",
		"region_type": "обл",
		"region_type_full": "область",
		"region": "Челябинская",
		"area_fias_id": null,
		"area_kladr_id": null,
		"area_with_type": null,
		"area_type": null,
		"area_type_full": null,
		"area": null,
		"city_fias_id": "a376e68d-724a-4472-be7c-891bdb09ae32",
		"city_kladr_id": "7400000100000",
		"city_with_type": "г Челябинск",
		"city_type": "г",
		"city_type_full": "город",
		"city": "Челябинск",
		"city_area": null,
		"city_district_fias_id": null,
		"city_district_kladr_id": null,
		"city_district_with_type": null,
		"city_district_type": null,
		"city_district_type_full": null,
		"city_district": null,
		"settlement_fias_id": null,
		"settlement_kladr_id": null,
		"settlement_with_type": null,
		"settlement_type": null,
		"settlement_type_full": null,
		"settlement": null,
		"street_fias_id": null,
		"street_kladr_id": null,
		"street_with_type": null,
		"street_type": null,
		"street_type_full": null,
		"street": null,
		"stead_fias_id": null,
		"stead_cadnum": null,
		"stead_type": null,
		"stead_type_full": null,
		"stead": null,
		"house_fias_id": null,
		"house_kladr_id": null,
		"house_cadnum": null,
		"house_type": null,
		"house_type_full": null,
		"house": null,
		"block_type": null,
		"block_type_full": null,
		"block": null,
		"entrance": null,
		"floor": null,
		"flat_fias_id": null,
		"flat_cadnum": null,
		"flat_type": null,
		"flat_type_full": null,
		"flat": null,
		"flat_area": null,
		"square_meter_price": null,
		"flat_price": null,
		"room_fias_id": null,
		"room_cadnum": null,
		"room_type": null,
		"room_type_full": null,
		"room": null,
		"postal_box": null,
		"fias_id": "a376e68d-724a-4472-be7c-891bdb09ae32",
		"fias_code": null,
		"fias_level": "4",
		"fias_actuality_state": "0",
		"kladr_id": "7400000100000",
		"geoname_id": "1508291",
		"capital_marker": "2",
		"okato": "75401000000",
		"oktmo": "75701000001",
		"tax_office": "7400",
		"tax_office_legal": "7400",
		"timezone": null,
		"geo_lat": "55.160283",
		"geo_lon": "61.400856",
		"beltway_hit": null,
		"beltway_distance": null,
		"metro": null,
		"divisions": null,
		"qc_geo": "4",
		"qc_complete": null,
		"qc_house": null,
		"history_values": null,
		"unparsed_parts": null,
		"source": null,
		"qc": null
	}
};