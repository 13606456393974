import React from "react";
import { forwardRef, useContext, useState } from "react";

import styles from "./popup.module.css";
import overlayStyles from "../overlay/overlay.module.css";

import { DeviceContext } from "context/DeviceContext";
import Overlay from "components/overlay/Overlay";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import crossIcon from "assets/images/cross-black.svg";

import ru from "date-fns/locale/ru";
import { endOfISOWeek, startOfISOWeek } from "date-fns";

import calendarIcon from "assets/images/calendar-blue.svg";
import arrowIcon from "assets/images/custom-input-arrow.svg";
import StatisticsContent from "./parts/StatisticsContent";
registerLocale("ru", ru);

export default function Statistics({ setShowPopup, id }) {
	const device = useContext(DeviceContext);
	const [state, setState] = useState([startOfISOWeek(Date.now()), endOfISOWeek(Date.now())]);

	// eslint-disable-next-line
	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<button className={styles.customInput} onClick={onClick} ref={ref}>
			<img src={calendarIcon} alt="" />
			{value}
			<img src={arrowIcon} alt="" />
		</button>
	));

	return device === "Desktop" ? (
		<div className={styles.popupContainer} onClick={() => setShowPopup(false)}>
			<div className={`${styles.popup} ${styles.addressPopup}`} style={{ width: 564 }} onClick={(e) => e.stopPropagation()}>
				<div className={styles.popupTop} style={{ border: "none" }}>
					<div style={{ display: "flex", gap: 24, alignItems: "center" }}>
						<h2 className={styles.popupTitle}>Статистика</h2>
						<ReactDatePicker
							startDate={state[0]}
							endDate={state[1]}
							selectsRange={true}
							onChange={(date) => setState([startOfISOWeek(date[0]), endOfISOWeek(date[0])])}
							dateFormat="dd.MM.yyyy"
							locale={"ru"}
							customInput={<CustomInput />}
						/>
					</div>
					<img src={crossIcon} alt="Cross icon" className={styles.addressCross} onClick={() => setShowPopup(false)} />
				</div>
				<StatisticsContent week={state} id={id} />
			</div>
		</div>
	) : (
		<Overlay backEvent={() => setShowPopup(false)} title="Статистика" container={overlayStyles.attributes}>
			<div className={styles.terms}>
				<div className={styles.popupTop} style={{ border: "none" }}>
					<div style={{ display: "flex", gap: 24, alignItems: "center" }}>
						<ReactDatePicker
							startDate={state[0]}
							endDate={state[1]}
							selectsRange={true}
							onChange={(date) => setState([startOfISOWeek(date[0]), endOfISOWeek(date[0])])}
							dateFormat="dd.MM.yyyy"
							locale={"ru"}
							customInput={<CustomInput />}
						/>
					</div>
				</div>
				<StatisticsContent week={state} id={id} />
			</div>
		</Overlay>
	);
}