import { setFormData } from "./methods";

export async function setFavorites(postId, basic = false) {
	if (!basic) {
		let favorites = JSON.parse(localStorage.getItem("favorites"));

		if (!Array.isArray(favorites)) {
			favorites = [];
		}

		let index = favorites.indexOf(postId);

		if (index > -1) {
			favorites.splice(index, 1);
		} else {
			favorites.push(postId);
		}

		localStorage.setItem("favorites", JSON.stringify(favorites));

		return favorites;

	} else {
		const data = setFormData({
			id: postId,
		});

		return fetch("https://api.funtik.su/wp-json/funtik/v1/favorites", {
			method: "POST",
			body: data,
			headers: {
				"Authorization": `Basic ${window.btoa(basic)}`
			}
		})
			.then(response => response.json())
			.then(data => { return Object.values(data); });
	}
}

export async function getFavorites(basic) {
	if (basic) {
		return fetch("https://api.funtik.su/wp-json/funtik/v1/favorites", {
			method: "GET",
			headers: {
				"Authorization": `Basic ${window.btoa(basic)}`
			}
		})
			.then(response => {
				if (response?.status === 401) {
					localStorage.removeItem("user");
					return JSON.parse(localStorage.getItem("favorites")) || [];
				} else {
					return response.json();
				}
			});

	} else {
		return JSON.parse(localStorage.getItem("favorites")) || [];
	}
}