import { getFavorites } from "api/favorite";
import { getManyAds } from "api/getAds";
import { getProfile } from "api/getProfile";
import FavoritesPage from "components/pages/Favorites/FavoritesPage";
import React from "react";
import { Helmet } from "react-helmet";

export async function loader() {
	const localUser = JSON.parse(localStorage.getItem("user"));
	const favorites = await getFavorites(localUser ? localUser?.basic : null);

	const response = await getManyAds(favorites);
	const adsList = response.list || [];

	if (localUser) {
		const profileResponse = await getProfile(localUser?.basic);

		if (profileResponse.success) {
			const user = profileResponse.user;
			return { favorites, adsList, user };
		} else {
			const user = null;
			return { favorites, adsList, user };
		}
	} else {
		const user = null;
		return { favorites, adsList, user };
	}
}

export default function Favorites() {
	return (
		<>
			<Helmet>
				<title>Избранные объявления | Фунтик</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<FavoritesPage />
		</>
	);
}