import React from "react";
import Button from "components/buttons/Button";

import buttonStyles from "components/buttons/button.module.css";
import { useContext, useState } from "react";

import {
	registrationStep1 as step1,
	registrationStep2 as step2,
	registrationStep3 as step3
} from "api/registration";

import { validateField } from "api/validation";
import InputGroup from "../parts/InputGroup";

import styles from "../form.module.css";
import { DeviceContext } from "context/DeviceContext";

export default function UserRegistration({ afterRegistration }) {
	const [loading, setLoading] = useState(false);
	const device = useContext(DeviceContext);
	const [step, setStep] = useState(1);

	const [state, setState] = useState({
		email: {
			value: "",
			name: "Email",
			required: true,
			type: "email",
			showError: false,
			errorText: "",
			steps: [1, 2, 3]
		},
		code: {
			value: "",
			name: "Код",
			required: true,
			type: "string",
			showError: false,
			errorText: "",
			steps: [2, 3]
		},
		nickname: {
			value: "",
			name: "Имя",
			required: true,
			type: "string",
			showError: false,
			errorText: "",
			steps: [3]
		},
		password: {
			value: "",
			name: "Пароль",
			required: true,
			type: "password",
			showError: false,
			errorText: "",
			steps: [3]
		},
		password2: {
			value: "",
			name: "Пароль 2",
			required: true,
			type: "password",
			showError: false,
			errorText: "",
			steps: []
		}
	});

	const setError = (state, field, error) => {
		setState({
			...state,
			[field]: {
				...state[field],
				errorText: error,
				showError: true
			}
		});
		setLoading(false);
	};

	const onChange = (e) => {
		setState({
			...state,
			[e.target.name]: {
				...state[e.target.name],
				value: e.target.value,
				showError: false
			}
		});
	};

	const validatePassword = (password1, password2) => {
		let isValidate = false;

		setState({
			...state,
			password: {
				...state.password,
				showError: false,
			}
		});

		if (password1 !== password2) {
			setError(state, "password", "Пароли не совпадают");
		} else if (!validateField(state.password.type, state.password.value)) {
			setError(state, "password", "Пароль должен содержать минимум 8 символов, иметь строчную и заглавную буквы а также цифру");
		} else {
			isValidate = true;
		}

		return isValidate;
	};

	const validate = () => {
		setLoading(true);
		const data = {};

		for (let item in state) {
			let value = state[item].value;

			if (!state[item].steps.includes(step)) continue;

			if (value.length === 0 && state[item].required === true) {
				setError(state, item, `Поле ${state[item].name} является обязательным`);
				return false;
			} else if (!validateField(state[item].type, value)) {
				setError(state, item, `Проверьте правильность введенного ${state[item].name}`);
				return false;
			} else {
				data[item] = state[item].value;
			}
		}
		return data;
	};

	// Отправка формы
	const onSubmit = async () => {
		let data;
		switch (step) {
		// Первый шаг
		case 1:
			data = validate();
			data && await step1(data)
				.then(data => {
					if (data.success === true) {
						setStep(2);
						setLoading(false);
					} else {
						setError(state, "email", data.message);
					}
				})
				.catch(error => {
					setError(state, "email", error);
				});
			break;
			// Второй шаг
		case 2:
			data = validate();
			data && await step2(data)
				.then(data => {
					if (data.success === true) {
						setStep(3);
						setLoading(false);
					} else {
						setError(state, "email", data.message);
					}
				})
				.catch(error => {
					setError(state, "email", error);
				});
			break;
		case 3:
			if (validatePassword(state.password.value, state.password2.value)) {
				data = validate();
				data && await step3(data)
					.then(data => {
						if (data.success === true) {
							afterRegistration();
						} else {
							setError(state, "email", data.message);
						}
					})
					.catch(error => {
						console.log(error);
						setLoading(false);
					});
			}
			break;
		default:
			break;
		}
	};

	return (
		<>
			{step === 1 &&
				<div id="registrationStep1" style={device === "Mobile" ? { height: window.innerHeight - 70 - 48 } : { height: "auto" }}>
					<div className={styles.inputs}>
						<InputGroup state={state} name="email" onChange={onChange} text="Email" type="email" placeholder="Введите Email" />
					</div>
					<div className={styles.helpText}>Введите адрес электронной почты, на него придет код с подтверждением. Позже вы будете пользоваться этим адресом для атворизации</div>
					{device === "Mobile" && <div className="mobileGap"></div>}
					<Button name="Продолжить" className={buttonStyles.submitButton} onClick={onSubmit} showPreloader={loading} />
				</div>
			}
			{step === 2 &&
				<div id="registrationStep2" style={device === "Mobile" ? { height: window.innerHeight - 70 - 48 } : { height: "auto" }}>
					<div className={styles.inputs}>
						<InputGroup state={state} name="code" onChange={onChange} text="Код" type="text" placeholder="Введите Код" />
					</div>
					<div className={styles.helpText}>Введите код подтверждения отправленный Вам на Email. Незабудьте проверить папку Спам</div>
					{device === "Mobile" && <div className="mobileGap"></div>}
					<Button name="Продолжить" className={buttonStyles.submitButton} onClick={onSubmit} showPreloader={loading} />
				</div>
			}
			{step === 3 &&
				<div id="registrationStep3" style={device === "Mobile" ? { height: window.innerHeight - 70 - 48 } : { height: "auto" }}>
					<div className={styles.inputs}>
						<InputGroup state={state} name="nickname" onChange={onChange} text="Введите имя" type="text" placeholder="Введите Имя" />
						<InputGroup state={state} name="password2" onChange={onChange} text="Введите пароль" type="password" placeholder="Введите Пароль" />
						<InputGroup state={state} name="password" onChange={onChange} text="Введите пароль еще раз" type="password" placeholder="Введите Пароль" />
					</div>
					<div className={styles.helpText}>Придумайте пароль. Вы будете использовать его для авторизации на сайте.</div>
					{device === "Mobile" && <div className="mobileGap"></div>}
					<Button name="Создать аккаунт" className={buttonStyles.submitButton} onClick={onSubmit} showPreloader={loading} color="green" />
				</div>
			}
		</>
	);
}