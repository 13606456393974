import { useLoaderData } from "react-router-dom";
import StoriesCard from "./parts/StoriesCard";
import styles from "./stories.module.css";
import StoriesItem from "./parts/StoriesItem";
import React, { useContext, useEffect, useRef, useState } from "react";
import { register as registerSwiper } from "swiper/element/bundle";
import { DeviceContext } from "context/DeviceContext";

export default function StoriesList() {
	const { stories } = useLoaderData();
	const [showStory, setShowStory] = useState(false);
	const sliderRef = useRef(null);
	const device = useContext(DeviceContext);

	registerSwiper();

	useEffect(() => {
		const params = {
			injectStyles: [
				`
              .swiper-pagination {
                top: -38px!important;
                text-align: right;
                z-index: 0;
              }
              `,
			],
		};

		if (sliderRef.current) {
			sliderRef.current.addEventListener("touchstart", e => e.stopPropagation());
			Object.assign(sliderRef.current, params);
			stories.length !== 0 && sliderRef.current.initialize();
		}
	}, [sliderRef, device]);

	useEffect(() => {
		if (showStory && window.innerWidth < 1260) {
			document.body.style.position = "fixed";
		} else {
			document.body.style.position = "static";
		}
	}, [showStory]);

	return (
		<>
			<swiper-container
				slides-per-view={device === "Mobile" ? "3" : "5.7"}
				slides-per-group={device === "Mobile" ? "3" : "1"}
				pagination={device === "Mobile" ? true : false}
				class={`${styles.gallery} stories-gallery`}
				ref={sliderRef}
				speed={400}
				space-between={device === "Mobile" ? "8" : "12"}
				init={false}
				style={{
					"--swiper-pagination-color": "var(--main-color-blue)",
					"--swiper-pagination-bullet-horizontal-gap": "2px",
					"--swiper-pagination-bullet-size": "7px",
					"--swiper-pagination-bullet-inactive-color": "var(--main-color-gray)",
					"--swiper-pagination-bullet-inactive-opacity": "1",
				}}
				touch-start-prevent-default={true}
			>
				{stories.map(story => <swiper-slide key={story.id} class={styles.slide} ><StoriesCard setShowStory={setShowStory} data={story} /></swiper-slide>)}
			</swiper-container>
			{stories.map(story => <React.Fragment key={story.id}> {showStory === story.id && <StoriesItem setShowStory={setShowStory} id={story.id} />}</React.Fragment>)}
		</>
	);
}