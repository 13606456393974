import React from "react";
import { forwardRef } from "react";
import styles from "./overlay.module.css";

export default function Overlay({ children, backEvent, title, container, stopPropogation = false }) {
	return (
		<div className={styles.wrapper} onClick={e => {
			if (stopPropogation) {
				e.stopPropagation();
			}
		}}>
			<div className={styles.top}>
				<div className={styles.back} onClick={backEvent}></div>
				<h2 className={styles.title}>{title}</h2>
			</div>
			<div className={styles.container + (container ? ` ${container}` : "")}>
				{children}
			</div>
		</div>
	);
}

export const ForwardOverlay = forwardRef(function ForwardOverlay(props, ref) {
	const { children, backEvent, title, container, className } = props;

	return (
		<div ref={ref} className={`${styles.wrapper} ${className ? className : ""}`}>
			<div className={styles.top}>
				<div className={styles.back} onClick={backEvent}></div>
				<h2 className={styles.title}>{title}</h2>
			</div>
			<div className={styles.container + (container ? ` ${container}` : "")}>
				{children}
			</div>
		</div>
	);
});