import React from "react";
import styles from "../header-mobile.module.css";
import { useRouteLoaderData } from "react-router-dom";
import shareIcon from "assets/images/share-header.svg";
import dotsIcon from "assets/images/dots-header.svg";
import editIcon from "assets/images/pen-black.svg";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";


export default function MyAdsPageHeader({ title }) {
	const { data } = useRouteLoaderData("profileMyAds");

	return (
		<header className={`${styles.header} ${styles.ads}`}>
			<div className={styles.headerContainer + ` ${styles.line}`}>
				<BackButton styles={styles} />
				<h2 className={`${styles.title}`}>{title}</h2>
				<div className={styles.adsButtons}>
					<div className={styles.adsButton} onClick={() => window.navigator.share({ title: "Поделиться объявлением", text: `Funtik: ${data.name}`, url: `${window.location.origin}/ads/${data.id}` })}>
						<img src={shareIcon} alt="" className={styles.adsButtonIcon} />
					</div>
					<Link to={`/profile/ads/${data.id}/edit`} className={styles.adsButton}>
						<img src={editIcon} alt="" className={styles.adsButtonIcon} />
					</Link>
					<div className={styles.adsButton}>
						<img src={dotsIcon} alt="" className={styles.adsButtonIcon} />
					</div>
				</div>
			</div>
		</header>
	);
}