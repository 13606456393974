import React from "react";
import { useContext } from "react";
import DesktopHeader from "./parts/Desktop/DesktopHeader";
import MobileHeader from "./parts/Mobile/MobileHeader";
import { DeviceContext } from "context/DeviceContext";


export default function Header({ setShowUserPopup }) {

	const device = useContext(DeviceContext);

	return device === "Desktop" ?
		(
			<DesktopHeader setShowUserPopup={setShowUserPopup} />
		) :
	// Мобильная шапка
		(
			<MobileHeader />
		);
}