import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import { ReviewsContext } from "../ProfileReviews";
import ProfileReviewCard from "./ProfileReviewCard";
import popupStyles from "../../../popups/popup.module.css";

export async function loader({params}) {
	const id = params.id;
	return {id};
}

export default function UserReviewPopup() {
	const {id} = useLoaderData();
	const {reviews, setReviews, path, isMy} = useContext(ReviewsContext);
	const [review, setReview] = useState(reviews.find(item => parseInt(item.review.id) === parseInt(id)));
	const navigate = useNavigate();

	useEffect(() => {
		console.log("test");
		setReview(reviews.find(item => parseInt(item.review.id) === parseInt(id)));
	}, [reviews]);

	return review ? (
		<div className={`${popupStyles.popupContainer}`} style={{display: "flex", alignItems: "center"}} onClick={() => navigate(path, {preventScrollReset: true, replace: true})}>
			<div onClick={e => e.stopPropagation()} style={{width: "100%", maxWidth: 480, margin: "0 16px"}}>
				<ProfileReviewCard item={review} fullOpen={true} isMy={isMy} setListState={setReviews} isUser={true}/>
			</div>
		</div>) : 
		<Navigate to={path} replace={true} />;
}