import React from "react";
import { getProfileAds } from "api/getProfileAds";
import { redirect, useLoaderData } from "react-router-dom";

import styles from "../User/user.module.css";
import adsStyles from "../../ads/ads.module.css";
import AdsCard from "components/ads/parts/AdsCard";
import { useContext, useState } from "react";
import { DeviceContext } from "context/DeviceContext";
import { Link } from "react-router-dom";
import plusIcon from "assets/images/Plus.svg";
import { Helmet } from "react-helmet";

export async function loader() {
	const parseUser = JSON.parse(localStorage.getItem("user"));

	const response = await getProfileAds(parseUser.basic);

	if (response.success) {
		const activeProfilePosts = response.active_ads;
		const draftProfilePosts = response.draft_posts;

		return { activeProfilePosts, draftProfilePosts };
	} else if (response?.data?.status === 401) {
		localStorage.removeItem("user");
		return redirect("/?show_login=true&from=/profile/ads");
	} else {
		throw new Error(response.message);
	}
}

export default function ProfileAds() {
	const { activeProfilePosts, draftProfilePosts } = useLoaderData();
	const [isShowActive, setIsShowActive] = useState(true);
	const device = useContext(DeviceContext);

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Мои объявления</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			{
				device === "Desktop" ? (
					<div className={`${styles.right} ${styles.profileAds}`}>
						<h2 className={styles.name}>Объявления пользователя</h2>
						<div className={styles.profileAdsContainer}>
							<div className={styles.profileAdsBalloons}>
								<div className={`${styles.balloon} ${isShowActive ? styles.active : ""}`} onClick={() => setIsShowActive(true)}>Активные</div>
								<div className={`${styles.balloon} ${isShowActive ? "" : styles.active}`} onClick={() => setIsShowActive(false)}>Архив</div>
							</div>
							<div className={`${styles.profileAdsList} ${adsStyles.grid}`}>
								{isShowActive ?
									activeProfilePosts.map(item => <AdsCard isMy={true} post={item} key={item.id} useLayout={false} />) :
									draftProfilePosts.map(item => <AdsCard isMy={true} post={item} key={item.id} useLayout={false} />)
								}
							</div>
							{isShowActive && activeProfilePosts.length === 0 && <div>У пользователя нет объявлений</div>}
							{!isShowActive && draftProfilePosts.length === 0 && <div>У пользователя нет объявлений в архиве</div>}
							<div></div>
						</div>
					</div>
				) : (
					<>
						<div className={styles.profileAdsHeader}>
							<h2 className={styles.title}>Мои объявления</h2>
						</div>
						<div className={styles.profileAdsContent}>
							<div className={styles.profileAdsContainer}>
								<div className={styles.profileAdsBalloons}>
									<div className={`${styles.balloon} ${isShowActive ? styles.active : ""}`} onClick={() => setIsShowActive(true)}>Активные</div>
									<div className={`${styles.balloon} ${isShowActive ? "" : styles.active}`} onClick={() => setIsShowActive(false)}>Архив</div>
								</div>
								<div className={`${styles.profileAdsList} ${adsStyles.horizontal}`}>
									{isShowActive ?
										activeProfilePosts.map(item => <AdsCard isMy={true} post={item} key={item.id} useLayout={true} />) :
										draftProfilePosts.map(item => <AdsCard isMy={true} post={item} key={item.id} useLayout={true} />)
									}
								</div>
								{isShowActive && activeProfilePosts.length === 0 && <div>У пользователя нет объявлений</div>}
								{!isShowActive && draftProfilePosts.length === 0 && <div>У пользователя нет объявлений в архиве</div>}
								<div>
        
								</div>
							</div>
						</div>
						<Link to="/ads/create" className={styles.createAdsButton}><img src={plusIcon} alt="" />Разместить объявление</Link>
					</>
				)
			}
		</>
	);
}