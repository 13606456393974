import React from "react";
import Button from "components/buttons/Button";
import { DataContext, StepContext } from "components/pages/CreateAds/CreateAdsPage";
import { useContext, useState } from "react";
import InputGroup from "../parts/InputGroup";

import inputStyles from "../form.module.css";
import Address from "components/popups/Address";
import TextareaGroup from "../parts/TextareaGroup";

export default function SelectDescription({ styles }) {
	const { setStep, stepList } = useContext(StepContext);
	const { data } = useContext(DataContext);
	const [showAddressPopup, setShowAddressPopup] = useState(false);

	function validate() {
		data.setData(d => {
			return {
				...d,
				title: {
					...d["title"],
					errorText: !data.data.title.value ? "Поле Название должно быть заполнено" : "Количество символов в названии не должно превышать 200",
					showError: !data.data.title.value || data.data.title.value > 2000
				},
				description: {
					...d["description"],
					errorText: !data.data.description.value ? "Поле Описание должно быть заполнено" : "Количество символов в описании не должно превышать 2000",
					showError: !data.data.description.value || data.data.description.value.length > 2000
				},
				address: {
					...d["address"],
					showError: !data.data.address.value
				}
			};
		});
	}

	function clickHandler() {
		validate();

		if (!stepList["4"].isBlock) {
			setStep("4");
		}
	}

	function selectAddressHandler() {
		data.setData(d => { return { ...d, address: { ...d["address"], showError: false } }; });
		setShowAddressPopup(true);
	}

	return (
		<>
			<div className={styles.descriptionContainer}>
				<InputGroup name='title' text="Название" type="text" state={data.data} placeholder="Укажите название объявления" onChange={data.onChange} />
				<label className={inputStyles.inputGroup} onClick={selectAddressHandler}>Адрес
					<div className={inputStyles.inputSelect}>
						{data.data?.address?.value?.value ? <span>{data.data.address.value.value}</span> : "Нажмите чтобы указать"}
					</div>
					{data.data.address.showError && <span className={inputStyles.errorText}>{data.data.address.errorText}</span>}
				</label>
				<TextareaGroup name='description' text="Описание" state={data.data} placeholder="Введите описание объявления" onChange={data.onChange} />
			</div>
			<div className={styles.buttons}><Button name="Назад" className={styles.backButton} onClick={() => setStep("2")} /><Button name="Продолжить" className={styles.nextButton} onClick={clickHandler} /></div>
			{showAddressPopup && <Address setShowPopup={setShowAddressPopup} />}
		</>
	);
}