import React from "react";
import { Link } from "react-router-dom";

import style from "../catalog.module.css";

import SubcategoryList from "./SubcategoryList";

export default function Subcategory({ category, categoryObj }) {
	let subcategoriesJsx = [];

	for (let cat in categoryObj.children) {
		subcategoriesJsx.push(<SubcategoryList key={cat} category={cat} parent={category} categoryObj={categoryObj.children[cat]} />);
	}

	return (
		<div className={style.subcategory}>
			<Link to={`/category/${category}`} className={style.categoryLink}>{categoryObj.name}</Link>
			<div className={style.subcategories}>
				{subcategoriesJsx}
			</div>
		</div>
	);
}