import React from "react";
import { Outlet, useLoaderData, useRouteLoaderData } from "react-router-dom";
import styles from "../User/user.module.css";
import adsStyles from "../../ads/ads.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

import AdsCard from "components/ads/parts/AdsCard";
import ProfileMobileMenu from "./parts/ProfileMobileMenu";
import { DeviceContext } from "context/DeviceContext";
import ReviewsInfo from "./parts/ReviewsInfo";
import ProfileReviewCard from "./parts/ProfileReviewCard";
import { ReviewsContext } from "./ProfileReviews";

export async function loader() {
	const user = JSON.parse(localStorage.getItem("user"));

	const reviewsInfo = await fetch(`https://api.funtik.su/wp-json/funtik/v1/user/${user.id}/reviews/info`, {
		method: "GET",
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) {
				return data.data;
			}
			throw new Error("Произошла ошибка");
		})
		.catch((e) => {
			throw new Error(e);
		});

	return {reviewsInfo};
}

export function getMonthName(month) {
	switch (month) {
	case 0:
		return "Января";
	case 1:
		return "Февраля";
	case 2:
		return "Марта";
	case 3:
		return "Апреля";
	case 4:
		return "Мая";
	case 5:
		return "Июня";
	case 6:
		return "Июля";
	case 7:
		return "Августа";
	case 8:
		return "Сентября";
	case 9:
		return "Октября";
	case 10:
		return "Ноября";
	case 11:
		return "Декабря";
	default:
		return;
	}
}

export default function ProfilePage() {
	const { user, userReviews } = useRouteLoaderData("profile");
	const [showDescription, setShowDescription] = useState(false);
	const device = useContext(DeviceContext);

	const {reviewsInfo} = useLoaderData();

	const sliderRef = useRef();
	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.addEventListener("touchstart", e => e.stopPropagation());
			user.ads.length !== 0 && sliderRef.current.initialize();
		}
	}, [sliderRef]);

	const slider2Ref = useRef();
	useEffect(() => {
		if (slider2Ref.current) {
			slider2Ref.current.addEventListener("touchstart", e => e.stopPropagation());
			slider2Ref.current.initialize();
		}
	}, [slider2Ref]);


	return <ReviewsContext.Provider value={{path: "/profile/reviews/my"}}>
		{
			device === "Desktop" ? (
				<div className={styles.right}>
					<div className={styles.information}>
						<h2 className={styles.name}>{user.name}</h2>
						<div className={styles.registered}>На Funtik с {getMonthName(new Date(Date.parse(user.registered)).getMonth())} {new Date(Date.parse(user.registered)).getFullYear()}</div>
						{user.description ? <div className={styles.descriptionWrapper}>
							<div className={styles.description} style={{ display: showDescription ? "block" : "-webkit-box" }}>{parse(DOMPurify.sanitize(user.description))}</div>
							<div className={styles.descriptionButton} onClick={() => setShowDescription(!showDescription)}>{showDescription ? "Скрыть" : "Развернуть"}</div>
						</div> : <Link to="/profile/edit" className={`${styles.link} ${styles.addDescription}`}>Добавить описание</Link>}
					</div>
					<div className={`${styles.adsList} ${adsStyles.grid}`}>
						<h3 className={styles.title}>Объявления пользователя</h3>
						{user.ads && !!user.ads.length !== 0 ?
							<swiper-container
								slides-per-view="3.3"
								class={styles.gallery}
								ref={sliderRef}
								space-between={16}
								touch-events-target='wrapper'
								touch-start-prevent-default={true}
								init={false}
							>
								{user.ads.map(item => { return <swiper-slide class={styles.slide} key={item.id}><AdsCard useLayout={false} post={item} /></swiper-slide>; })}
							</swiper-container>
							:
							<div className={styles.infoText}>У вас пока нету объявлений, <Link to="/ads/create" className={styles.link} />разместить объявление?</div>
						}
						{user.ads && user.ads.length !== 0 && <Link to="/profile/ads" className={`${styles.button} ${styles.white}`} >Все объявления</Link>}
					</div>
					<div className={styles.reviews}>
						<ReviewsInfo reviewsInfo={reviewsInfo} />
						{userReviews.length !== 0 ? 
							<>
								<swiper-container
									slides-per-view="1.2"
									class={styles.gallery}
									ref={slider2Ref}
									space-between={16}
									touch-events-target='wrapper'
									touch-start-prevent-default={true}
									init={false}
								>
									{userReviews.map(item => { return <swiper-slide class={styles.slide} key={item.review.id}><ProfileReviewCard isUser={true} item={item} /></swiper-slide>; })}
								</swiper-container>
								{<Link to={"/profile/reviews/my"} className={`${styles.button} ${styles.white}`} >Все отзывы</Link>}
							</>
							: <div className={styles.infoText}>Вам пока не оставляли отзывы</div>
						}
					</div>
					<Outlet />
				</div>
			) : (
				<ProfileMobileMenu />
			)
		}
	</ReviewsContext.Provider>;
	
}