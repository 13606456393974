import React from "react";
import PopupContainer from "./PopupContainer";
import styles from "../popup.module.css";
import Button from "components/buttons/Button";

export default function LogoutPopup({ closeEvent, leaveEvent }) {
	return (
		<div className={`${styles.popupContainer}`} onClick={closeEvent}>
			<PopupContainer closeEvent={closeEvent} title="Вы действительно хотите выйти?" smallTitle={true}>
				<div className={styles.logoutButtons}>
					<Button name="Да" className={styles.logoutButton} onClick={leaveEvent} />
					<Button name="Нет" color="white" className={styles.logoutButton} onClick={closeEvent} />
				</div>
			</PopupContainer>
		</div>);
}