import React, { useEffect, useRef } from "react";
import { DeviceContext } from "context/DeviceContext";
import { useContext, useState } from "react";
import { Navigate, useRouteLoaderData } from "react-router-dom";
import styles from "../User/user.module.css";
import adsStyles from "../../ads/ads.module.css";
import { getMonthName } from "./ProfilePage";

import userIcon from "assets/images/User-placeholder.svg";
import eyeIcon from "assets/images/eye-placeholder.svg";
import starIcon from "assets/images/star-fill.svg";
import commentIcon from "assets/images/comment-blue.svg";
import shareIcon from "assets/images/share-header.svg";
import editIcon from "assets/images/pen-black.svg";
import placeholder from "assets/images/User-gray.svg";
import backgroundPlaceholder from "assets/images/background-placeholder.png";

import { Link } from "react-router-dom";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";
import AdsCard from "components/ads/parts/AdsCard";
import ProfileReviewCard from "./parts/ProfileReviewCard";
import { ReviewsContext } from "./ProfileReviews";

export default function ProfileMe() {
	const device = useContext(DeviceContext);
	const [showDescription, setShowDescription] = useState(false);
	const { user, userReviews } = useRouteLoaderData("profile");

	const slider2Ref = useRef();
	useEffect(() => {
		if (slider2Ref.current) {
			slider2Ref.current.addEventListener("touchstart", e => e.stopPropagation());
			slider2Ref.current.initialize();
		}
	}, [slider2Ref]);

	return device === "Mobile" ? (
		<ReviewsContext.Provider value={{path: "/profile/reviews/my"}}>
			<div className={styles.profileMobile}>
				<div className={styles.profileTop}>
					<div className={styles.profileHeader}>
						<div className={styles.shareButton} onClick={() => window.navigator.share({ url: `${window.location.origin}/user/${user.id}`, title: "Поделиться профилем", text: `Funtik: ${user.name}` })}>
							<img src={shareIcon} alt="" />
						</div>
						<div className={styles.profileHeaderButtons}>
							<Link to="/profile/edit" className={styles.profileHeaderButton}>
								<img src={editIcon} alt="" />
							</Link>
						</div>
					</div>

					{user.background ? <img src={user.background} className={styles.profileBackground} alt="" /> : <img src={backgroundPlaceholder} className={styles.profileBackground} alt="" />}
					{user.avatar ? <img src={user.avatar} className={styles.profileAvatar} alt="" /> : <img src={placeholder} className={`${styles.profileAvatar} ${styles.placeholder}`} alt="" />}
				</div>
				<div className={styles.profileContent}>
					<div className={styles.profileContainer}>
						<div className={styles.profileText}>
							<h2 className={styles.profileName}>{user.name}</h2>
							<div className={styles.registered}>На Funtik с {getMonthName(new Date(Date.parse(user.registered)).getMonth())} {new Date(Date.parse(user.registered)).getFullYear()}</div>
							<div className={styles.info}>
								<div className={styles.infoItem}>
									<img src={userIcon} alt="" />
									{user.subscribers} подписчиков
								</div>
								<div className={styles.infoItem}>
									<img src={eyeIcon} alt="" />
									{user.subscriptions} подписок
								</div>
								<div className={styles.infoItem}>
									<img src={starIcon} alt="" />
									{user.rating} рейтинг
								</div>
								<Link to={`/user/${user.id}/reviews`} className={styles.infoItem} style={{color: "var(--main-color-blue)"}}>
									<img src={commentIcon} alt="" />
									{user.reviews} отзывов
								</Link>
							</div>
							{user.description ? <div className={styles.descriptionWrapper}>
								<div className={styles.description} style={{ display: showDescription ? "block" : "-webkit-box" }}>{parse(DOMPurify.sanitize(user.description))}</div>
								<div className={styles.descriptionButton} onClick={() => setShowDescription(!showDescription)}>{showDescription ? "Скрыть" : "Развернуть"}</div>
							</div> : <Link to="/profile/edit" className={`${styles.link} ${styles.addDescription}`}>Добавить описание</Link>}
						</div>
						<div className={styles.adsSection}>
							<h3 className={styles.title}>Объявления</h3>
							{user.ads.length !== 0 && <div className={`${styles.adsList} ${adsStyles.grid}`}>
								{user.ads.map((card, key) => { return key < 4 ? <AdsCard key={key} post={card} useLayout={false} /> : null; })}
							</div>}
							{user.ads.length !== 0 ? <Link to="/profile/ads" className={styles.button}>Показать все объявления</Link> :
								<div className={styles.infoText}>У вас пока нету объявлений, <Link to="/ads/create" className={styles.link} />разместить объявление?</div>}
						</div>
						<div className={styles.reviewSection}>
							<h3 className={styles.title}>Отзывы</h3>
							{userReviews.length !== 0 ? 
								[
									<swiper-container
										slides-per-view="1.2"
										class={styles.gallery}
										ref={slider2Ref}
										space-between={16}
										touch-events-target='wrapper'
										touch-start-prevent-default={true}
										init={false}
										key="reviewSwiper"
									>
										{userReviews.map(item => { return <swiper-slide style={{height: "100%"}} class={styles.slide} key={item.review.id}><ProfileReviewCard isUser={true} item={item} /></swiper-slide>; })}
									</swiper-container>, 
									<Link key="reviewButton" to={"/profile/reviews/my"} className={`${styles.button}`} >Показать все отзывы</Link>
								]
								: <div className={styles.infoText}>Вам пока не оставляли отзывы</div>
							}
						</div>
					</div>
				</div>
			</div>
		</ReviewsContext.Provider>
	) : (
		<Navigate to="/profile" />
	);
}