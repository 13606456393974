import { getAdsList } from "api/getAdsList";
import { getFilterCount } from "api/getFilterCount";
import { getSubcategoryPage } from "api/getSubcategoryPage";
import SubcategoryPage from "components/pages/Subcategory/SubcategoryPage";
import { DeviceContext } from "context/DeviceContext";
import debounce from "lodash.debounce";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { FiltersDrawerContext } from "./main";
import FilterDrawer from "components/drawer/FilterDrawer";
import React from "react";
import { Helmet } from "react-helmet";
import { MainContext } from "context/MainContext";

export const FilterContext = createContext(null);

export async function loader({ params }) {
	const response = await getSubcategoryPage(params.category, params.subcategory, params.selected);
	if (response.success) {
		const category = response.category;
		const subcategory = response.subcategory;
		const attributes = response.attributes;
		const selected = response.selected;
		const categories = response.categories;
		const breadcrumbs = response.breadcrumbs;

		const city = JSON.parse(localStorage.getItem("city"));
		const postsResponse = await getAdsList({ before: 0, page: 1, sort: "new", category: selected.term_id, city: city, isCityPosts: true });
		const posts = postsResponse.posts;
		const before = postsResponse.before;

		return { subcategory, category, attributes, selected, categories, posts, before, breadcrumbs };
	} else {
		throw new Error(response.message);
	}
}

export default function Subcategory() {
	const { selected } = useLoaderData();

	const [filters, setFilters] = useState({
		priceFrom: { value: "" },
		priceTo: { value: "" }
	});

	const [foundPosts, setFoundPosts] = useState(null);
	const [loading, setLoading] = useState(true);

	// Для мобилки
	const [listFilters, setListFilters] = useState(false);

	// eslint-disable-next-line
	const fetching = useCallback(debounce((data, ignore) => {
		setLoading(true);
		getFilterCount(data).then(response => {
			if (!ignore) {
				if (response.success) {
					setFoundPosts(response.found_posts);
				} else {
					throw new Error(response.message);
				}
			}
			setLoading(false);
		});
	}, 500), []);

	function resetFilters() {
		for (let item in filters) {
			if (item === "priceFrom" || item === "priceTo") {
				setFilters(s => { return { ...s, [item]: { value: "" } }; });
			} else {
				for (let term in filters[item]) {
					setFilters(s => {
						return {
							...s, [item]: {
								...s[item],
								[term]: {
									value: false
								}
							}
						};
					});
				}
			}
		}
	}

	const setFiltersData = useCallback(() => {
		let data = {};

		for (let item in filters) {
			let obj = filters[item];
			if (obj.value) {
				data[item] = obj.value;
			} else {
				for (let i in obj) {
					if (obj[i].value) {
						data[item] = {
							...data[item],
							[i]: obj[i].value
						};
					}
				}
			}
		}

		data.category = selected.term_id;

		return data;
	}, [filters, selected.term_id]);

	useEffect(() => {
		setFilters(s => {
			return {
				...s,
				priceFrom: { value: "" },
				priceTo: { value: "" }
			};
		});
	}, [selected]);

	useEffect(() => {
		let ignore = false;
		if (!ignore) {

			fetching(setFiltersData(), ignore);

		}

		return () => {
			ignore = true;
		};

	}, [filters, fetching, selected.term_id, setFiltersData]);

	const device = useContext(DeviceContext);
	const [showDrawer, setShowDrawer] = useState(false);

	useEffect(() => {
		if (showDrawer) {
			document.body.style.position = "fixed";
		} else {
			document.body.style.position = "static";
		}
	}, [showDrawer]);

	// Сортировка
	const sortList = [
		{
			name: "По умолчанию",
			value: "new"
		},
		{
			name: "Старее",
			value: "old"
		},
		{
			name: "Дешeвле",
			value: "cheap"
		},
		{
			name: "Дороже",
			value: "exp"
		}
	];
	const [sort, setSort] = useState(sortList[0]);

	const { city } = useContext(MainContext);
	const [cityState, setCityState] = useState(city);

	useEffect(() => {
		setCityState(city);
	}, [city]);

	let description = selected.description;
	if (description.length > 600) {
		description = `${description.substr(0, 600)}...`;
	}

	return (
		<>
			<Helmet>
				<title>{selected.name} в {cityState.data.city ? cityState.data.city_with_type : cityState.data.region_with_type} | Фунтик</title>
				<meta property="og:description" content={description}></meta>
				<meta name="description" content={description}></meta>
			</Helmet>
			<FilterContext.Provider value={{ get: filters, set: setFilters, foundPosts: foundPosts, loading: loading, setFiltersData: setFiltersData, resetFilters: resetFilters }}>
				<FiltersDrawerContext.Provider value={{ showDrawer: showDrawer, setShowDrawer: setShowDrawer, sort: sort, setSort: setSort, sortList: sortList, listFilers: listFilters, setListFilters: setListFilters }}>
					{device === "Mobile" && <FilterDrawer />}
					<SubcategoryPage propsListFilters={listFilters} />
				</FiltersDrawerContext.Provider>
			</FilterContext.Provider>
		</>
	);
}