import React from "react";
import ReactDOM from "react-dom/client";

import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";

import "./index.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import reportWebVitals from "./reportWebVitals";

import Root from "./Root";
import ErrorPage from "./routes/error-page";
import Search, { loader as searchLoader } from "routes/search";
import CreateAdsPage, { loader as capLoader } from "components/pages/CreateAds/CreateAdsPage";
import Ads, { loader as adsLoader } from "routes/ads";
import EditProfilePage, { loader as editProfileLoader } from "components/pages/EditProfile/EditProfilePage";
import Category, { loader as categoryLoader } from "routes/category";
import Subcategory, { loader as subcategoryLoader } from "routes/subcategory";
import Main, { loader as mainLoader } from "routes/main";
import Standart, { loader as standartLoader } from "routes/standart";
import { loader as privacyLoader } from "routes/privacy";
import rootLoader from "api/rootLoader";
import User, { loader as userLoader } from "routes/user";
import Profile, { loader as profileLoader } from "routes/profile";
import ProfileAds, { loader as profileAdsLoader } from "components/pages/Profile/ProfileAds";
import ProfilePage, {loader as profilePageLoader} from "components/pages/Profile/ProfilePage";
import ProfileLogin from "components/pages/Profile/ProfileLogin";
import ProfileMe from "components/pages/Profile/ProfileMe";
import ProfileMyAds, { loader as profileMyAdsLoader } from "components/pages/Profile/ProfileMyAds";
import ProfileAdsEdit, { loader as profileAdsEditLoader } from "components/pages/Profile/ProfileAdsEdit";
import Favorites, { loader as favoritesLoader } from "routes/favorites";
import UserAds, { loader as userAdsLoader } from "components/pages/User/UserAds";
import ChatList, { loader as chatListLoader } from "routes/chatList";
import Chat, { loader as chatLoader } from "routes/chat";
import NewChat, { loader as newChatLoader } from "routes/newChat";
import ProfileReviews, { ProfileMyReviews, ProfileNewReviews, ProfileOutReviews, loader as profileReviewsLoader, newLoader as profileReviewsNewLoader, outLoader as profileReviewsOutLoader, myLoader as profileReviewsMyLoader } from "components/pages/Profile/ProfileReviews";
import ProfileReviewPopup, {loader as profileReviewPopupLoader} from "components/pages/Profile/parts/ProfileReviewPopup";
import UserReviews, {loader as userReviewsLoader} from "components/pages/User/UserReviews";
import UserReviewPopup, {loader as userReviewPopupLoader} from "components/pages/Profile/parts/UserReviewPopup";
import ProfileNotifications, {loader as profileNotificationsLoader} from "components/pages/Profile/ProfileNotifications";
import ProfileSubscriptions, {loader as profileSubscriptionsLoader} from "components/pages/Profile/ProfileSubscriptions";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />,
		loader: rootLoader,
		id: "root",
		children: [
			{
				errorElement: <ErrorPage />,
				children: [
					{ index: true, element: <Main />, loader: mainLoader },
					{ path: "*", element: <ErrorPage /> }, // обработка 404
					{
						path: "/search",
						element: <Search />,
						loader: searchLoader
					},
					{
						path: "/login",
						element: <ProfileLogin />,
					},
					{
						path: "/profile",
						loader: profileLoader,
						id: "profile",
						element: <Profile />,
						children: [
							{
								path: "/profile",
								loader: profilePageLoader,
								element: <ProfilePage />
							},
							{
								path: "/profile/ads",
								loader: profileAdsLoader,
								element: <ProfileAds />
							},
							{
								path: "/profile/ads/:id",
								id: "profileMyAds",
								loader: profileMyAdsLoader,
								element: <ProfileMyAds />
							},
							{
								path: "/profile/me",
								loader: profilePageLoader,
								element: <ProfileMe />
							},
							{
								path: "/profile/chat",
								loader: chatListLoader,
								id: "chat",
								element: <ChatList />
							},
							{
								path: "/profile/chat/:id",
								loader: chatLoader,
								id: "chatPage",
								element: <Chat />
							},
							{
								path: "/profile/chat/new/:id",
								loader: newChatLoader,
								element: <NewChat />
							},
							{
								path: "/profile/reviews",
								element: <ProfileReviews />,
								loader: profileReviewsLoader,
								children: [
									{
										path: "/profile/reviews/my",
										loader: profileReviewsMyLoader,
										element: <ProfileMyReviews />,
										children: [
											{
												path: "/profile/reviews/my/:id",
												loader: profileReviewPopupLoader,
												element: <ProfileReviewPopup />
											}
										]
									},
									{
										path: "/profile/reviews/outcoming",
										loader: profileReviewsOutLoader,
										element: <ProfileOutReviews />,
										children: [
											{
												path: "/profile/reviews/outcoming/:id",
												loader: profileReviewPopupLoader,
												element: <ProfileReviewPopup />
											}
										]
									},
									{
										path: "/profile/reviews/new",
										loader: profileReviewsNewLoader,
										element: <ProfileNewReviews />,
									}
								]
							},
							{
								path: "/profile/notifications",
								loader: profileNotificationsLoader,
								element: <ProfileNotifications />
							},
							{
								path: "/profile/subscriptions",
								loader: profileSubscriptionsLoader,
								element: <ProfileSubscriptions />
							},
						]
					},
					{
						path: "/profile/ads/:id/edit",
						loader: profileAdsEditLoader,
						element: <ProfileAdsEdit />
					},
					{
						path: "/profile/edit",
						loader: editProfileLoader,
						element: <EditProfilePage />
					},
					{
						path: "/ads/create",
						loader: capLoader,
						element: <CreateAdsPage />
					},
					{
						path: "/ads/:adsId",
						loader: adsLoader,
						element: <Ads />
					},
					{
						path: "/category/:category",
						loader: categoryLoader,
						element: <Category />
					},
					{
						path: "/category/:category/:subcategory",
						loader: categoryLoader,
						element: <Category />
					},
					{
						path: "/category/:category/:subcategory/:selected",
						loader: subcategoryLoader,
						element: <Subcategory />
					},
					{
						path: "/page/:id",
						loader: standartLoader,
						element: <Standart />
					},
					{
						path: "/privacy-policy",
						loader: privacyLoader,
						element: <Standart />
					},
					{
						path: "/user/:id",
						id: "user",
						loader: userLoader,
						element: <User />
					},
					{
						path: "/user/:id/ads",
						loader: userAdsLoader,
						element: <UserAds />
					},
					{
						path: "/user/:uid/reviews",
						loader: userReviewsLoader,
						element: <UserReviews />,
						children: [
							{
								path: "/user/:uid/reviews/:id",
								loader: userReviewPopupLoader,
								element: <UserReviewPopup />
							}
						]
					},
					{
						path: "/favorites",
						loader: favoritesLoader,
						element: <Favorites />
					},
				]
			}
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
