import { checkUnreads } from "./chat/checkUnreads";
import { getAvatar } from "./getAvatar";
import { getSiteOptions } from "./getSiteOptions";

export default async function rootLoader({ request }) {
	const url = new URL(request.url);
	const s = url.searchParams.get("s");
	const siteOptions = await getSiteOptions();
	const localUser = JSON.parse(localStorage.getItem("user"));

	if (localUser) {
		const responseAvatar = await getAvatar(localUser?.id);
		const responseUnreads = await checkUnreads(localUser?.basic);
		const isHaveUnread = responseUnreads.have_unreads;
		if (responseAvatar.success) {
			const avatar = responseAvatar.avatar;
			return { s, siteOptions, avatar, isHaveUnread };
		} else {
			return { s, siteOptions, isHaveUnread };
		}
	}
	const isHaveUnread = false;
	return { s, siteOptions, isHaveUnread };
}