import React from "react";
import { Link } from "react-router-dom";
import styles from "../main.module.css";

import categoriesIcon from "assets/images/AllCategories.png";
import { useContext, useState } from "react";
import { DeviceContext } from "context/DeviceContext";
import CategoryOverlay from "components/overlay/Category";

export default function MainCategories({ categories }) {
	const device = useContext(DeviceContext);
	const [showOverlay, setShowOverlay] = useState(false);

	return (
		<div className={styles.categories}>
			{device === "Mobile" &&
				<>
					<div className={styles.category} onClick={() => setShowOverlay(true)}>
						<img src={categoriesIcon} alt="Category" className={styles.categoryImage} />Смотреть все
					</div>
					{showOverlay && <CategoryOverlay closeEvent={() => setShowOverlay(false)} />}
				</>
			}
			{categories && categories.map(cat => {
				return (
					<Link key={cat.term_id} className={styles.category} to={`category/${cat.slug}`}>
						<img src={cat?.image} alt="Category" className={styles.categoryImage} />{cat.name}
					</Link>
				);
			})}
		</div>
	);
}