import React from "react";
import { useContext } from "react";
import styles from "./button.module.css";
import filterIcon from "assets/images/filter.svg";
import { FiltersDrawerContext } from "routes/main";

export default function FilterButton() {
	const filtersDrawer = useContext(FiltersDrawerContext);

	return (
		<div className={styles.filterButton} onClick={() => filtersDrawer.setShowDrawer(true)}>
			<img src={filterIcon} alt="" />
		</div>
	);
}