import React from "react";
import {
	Link
} from "react-router-dom";

import style from "../header.module.css";

export default function MenuLink({ link, name, icon }) {
	return (
		<Link to={link} className={style.menuLink}>
			<div className={style.iconWrapper}><img src={icon} alt="" /></div>
			{name}
		</Link>
	);
}