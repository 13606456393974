import React from "react";
import { useLocation } from "react-router-dom";
import ProfileHeader from "./Parts/ProfileHeader";
import MyAdsHeader from "./Parts/MyAdsHeader";
import CategoryHeader from "./Parts/CategoryHeader";

export default function MobileHeader({ type }) {
	const location = useLocation();

	const setType = () => {
		if (!type) {
			switch (location.pathname) {
			case "/profile":
				return <ProfileHeader />;
			case "/my_ads":
				return <MyAdsHeader />;
			default:
				return <></>;
			}
		} else {
			switch (type) {
			case "category":
				return <CategoryHeader />;
			default:
				return <></>;
			}
		}
	};

	return (
		<>
			{setType()}
		</>
	);
}