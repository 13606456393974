import { checkUser } from "api/checkUser";
import { getAds } from "api/getAds";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import styles from "./profile.module.css";
import inputStyles from "../../forms/form.module.css";
import { Link } from "react-router-dom";
import arrowIcon from "assets/images/arrow-left-blue.svg";
import InputGroup from "components/forms/parts/InputGroup";
import React, { useContext, useEffect, useState } from "react";
import AddressEdit from "components/popups/AddressEdit";
import { getAttibutes } from "api/getAttribues";
import Select from "components/popups/Select";
import cameraIcon from "assets/images/camera.svg";
import crossIcon from "assets/images/cross-black.svg";

import photoStyles from "../CreateAds/create-ads.module.css";
import TextareaGroup from "components/forms/parts/TextareaGroup";
import Button from "components/buttons/Button";
import { editAds } from "api/editAds";
import { AuthContext } from "context/AuthContext";
import { DeviceContext } from "context/DeviceContext";
import headerStyles from "../../header/parts/Mobile/header-mobile.module.css";
import { Helmet } from "react-helmet";

export async function loader({ params }) {
	const localUser = JSON.parse(localStorage.getItem("user"));
	const ads = await getAds(params.id, localUser.basic);

	if (!localUser) {
		return redirect(`/?show_login=true&from=/profile/ads/${params.id}/edit`);
	}

	const user = await checkUser(localUser.basic);

	if (!user?.success) {
		throw new Error("Эта страница только для авторизированных пользователей");
	}

	if (parseInt(ads?.post?.user?.id) === parseInt(user?.id)) {
		const data = ads.post;
		const attributes = await getAttibutes(data.term_id);
		return { data, attributes };
	} else {
		throw new Error("Страница не найдена");
	}
}

export default function ProfileAdsEdit() {
	const { data, attributes } = useLoaderData();
	const [showAddressPopup, setShowAddressPopup] = useState(false);
	const [selects, setSelects] = useState(null);
	const [popups, setPopups] = useState(null);
	const { user } = useContext(AuthContext);
	const [error, setError] = useState(null);
	const [showSuccess, setShowSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const device = useContext(DeviceContext);
	const navigate = useNavigate();

	const [state, setState] = useState({
		title: {
			value: data.title,
			name: "Название",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Название должно быть заполнено",
		},
		description: {
			value: data.description.replace("<br />", ""),
			name: "Описание",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Описание должно быть заполнено",
		},
		address: {
			value: JSON.parse(data.address),
			name: "Адрес",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Адрес должно быть заполнено",
		},
		price: {
			value: data.price,
			name: "Цена",
			required: true,
			type: "string",
			showError: false,
			errorText: "Поле Цена должно быть заполнено",
		},
		photosState: {
			error: null,
			imagesUrls: []
		},
		freeChecked: data.isFree === "true" ? true : false,
		emptyChecked: data.isEmpty === "true" ? true : false,
	});

	function checkboxHandler(e) {
		setState(s => {
			return {
				...s,
				freeChecked: false,
				emptyChecked: false
			};
		});
		setState(s => {
			return {
				...s,
				[e.target.name]: e.target.checked

			};
		});
	}

	function changeHandler(e) {
		setState(s => { return { ...s, [e.target.name]: { ...s[e.target.name], value: e.target.value } }; });
	}

	function selectAddressHandler() {
		setState(s => { return { ...s, address: { ...s["address"], showError: false } }; });
		setShowAddressPopup(true);
	}

	useEffect(() => {
		setState(s => { return { ...s, attributes: {} }; });
		for (let a in attributes) {
			setState(s => {
				return {
					...s,
					attributes: {
						...s["attributes"],
						[a]: {
							show: false,
							id: data.attributesObj?.[a].id || false,
							name: data.attributesObj?.[a].name || "Другое"
						}
					}
				};
			});
		}
	}, [attributes, data.attributesObj]);

	useEffect(() => {
		let selectList = [];
		let popupList = [];

		for (let attribute in attributes) {
			selectList.push(
				<label
					key={attribute}
					className={inputStyles.inputGroup}
					onClick={() => {
						setState(s => { return { ...s, attributes: { ...s["attributes"], [attribute]: { ...s["attributes"][attribute], show: true } } }; });
					}}
				>
					{attributes[attribute].name}
					<div className={inputStyles.inputSelect}>
						{state.attributes?.[attribute].name || "Нажмите чтобы указать"}
					</div>
				</label>
			);
			popupList.push(<React.Fragment key={attribute}>
				{
					state.attributes?.[attribute].show &&
					<Select
						s={state}
						setS={setState}
						attribute={attributes[attribute]}
						setShowPopup={() => {
							setState(s => { return { ...s, attributes: { ...s["attributes"], [attribute]: { ...s["attributes"][attribute], show: false } } }; });
						}}
					/>
				}
			</React.Fragment>);
		}

		setSelects(selectList);
		setPopups(popupList);

	}, [attributes, state.attributes, state]);

	const [images, setImages] = useState([]);

	useEffect(() => {
		setState(s => { return { ...s, photosState: { ...s.photosState, error: null } }; });

		if (images.length < 1) return;

		const newImageUrls = [];
		images.forEach(image => {
			if (image.size > 1024 * 1024 * 3 || (image.type !== "image/jpeg" && image.type !== "image/png")) {
				setState(s => { return { ...s, photosState: { ...s.photosState, error: "Некоторые изображения не были загружены, допустимые форматы изображения: .jpg, .jpeg, .png, максимальный размер файла: 3МБ" } }; });
			} else {
				newImageUrls.push({ file: image, src: URL.createObjectURL(image) });
			}
		});
		setState(s => { return { ...s, photosState: { ...s.photosState, imagesUrls: [...s.photosState.imagesUrls, ...newImageUrls] } }; });
	}, [images, setState]);

	function removeImage(key) {
		setState(s => {
			return {
				...s, photosState: {
					...s.photosState,
					imagesUrls: s.photosState.imagesUrls.filter((i, k) => k !== key)
				}
			};
		});
	}

	useEffect(() => {
		let array = [];
		data.photosObj.forEach(item => {
			array = [...array, { src: item.url, id: item.id }];
		});
		setState(s => {
			return {
				...s, photosState: {
					...s.photosState,
					imagesUrls: array
				}
			};
		});
	}, [data.photosObj]);

	async function submitHandler(e) {
		e.preventDefault();
		setLoading(true);

		let body = {
			title: state.title.value,
			description: state.description.value,
			price: state.price.value,
			isFree: state.freeChecked,
			isEmpty: state.emptyChecked,
			address: state.address.value,
			attributes: {},
			photos: [],
			photosObj: {},
			id: data.id
		};

		let photos = state.photosState.imagesUrls;
		for (let photo in photos) {
			if (photos[photo]?.file) {
				body.photos.push(photos[photo].file);
				body.photosObj[`photo-${photo}`] = null;
			} else if (photos[photo].id) {
				body.photosObj[`photo-${photo}`] = (photos[photo].id);
			}
		}

		let attributes = state.attributes;
		for (let attribute in attributes) {
			body.attributes[attribute] = attributes[attribute].id;
		}


		const response = await editAds(body, user.basic);
		if (response.success) {
			setError(null);
			setShowSuccess(true);
		} else {
			setError(response.message);
			setShowSuccess(false);
		}
		setLoading(false);
	}

	return (
		<>
			<Helmet>
				<title>Личный кабинет - Редактирование объявления</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<div className={`${styles.editProfileContainer} container`}>
				<div className={styles.left}>
					<div className={styles.stickyContainer}>
						<Link to={`/profile/ads/${data.id}`} className={styles.leave}><img src={arrowIcon} alt="Icon" />Отмена</Link>
					</div>
				</div>
				{device === "Mobile" &&
				<header className={headerStyles.header}>
					<div className={`${headerStyles.headerContainer} ${styles.line}`}>
						<div className={headerStyles.back} onClick={() => { window.history.length === 1 ? navigate("/") : navigate(-1); }}></div>
						<h2 className={`${headerStyles.headerTitle}`}>Редактирование объявления</h2>
					</div>
				</header>}
				<div className={styles.right}>
					{device === "Desktop" && <h2 className={styles.title}>Редактировать объявление</h2>}
					{device === "Mobile" && <h2 className={styles.title}>Основная информация</h2>}
					<form onSubmit={submitHandler} className={styles.form}>
						<div className={styles.formGroup}>
							<InputGroup state={state} name="title" onChange={changeHandler} text="Название" placeholder="Введите название" />
							<div>
								<label className={inputStyles.inputGroup + ((state.emptyChecked || state.freeChecked) ? ` ${inputStyles.disabled}` : "")}>Укажите цену
									<input type='text' disabled={state.emptyChecked || state.freeChecked} name='price' value={state.price.value} onChange={e => {
										e.target.value = e.target.value.replace(/\D/g, "");
										changeHandler(e);
									}} placeholder='1000' />
								</label>
								<label className={inputStyles.checkbox + (state.emptyChecked ? ` ${inputStyles.checked}` : "")}>
									<div className={inputStyles.checkboxBox}></div>
								Не указывать цену
									<input type="checkbox" name="emptyChecked" checked={state.emptyChecked} onChange={checkboxHandler} />
								</label>
								<label className={inputStyles.checkbox + (state.freeChecked ? ` ${inputStyles.checked}` : "")}>
									<div className={inputStyles.checkboxBox}></div>
								Бесплатно
									<input type="checkbox" name="freeChecked" checked={state.freeChecked} onChange={checkboxHandler} />
								</label>
							</div>
							<label className={inputStyles.inputGroup} onClick={selectAddressHandler}>Адрес
								<div className={inputStyles.inputSelect}>
									{state?.address?.value?.value ? <span>{state.address.value.value}</span> : "Нажмите чтобы указать"}
								</div>
								{state.address.showError && <span className={inputStyles.errorText}>{state.address.errorText}</span>}
							</label>
							{showAddressPopup && <AddressEdit setShowPopup={setShowAddressPopup} state={state} setState={setState} />}
						</div>
						<div className={styles.formGroup}>
							<h3 className={styles.subtitle}>Характеристики</h3>
							<div className={`${styles.selectsContainer} ${styles.formGroup}`}>
								{selects}
								{false && <span className={inputStyles.errorText}>Нужно указать значение у всех характеристик</span>}
							</div>
							{popups}
						</div>
						<div className={styles.formGroup}>
							<h3 className={styles.subtitle}>Дополнительная информация</h3>
							<div className={styles.formGroup}>
								<h4 className={styles.photosTitle}>Фотографии</h4>
								<div className={photoStyles.photos}>
									{state.photosState.imagesUrls.map((img, key) => <div key={key} className={photoStyles.photo}><img src={crossIcon} className={photoStyles.deletePhoto} alt="Cross icon" onClick={() => { removeImage(key); }} /><img data-fancybox="photos" src={img.src} alt="" className={photoStyles.photoImage} /></div>)}
									<label className={photoStyles.labelPhoto}>
										<img src={cameraIcon} alt='Camera icon' />
										<span>Добавить фото</span>
										<input type="file" multiple onChange={(e) => setImages([...e.target.files])} accept="image/png,image/jpeg" />
									</label>
								</div>
								{state.photosState.error && <div className={photoStyles.errorText}>{state.photosState.error}</div>}
								<div className={photoStyles.photosBottom}>
									<div className={photoStyles.infoText}>Параметры изображений: .jpg, .jpeg, .png, размер &lt; 3МБ</div>
									{state.photosState.imagesUrls && <div className={photoStyles.photosCount}><span className={state.photosState.imagesUrls.length > 10 ? ` ${photoStyles.countError}` : ""}>{state.photosState.imagesUrls.length}</span> / 10</div>}
								</div>
								<TextareaGroup name='description' text="Описание" state={state} placeholder="Введите описание объявления" onChange={changeHandler} />
								{error && <div className={styles.error}>{error}</div>}
								{showSuccess && <div className={styles.success}>Изменения успешно применены</div>}
								<Button showPreloader={loading} type="submit" className={styles.submit} name="Сохранить изменения" color="green" />
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}