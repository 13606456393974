import React from "react";
import { getStory } from "api/getStory";
import { useContext, useEffect, useState } from "react";
import Stories from "stories-react";
import "stories-react/dist/index.css";
import styles from "../stories.module.css";
import { Link, useNavigate } from "react-router-dom";
import { DeviceContext } from "context/DeviceContext";

import iconCross from "assets/images/cross-black.svg";

export default function StoriesItem({ setShowStory, id }) {
	const [stories, setStories] = useState([]);
	const device = useContext(DeviceContext);
	const navigate = useNavigate();

	function StoriesPage(props) {
		return (
			<div
				className="box"
				style={{
					padding: "52px 24px 32px 24px",
					backgroundColor: props.story.data.stories_page_background_color,
					height: "100%",
					display: "flex",
					flexDirection: "column",
					gap: window.innerWidth > 1259 ? 16 : 12,
					borderRadius: window.innerWidth > 540 ? 16 : 0,
				}}
			>
				{device === "Mobile" && <img onClick={() => setShowStory(false)} className={styles.pageCross} src={iconCross} alt="cross" />}
				<img src={props.story.data.stories_page_image} alt="" className={styles.pageImage} />
				<h2 className={styles.pageTitle}>{props.story.data.stories_page_title}</h2>
				<p className={styles.pageDescription}>{props.story.data.stories_page_description}</p>
				{
					!props.story.data.is_stories_page_link &&
					props.story.data.stories_page_common_link &&
					<Link to={`/pages/${props.story.data.stories_page_common_link}`} className={styles.pageButton} onClick={e => {
						e.preventDefault();
						setShowStory(false);
						navigate(`/page/${props.story.data.stories_page_common_link}`);
					}}>{props.story.data.stories_page_button_text}</Link>
				}
				{
					props.story.data.is_stories_page_link &&
					props.story.data.stories_page_link &&
					<Link to={props.story.data.stories_page_link} className={styles.pageButton} onClick={e => {
						e.preventDefault();
						window.open(props.story.data.stories_page_link, "_blank");
					}}>{props.story.data.stories_page_button_text}</Link>
				}
			</div>
		);
	}

	useEffect(() => {
		let ignore = false;

		if (!ignore) {
			getStory(id).then(
				data => {
					if (ignore) return;
					data.forEach(story => {
						setStories(s => { return [...s, { type: "component", duration: 9000, component: StoriesPage, data: story }]; });
					});
				}
			);
		}

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line
}, [id])

	return (
		<div
			style={{
				display: "grid",
				placeItems: "center",
				width: "100%",
				marginBottom: "16px",
				position: "fixed",
				height: "100%",
				zIndex: 10000,
				top: 0,
				left: 0,
				background: "var(--main-color-blackout)",
				overflow: "auto"
			}}
			onClick={() => setShowStory(false)}
		>
			<div onClick={(e) => e.stopPropagation()} style={{ display: "flex", alignItems: "center", margin: "24px 0", height: window.innerWidth > 540 ? "fit-content" : "100%" }}>
				{stories.length !== 0 ? <Stories width={window.innerWidth > 540 ? "540px" : "100%"} height={window.innerWidth > 540 ? "760px" : "100%"} stories={stories} /> : "Загрузка..."}
			</div>
		</div>
	);
}