import React from "react";
import UserLogin from "components/forms/User/UserLogin";
import styles from "../profile.module.css";
import { useState } from "react";
import Overlay from "components/overlay/Overlay";
import UserRegistration from "components/forms/User/UserRegistartion";

export default function UserAuth() {
	const [showOverlay, setShowOverlay] = useState(false);
	const [isAfterRegistration, setIsAfterRegistration] = useState(false);

	const afterRegistration = () => {
		setIsAfterRegistration(true);
		setShowOverlay(false);
	};

	return (
		<form className={styles.userAuthForm} style={{ height: window.innerHeight - 70 - 32 - 48 }}>
			<h3 className={`mobileTitle ${styles.userAuthTitle}`}>Авторизация</h3>
			<UserLogin isAfterRegistration={isAfterRegistration} changeForm={() => setShowOverlay(true)} />
			{showOverlay &&
				<Overlay backEvent={() => setShowOverlay(false)} title="Регистрация">
					<div className={styles.userRegistrationFrom}>
						<UserRegistration afterRegistration={afterRegistration} />
					</div>
				</Overlay>
			}
		</form>
	);
}