import { DeviceContext } from "context/DeviceContext";
import { useContext } from "react";
import { useRouteError } from "react-router-dom";
import SearchHeader from "components/header/parts/Mobile/Parts/SearchHeader";
import corgiLogo from "assets/images/corgi.svg";
import styles from "../components/pages/ErrorPage/error.module.css";
import { Link } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";

export default function ErrorPage() {
	const error = useRouteError();
	const device = useContext(DeviceContext);

	return (
		<>
			<Helmet>
				<title>Ошибка 404 - Страница не найдена</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			<div className='container'>
				{device === "Mobile" && <SearchHeader isError={true} />}
				<div className={styles.container}>
					<div className={styles.wrapper}>
						<img src={corgiLogo} alt="Corgi" className={styles.logo} />
						<h2 className={styles.title}>Упс</h2>
						<h3 className={styles.subtitle}>Что-то пошло не так</h3>
						<div className={styles.message}>{error?.statusText || error?.message || "404 Страница не найдена"}</div>
						<Link to="/" className={styles.link}>Вернуться на главную</Link>
					</div>
				</div>
			</div>
		</>
	);
}