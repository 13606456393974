export async function getChat(chatId, basic) {

	return fetch(`https://api.funtik.su/wp-json/funtik/v1/chat/${chatId}`, {
		method: "GET",
		headers: {
			"Authorization": `Basic ${window.btoa(basic)}`
		}
	})
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			return error;
		});
}