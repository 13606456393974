import React from "react";
import styles from "../form.module.css";

export default function InputGroup({ state, name, onChange, text, type, placeholder }) {
	return (
		<label className={styles.inputGroup}>{text}
			<input type={type} name={name} onChange={onChange} value={state[name].value} placeholder={placeholder} />
			{state[name].showError && <span className={styles.errorText}>{state[name].errorText}</span>}
		</label>
	);
}