import React from "react";
import styles from "../create-ads.module.css";
import { NavLink } from "react-router-dom";

import arrowIcon from "assets/images/arrow-left-blue.svg";
import { useContext } from "react";
import SelectCategory from "components/forms/CreateAds/SelectCategory";
import StepNav from "./parts/StepNav";
import { DataContext, StepContext } from "../CreateAdsPage";
import SelectPhotos from "components/forms/CreateAds/SelectPhotos";
import SelectDescription from "components/forms/CreateAds/SelectDescription";
import SelectAttributes from "components/forms/CreateAds/SelectAttribues";
import CreateAds from "components/forms/CreateAds/CreateAds";

export default function DesktopCreateAdsPage() {
	const { step } = useContext(StepContext);
	const { selectPhotosState, setSelectPhotosState } = useContext(DataContext);

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.stickyContainer}>
					<NavLink to="/" className={styles.leave}><img src={arrowIcon} alt="Icon" />Отмена</NavLink>
					<StepNav />
				</div>
			</div>
			<div className={styles.right}>
				<h2 className={styles.title}>Новое объявление</h2>
				<div className={styles.stepContent}>
					{(step === "1") && <SelectCategory styles={styles} />}
					{(step === "2") && <SelectPhotos state={selectPhotosState} setState={setSelectPhotosState} styles={styles} />}
					{(step === "3") && <SelectDescription styles={styles} />}
					{(step === "4") && <SelectAttributes styles={styles} />}
					{(step === "5") && <CreateAds styles={styles} />}
				</div>
			</div>
		</div>
	);
}