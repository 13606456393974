import React from "react";
import { useContext, useState } from "react";
import { AuthContext } from "context/AuthContext";
import { Navigate, redirect, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getProfile } from "api/getProfile";

import { validateField } from "api/validation";

import arrowIcon from "assets/images/arrow-left-blue.svg";

import styles from "./editProfile.module.css";
import { editProfile } from "api/editProfile";
import { DeviceContext } from "context/DeviceContext";
import Overlay from "components/overlay/Overlay";
import EditProfile from "components/forms/EditProfile/EditProfile";
import { MainContext } from "context/MainContext";
import { Helmet } from "react-helmet";

export async function loader() {
	const user = localStorage.getItem("user");

	if (user) {
		const parseUser = JSON.parse(user);
		const response = await getProfile(parseUser.basic);
		if (response.success) {
			const userEditProfileData = response.user;
			return { userEditProfileData };
		} else {
			localStorage.removeItem("user");
			return redirect("/?show_login=true&from=/profile/edit");
		}
	} else {
		localStorage.removeItem("user");
		return redirect("/?show_login=true&from=/profile/edit");
	}
}

export default function EditProfilePage() {
	const { user } = useContext(AuthContext);
	const location = useLocation();
	const device = useContext(DeviceContext);
	const navigate = useNavigate();

	const [showMask, setShowMask] = useState(false);
	const [showPreloader, setshowPreloader] = useState(false);

	const { userEditProfileData } = useLoaderData();

	const { setProfileAvatar } = useContext(MainContext);

	const [state, setState] = useState({
		avatar: {
			value: userEditProfileData.avatar,
			showError: false,
			file: null
		},
		background: {
			value: userEditProfileData.background,
			showError: false,
			file: null
		},
		name: {
			value: userEditProfileData.name,
			showError: false
		},
		phone: {
			value: userEditProfileData.phone,
			showError: false
		},
		description: {
			value: userEditProfileData.description.replace("<br />", ""),
			showError: false
		},
		showSuccessText: false
	});

	function changeHandler(e) {
		setState(s => {
			if (e.target.value) {
				setShowMask(true);
			} else {
				setShowMask(false);
			}

			return {
				...s,
				[e.target.name]: {
					...s[e.target.name],
					value: e.target.value
				}
			};
		});
	}

	function uploadHandler(e) {
		if (e.target.files.length !== 0) {
			let file = e.target.files[0];
			let name = e.target.name;

			console.log(name === "avatar");

			if (file.size > 3 * 1024 * 1024 || (file.type !== "image/jpeg" && file.type !== "image/png")) {
				setState(s => {
					return {
						...s,
						[name]: {
							...s[name],
							errorText: `${name === "avatar" ? "Фото профиля" : "Фоновое изображение профиля"} не было загружено, допустимые форматы изображения: .jpg, .jpeg, .png, максимальный размер файла: 3МБ`,
							showError: true
						}
					};
				});
			} else {
				setState(s => {
					return {
						...s,
						[name]: {
							...s[name],
							file: file,
							value: URL.createObjectURL(file),
							showError: false
						}
					};
				});
			}
		}
	}

	async function clickHandler() {
		setshowPreloader(true);
		if (!state.name.value || !state.phone.value) {
			setState(s => {
				return {
					...s,
					name: {
						...s.name,
						errorText: "Поле Имя должно быть заполнено",
						showError: !state.name.value && true,
					},
					phone: {
						...s.phone,
						errorText: "Поле Телефон должно быть заполнено",
						showError: !state.phone.value && true,
					},
					showSuccessText: false
				};
			});
			setshowPreloader(false);
		} else if (!validateField("phone", state.phone.value) || state.phone.value.length !== 18) {
			setState(s => {
				return {
					...s,
					phone: {
						...s.phone,
						errorText: "Номер телефона неверный",
						showError: true,
					},
					showSuccessText: false
				};
			});
			setshowPreloader(false);
		} else {
			setState(s => {
				return {
					...s,
					name: {
						...s.name,
						showError: false,
					},
					phone: {
						...s.phone,
						showError: false,
					},
					description: {
						...s.description,
						showError: false,
					}
				};
			});
			let data = {
				name: state.name.value,
				phone: state.phone.value,
				description: state.description.value,
				avatar: state.avatar.file,
				background: state.background.file
			};
			await editProfile(data, user.basic)
				.then(response => {
					setshowPreloader(false);
					setState(s => {
						return {
							...s,
							showSuccessText: response.success
						};
					});
					if (response.avatar) {
						setProfileAvatar(response.avatar);
					}
				});
		}
	}

	return user ? (
		<>
			<Helmet>
				<title>Личный кабинет - Редактирование профиля</title>
				<meta name="description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
				<meta property="og:description" content="Сервис объявлений с товарами для животных. Поиск потерянных питомцев. Поиск новых владельцев для питомцев."/>
			</Helmet>
			{
				device === "Desktop" ?
					<div className={`container ${styles.container}`}>
						<div className={styles.left}>
							<Link to="/profile" className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться в профиль</Link>
						</div>
						<div className={styles.right}>
							<h2 className={styles.title}>Редактирование профиля</h2>
							<EditProfile
								styles={styles}
								state={state}
								uploadHandler={uploadHandler}
								changeHandler={changeHandler}
								clickHandler={clickHandler}
								showMask={showMask}
								showPreloader={showPreloader}
							/>
						</div>
					</div> :
					<Overlay backEvent={() => navigate(-1)} title="Редактирование профиля">
						<div className={styles.mobileContainer}>
							<EditProfile
								styles={styles}
								state={state}
								uploadHandler={uploadHandler}
								changeHandler={changeHandler}
								clickHandler={clickHandler}
								showMask={showMask}
								showPreloader={showPreloader}
							/>
						</div>
					</Overlay>
			}
		</>
	) : <Navigate to='/' from={location} />;
}