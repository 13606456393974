import { setFormData } from "./methods";

export async function getAdsList(body) {
	const city = body?.city;

	const data = setFormData({
		before: body.before,
		sort: body.sort,
		category: body.category,
		filters: body.filters || false,
		s: body.s || "",
		fias_level: city?.data?.fias_level,
		fias_id: city?.data?.fias_id,
		isCityPosts: body.isCityPosts
	});

	return fetch(`https://api.funtik.su/wp-json/funtik/v1/post/page/${body.page}`, {
		method: "POST",
		body: data,
	})
		.then(response => response.json())
		.then(data => { return data; })
		.catch(e => {
			return e;
		});
}