import React from "react";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import styles from "./user.module.css";
import AdsCard from "components/ads/parts/AdsCard";
import { useContext, useEffect, useRef, useState } from "react";
import adsStyles from "../../ads/ads.module.css";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import * as DOMPurify from "dompurify";

import arrowIcon from "assets/images/arrow-left-blue.svg";
import UserCard from "components/user/UserCard";


import userIcon from "assets/images/User-placeholder.svg";
import eyeIcon from "assets/images/eye-placeholder.svg";
import starIcon from "assets/images/star-fill.svg";
import commentIcon from "assets/images/comment-blue.svg";

import shareIcon from "assets/images/share-header.svg";
import placeholder from "assets/images/User-gray.svg";
import backgroundPlaceholder from "assets/images/background-placeholder.png";
import { DeviceContext } from "context/DeviceContext";
import ReviewsInfo from "../Profile/parts/ReviewsInfo";
import ProfileReviewCard from "../Profile/parts/ProfileReviewCard";
import { ReviewsContext } from "../Profile/ProfileReviews";
import { checkUser } from "api/checkUser";
import Button from "components/buttons/Button";

export default function UserPage() {
	const { user, reviewsInfo, userReviews } = useRouteLoaderData("user");
	const currentUser = JSON.parse(localStorage.getItem("user"));
	const [showDescription, setShowDescription] = useState(false);
	const device = useContext(DeviceContext);
	
	const {subscriptionList} = useLoaderData();
	const [subscribed, setSubscribed] = useState(subscriptionList?.includes(user.id));
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	function subscribeHandler() {
		const localUser = JSON.parse(localStorage.getItem("user"));
		setLoading(true);
		
		checkUser(localUser?.basic)
			.then(data => {
				if (data.success) {
					fetch(`https://api.funtik.su/wp-json/funtik/v1/subscribe/${user.id}`, {
						method: "GET",
						headers: {
							Authorization: `Basic ${window.btoa(localUser?.basic)}`,
						},
					})
						.then((response) => response.json())
						.then((data) => {
							if (data.success) {
								setSubscribed(data.data.includes(user.id));
								setLoading(false);
							}
							throw new Error("Произошла ошибка");
						})
						.catch((e) => {
							throw new Error(e);
						});
				} else {
					navigate(`/?show_login=true&from=/user/${user.id}`);
				}
			})
			.catch(e => {throw new Error(e);});
	}

	function getMonthName(month) {
		switch (month) {
		case 0:
			return "Января";
		case 1:
			return "Февраля";
		case 2:
			return "Марта";
		case 3:
			return "Апреля";
		case 4:
			return "Мая";
		case 5:
			return "Июня";
		case 6:
			return "Июля";
		case 7:
			return "Августа";
		case 8:
			return "Сентября";
		case 9:
			return "Октября";
		case 10:
			return "Ноября";
		case 11:
			return "Декабря";
		default:
			return;
		}
	}

	const sliderRef = useRef();
	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.addEventListener("touchstart", e => e.stopPropagation());
			user.ads.length !== 0 && sliderRef.current.initialize();
		}
	}, [sliderRef, device]);

	const slider2Ref = useRef();
	useEffect(() => {
		if (slider2Ref.current) {
			slider2Ref.current.addEventListener("touchstart", e => e.stopPropagation());
			userReviews.length !== 0 && slider2Ref.current.initialize();
		}
	}, [slider2Ref, device]);

	return (
		<ReviewsContext.Provider value={{path: `/user/${user.id}/reviews`}}>
			{device === "Desktop" ? (
				<div className={`container ${styles.container}`}>
					<div className={styles.left}>
						<div className={styles.stickyContainer}>
							<Link to="/" className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться на главную</Link>
							{parseInt(currentUser?.id) !== user.id ? <UserCard user={user} /> : <UserCard user={user} type="my" />}
						</div>
					</div>
					<div className={styles.right}>
						{parseInt(currentUser?.id) === user.id && <div className={styles.infoText}>Так видят ваш профиль другие пользователи, <Link to="/profile" className={styles.link}>Перейти в профиль?</Link></div>}
						<div className={styles.information}>
							<h2 className={styles.name}>{user.name}</h2>
							<div className={styles.registered}>На Funtik с {getMonthName(new Date(Date.parse(user.registered)).getMonth())} {new Date(Date.parse(user.registered)).getFullYear()}</div>
							{user.description && <div className={styles.descriptionWrapper}>
								<div className={styles.description} style={{ display: showDescription ? "block" : "-webkit-box" }}>{parse(DOMPurify.sanitize(user.description))}</div>
								<div className={styles.descriptionButton} onClick={() => setShowDescription(!showDescription)}>{showDescription ? "Скрыть" : "Развернуть"}</div>
							</div>}
						</div>
						<div className={`${styles.adsList} ${adsStyles.grid}`}>
							<h3 className={styles.title}>Объявления пользователя</h3>
							{user.ads && user.ads.length !== 0 ?
								<swiper-container
									slides-per-view="3.3"
									class={styles.gallery}
									ref={sliderRef}
									space-between={16}
									touch-events-target='wrapper'
									touch-start-prevent-default={true}
									init={false}
								>
									{user.ads.map(item => { return <swiper-slide class={styles.slide} key={item.id}><AdsCard useLayout={false} post={item} /></swiper-slide>; })}
								</swiper-container>
								:
								<div className={styles.infoText}>Пользователь пока не размещал объявления</div>
							}
							{user.ads && user.ads.length !== 0 && <Link to={`/user/${user.id}/ads`} className={`${styles.button} ${styles.white}`} >Все объявления</Link>}
						</div>
						<div className={styles.reviews}>
							<ReviewsInfo reviewsInfo={reviewsInfo} />
							{userReviews.length !== 0 ? 
								<>
									<swiper-container
										slides-per-view="1.2"
										class={styles.gallery}
										ref={slider2Ref}
										space-between={16}
										touch-events-target='wrapper'
										touch-start-prevent-default={true}
										init={false}
									>
										{userReviews.map(item => { return <swiper-slide class={styles.slide} key={item.review.id}><ProfileReviewCard isUser={true} item={item} /></swiper-slide>; })}
									</swiper-container>
									{<Link to={`/user/${user.id}/reviews`} className={`${styles.button} ${styles.white}`} >Все отзывы</Link>}
								</>
								: <div className={styles.infoText}>Пользователю пока не оставляли отзывы</div>
							}
						</div>
					</div>
				</div>
			) : (
				<div className={styles.profileMobile}>
					<div className={styles.profileTop}>
						<div className={styles.profileHeader}>
							<div className={styles.shareButton} onClick={() => window.navigator.share({ url: `${window.location.origin}/user/${user.id}`, title: "Поделиться профилем", text: `Funtik: ${user.name}` })}>
								<img src={shareIcon} alt="" />
							</div>
							<div className={styles.profileHeaderButtons}>
							</div>
						</div>
						{user.background ? <img src={user.background} className={styles.profileBackground} alt="" /> : <img src={backgroundPlaceholder} className={styles.profileBackground} alt="" />}
						{user.avatar ? <img src={user.avatar} className={styles.profileAvatar} alt="" /> : <img src={placeholder} className={`${styles.profileAvatar} ${styles.placeholder}`} alt="" />}
					</div>
					<div className={styles.profileContent}>
						<div className={styles.profileContainer}>
							<div className={styles.profileText}>
								<h2 className={styles.profileName}>{user.name}</h2>
								<div className={styles.registered}>На Funtik с {getMonthName(new Date(Date.parse(user.registered)).getMonth())} {new Date(Date.parse(user.registered)).getFullYear()}</div>
								<div className={styles.info}>
									<div className={styles.infoItem}>
										<img src={userIcon} alt="" />
										{user.subscribers} подписчиков
									</div>
									<div className={styles.infoItem}>
										<img src={eyeIcon} alt="" />
										{user.subscriptions} подписок
									</div>
									<div className={styles.infoItem}>
										<img src={starIcon} alt="" />
										{user.rating} рейтинг
									</div>
									<Link to={`/user/${user.id}/reviews`} className={styles.infoItem} style={{color: "var(--main-color-blue)"}}>
										<img src={commentIcon} alt="" />
										{user.reviews} отзывов
									</Link>
								</div>
								{user.description && <div className={styles.descriptionWrapper}>
									<div className={styles.description} style={{ display: showDescription ? "block" : "-webkit-box" }}>{parse(DOMPurify.sanitize(user.description))}</div>
									<div className={styles.descriptionButton} onClick={() => setShowDescription(!showDescription)}>{showDescription ? "Скрыть" : "Развернуть"}</div>
								</div>}
								{!subscribed ? <Button name="Подписаться" showPreloader={loading} onClick={subscribeHandler} color="blue" className={styles.button} /> : <Button name="Отписаться" onClick={subscribeHandler} showPreloader={loading} color="white" className={styles.button} />}
							</div>
							<div className={styles.adsSection}>
								<h3 className={styles.title}>Объявления</h3>
								{user.ads.length !== 0 && <div className={`${styles.adsList} ${adsStyles.grid}`}>
									{user.ads.map((card, key) => { return key < 4 ? <AdsCard key={key} post={card} useLayout={false} /> : null; })}
								</div>}
								{user.ads.length !== 0 ? <Link to={`/user/${user.id}/ads`} className={styles.button}>Показать все объявления</Link> :
									<div className={styles.infoText}>Пользователь пока не размещал объявления</div>}
							</div>
							<div className={styles.reviewSection}>
								<h3 className={styles.title}>Отзывы</h3>
								{userReviews.length !== 0 ? 
									[
										<swiper-container
											slides-per-view="1.2"
											class={styles.gallery}
											ref={slider2Ref}
											space-between={16}
											touch-events-target='wrapper'
											touch-start-prevent-default={true}
											init={false}
											key="reviewSwiper"
										>
											{userReviews.map(item => { return <swiper-slide style={{height: "100%"}} class={styles.slide} key={item.review.id}><ProfileReviewCard isUser={true} item={item} /></swiper-slide>; })}
										</swiper-container>, 
										<Link key="reviewButton" to={`/user/${user.id}/reviews`} className={`${styles.button}`} >Показать все отзывы</Link>
									]
									: <div className={styles.infoText}>Пользователю пока не оставляли отзывы</div>
								}
							</div>
						</div>
					</div>
				</div>
			)}
		</ReviewsContext.Provider>
	);
}