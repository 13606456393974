export function setFormData(data) {
	const formData = new FormData();
	const images = ["avatar", "background"];
	for (const item in data) {
		if (item === "photos") {
			data[item].forEach((photo, key) => {
				formData.append(`photo${key}`, photo, photo.name);
			});
		} else if (images.includes(item) && data[item] !== null) {
			formData.append(item, data[item], data[item].name);
		} else if (typeof data[item] === "object" && !Array.isArray(data[item])) {
			formData.append(item, JSON.stringify(data[item]));
		} else {
			formData.append(item, data[item]);
		}
	}

	return formData;
}
