import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../User/user.module.css";
import arrowIcon from "assets/images/arrow-left-blue.svg";
import UserCard from "components/user/UserCard";
import { NavLink } from "react-router-dom";
import ProfileMyAdsSidebar from "./ProfileMyAdsSidebar";

export default function ProfileSidebar({ user }) {
	const location = useLocation();

	return (
		<div className={styles.stickyContainer}>
			<Link to="/" className={styles.leave}><img src={arrowIcon} alt="Icon" />Вернуться на главную</Link>
			<div className={styles.navigation}>
				<NavLink to="/profile" className={({ isActive }) => isActive ? styles.active : ""} end>Профиль</NavLink>
				<NavLink to="/profile/ads" className={({ isActive }) => isActive ? styles.active : ""} end>Мои объявления</NavLink>
				<NavLink to="/profile/reviews" className={({ isActive }) => isActive ? styles.active : ""}>Отзывы</NavLink>
				<NavLink to="/profile/chat" className={({ isActive }) => isActive ? styles.active : ""}>Сообщения</NavLink>
				<NavLink to="/profile/notifications" className={({ isActive }) => isActive ? styles.active : ""}>Уведомления</NavLink>
				<NavLink to="/favorites" className={({ isActive }) => isActive ? styles.active : ""}>Избранное</NavLink>
				<NavLink to="/profile/subscriptions" className={({ isActive }) => isActive ? styles.active : ""}>Подписки</NavLink>
			</div>
			{!location.pathname.match("/profile/ads/") && <UserCard user={user} type="profile" />}
			{location.pathname.match("/profile/ads/") && <ProfileMyAdsSidebar />}
		</div>
	);
}