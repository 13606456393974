import { DataContext } from "components/pages/CreateAds/CreateAdsPage";
import React, { useContext, useState } from "react";
import inputStyles from "../form.module.css";
import Button from "components/buttons/Button";
import { StepContext } from "components/pages/CreateAds/CreateAdsPage";
import { createAds as fetchData } from "api/createAds";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function CreateAds({ styles }) {
	const navigate = useNavigate();

	const { data } = useContext(DataContext);
	const { setStep } = useContext(StepContext);

	const [showError, setShowError] = useState(false);
	const [adsCreateError, setAdsCreateError] = useState("");
	const { user } = useContext(AuthContext);
	const [showPreloader, setShowProloader] = useState(false);

	function changeHandler(e) {
		setShowError(false);
		e.target.value = e.target.value.replace(/\D/g, "");
		data.setData(d => {
			return {
				...d,
				price: {
					...d.price,
					value: e.target.value.replace(/\D/g, ""),
				}
			};
		});
	}

	function checkboxHandler(e) {
		setShowError(false);
		data.setData(d => {
			return {
				...d,
				price: {
					...d.price,
					freeChecked: false,
					emptyChecked: false
				}
			};
		});
		data.setData(d => {
			return {
				...d,
				price: {
					...d.price,
					[e.target.name]: e.target.checked
				}
			};
		});
	}

	function clickHandler() {
		setShowProloader(true);
		createAds();
	}

	async function createAds() {
		if ((data.data.price.value && data.data.price.value !== 0) || data.data.price.freeChecked || data.data.price.emptyChecked) {
			let body = {
				title: data.data.title.value,
				description: data.data.description.value,
				category: data.data.category.value.term_id,
				price: data.data.price.value,
				isFree: data.data.price.freeChecked,
				isEmpty: data.data.price.emptyChecked,
				address: data.data.address.value,
				attributes: {},
				photos: []
			};

			let photos = data.data.photos.value;
			for (let photo in photos) {
				body.photos.push(photos[photo].file);
			}

			let attributes = data.data.attributes;
			for (let attribute in attributes) {
				body.attributes[attribute] = attributes[attribute].id;
			}

			await fetchData(body, user.basic)
				.then(async data => {
					setShowProloader(false);
					if (data.success === true) {
						navigate(`/ads/${data.post}`);
					} else {
						setAdsCreateError(data.message);
					}
				})
				.catch(error => {
					setShowProloader(false);
					setAdsCreateError(error);
				});
		} else {
			setShowError(true);
			setShowProloader(false);
		}
	}

	return (
		<>
			<div>
				<label className={inputStyles.inputGroup + (data.data.price.checked ? ` ${inputStyles.disabled}` : "")}>Укажите цену
					<input type='text' disabled={data.data.price.emptyChecked || data.data.price.freeChecked} name='price' value={data.data.price.value} onChange={changeHandler} placeholder='1000' />
				</label>
				<label className={inputStyles.checkbox + (data.data.price.emptyChecked ? ` ${inputStyles.checked}` : "")}>
					<div className={inputStyles.checkboxBox}></div>
					Не указывать цену
					<input type="checkbox" name="emptyChecked" checked={data.data.price.emptyChecked} onChange={checkboxHandler} />
				</label>
				<label className={inputStyles.checkbox + (data.data.price.freeChecked ? ` ${inputStyles.checked}` : "")}>
					<div className={inputStyles.checkboxBox}></div>
					Бесплатно
					<input type="checkbox" name="freeChecked" checked={data.data.price.freeChecked} onChange={checkboxHandler} />
				</label>
				{showError && <span style={{ marginTop: 12, display: "block" }} className={inputStyles.errorText}>Введите цену или отметьте пункт &quot;Не указывать цену&quot;</span>}
				{adsCreateError && <span style={{ marginTop: 12, display: "block" }} className={inputStyles.errorText}>{adsCreateError}</span>}
			</div>
			<div className={styles.buttons}><Button name="Назад" className={styles.backButton} onClick={() => setStep("4")} /><Button showPreloader={showPreloader} name="Создать объявление" color='green' onClick={clickHandler} className={styles.nextButton} /></div>
		</>
	);
}