import React from "react";
import { useContext, useEffect, useState } from "react";

import styles from "./popup.module.css";
import overlayStyles from "../overlay/overlay.module.css";

import { DeviceContext } from "context/DeviceContext";
import PopupContainer from "./parts/PopupContainer";
import Overlay from "components/overlay/Overlay";

import otherIcon from "assets/images/other-icon.png";

export default function Select({ setShowPopup, attribute, s, setS }) {
	const device = useContext(DeviceContext);
	const [state, setState] = useState(null);

	useEffect(() => {
		let list = [];
		let taxonomy = "attribute-" + attribute.id;

		let isIcon = false;

		for (let item in attribute.terms) {
			let term = attribute.terms[item];

			if(term.icon) {
				isIcon = true;
			}

			let clickEvent = () => {
				setS(s => {
					return {
						...s, attributes: {
							...s.attributes,
							[taxonomy]: {
								id: term.term_id,
								name: term.name
							}
						}
					};
				});
			};

			list.push(<div key={item} className={styles.term} onClick={clickEvent}>{term.icon ? <img style={{width: 40, height: 40}} src={term.icon} alt="" /> : ""}{term.name}</div>);
		}

		function clickOther() {
			setS(s => {
				return {
					...s, attributes: {
						...s.attributes,
						[taxonomy]: {
							id: false,
							name: "Другое"
						}
					}
				};
			});
		}

		list.push(<div key={"other"} className={styles.term} onClick={clickOther}>{isIcon ? <img style={{width: 40, height: 40}} src={otherIcon} alt=""/> : ""}Другое</div>);

		setState(list);
	}, [attribute, setS, s]);

	return device === "Desktop" ? (
		<div className={`${styles.popupContainer} ${styles.attribute}`} onClick={setShowPopup}>
			<PopupContainer closeEvent={setShowPopup} title={attribute.name}>
				<div className={styles.terms}>
					{state}
				</div>
			</PopupContainer>
		</div>
	) : (
		<Overlay backEvent={setShowPopup} title={attribute.name} container={overlayStyles.attributes}>
			<div className={styles.terms}>
				{state}
			</div>
		</Overlay>
	);
}