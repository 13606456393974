import React from "react";
import { useEffect, useRef, useState } from "react";
import styles from "./select.module.css";

export default function Select({ state, setState, list, type, callback }) {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();

	// Закрываем всплывающие элементы, если совершен клик вне элемента
	useEffect(() => {
		const documentClickHandler = (e) => {
			let isOver = (rf) => rf?.current?.contains(e.target);
			let checkOutside = function (flag, rp) {
				if (flag && !isOver(rp) && !isOver(rp)) {
					setIsOpen(false);
				}
			};
			// Вызываем функции передавая состояние и ref с ссылкой на элемент
			checkOutside(isOpen, ref);
		};
		isOpen && document.addEventListener("mousedown", documentClickHandler);
		return () => document.removeEventListener("mousedown", documentClickHandler);
	}, [isOpen, ref]);

	return (
		<div className={`${styles.wrapper} ${type === "sort" ? styles.sort : ""} ${isOpen ? styles.open : ""}`} ref={ref}>
			<div className={styles.selected} onClick={() => setIsOpen(s => !s)}>{state.name}</div>
			<div className={styles.list}>
				{list.map(item =>
					<div
						key={item.value}
						className={styles.item}
						onClick={() => {
							setState(item);
							callback();
							setIsOpen(false);
						}}
					>
						{item.name}
					</div>
				)}
			</div>
		</div >
	);
}