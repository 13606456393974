import React from "react";
import accountIcon from "assets/images/User-blue.svg";
import { useAuth } from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function AccountButton({ setShowUserPopup, styles }) {
	const { user } = useAuth();
	const navigate = useNavigate();

	const clickHandler = (user) => {
		user ? navigate("/profile") : setShowUserPopup(true);
	};

	return (
		<div className={styles.accountButton} onClick={() => { clickHandler(user); }}><img src={accountIcon} alt='Icon' />Профиль</div>
	);
}