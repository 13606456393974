import { MainContext } from "context/MainContext";
import React, { useContext } from "react";

export default function BottomMenuLink({ isActive, item }) {
	const user = JSON.parse(localStorage.getItem("user"));
	const { profileAvatar } = useContext(MainContext);

	return (
		<>
			<div style={{ width: 20, height: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
				{user && profileAvatar && item.link === "/profile" ? <img src={profileAvatar} alt="" style={{ width: 20, height: 20, borderRadius: 10 }} /> : <img style={{ width: "fit-content", height: "fit-content" }} src={isActive ? item.activeIcon : item.icon} alt='Icon' />}
			</div>
			{item.name}
		</>
	);
}