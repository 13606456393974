import React from "react";
import styles from "./button.module.css";

export default function Button({ icon, name, color, onClick, type, className, showPreloader, s }) {
	let style;

	switch (color) {
	case "blue":
		style = {
			background: "var(--main-color-blue)",
			color: "var(--main-color-white)",
		};
		break;
	case "green":
		style = {
			background: "var(--main-color-green)",
			color: "var(--main-color-white)",
		};
		break;
	case "white":
		style = {
			background: "var(--main-color-white)",
			color: "var(--main-color-black)",
			border: "1px solid var(--main-color-gray2)"
		};
		break;
	case "gray":
		style = {
			background: "var(--main-color-gray)",
			color: "var(--main-color-black)",
		};
		break;
	case "disable":
		style = {
			background: "var(--main-color-gray)",
			color: "var(--main-color-placeholder)",
		};
		break;
	default:
		style = {
			background: "var(--main-color-blue)",
			color: "var(--main-color-white)",
		};
	}

	return (
		<button
			type={type ? type : "button"}
			style={{...style, ...s}}
			className={`${styles.button} ${showPreloader ? ` ${styles.loading}` : ""} ${className ? ` ${className}` : ""} ${color ? `button-${color}` : ""}`}
			disabled={showPreloader ? true : false}
			onClick={onClick}
		>
			{icon && <img src={icon} alt='Icon' />}
			{name}
			{showPreloader && <div className={styles.preloader}></div>}
		</button>
	);
}