import React from "react";
import AdsList from "components/ads/AdsList";
import styles from "./category.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import { DeviceContext } from "context/DeviceContext";
import AdsLast from "components/ads/AdsLast";
import { Link, useLoaderData } from "react-router-dom";

// import function to register Swiper custom elements
import { register as registerSwiper } from "swiper/element/bundle";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

import questionIcon from "assets/images/question.svg";
import CategoryPopup from "components/popups/Category";
import CategoryHeader from "components/header/parts/Mobile/Parts/CategoryHeader";
import { FiltersDrawerContext } from "routes/main";


export default function CategoryPage() {
	const { category, categories, subcategory, breadcrumbs, selectedCategory } = useLoaderData();
	const device = useContext(DeviceContext);
	const filtersDrawer = useContext(FiltersDrawerContext);

	const [showPopup, setShowPopup] = useState(false);
	const swiperElRef = useRef(null);
	const swiperBalloons = useRef(null);

	useEffect(() => {
		if (subcategory) {
			swiperElRef.current.swiper.update();
			swiperElRef?.current?.addEventListener("touchstart", e => e.stopPropagation());
			swiperBalloons.current.swiper.update();
			swiperBalloons?.current?.addEventListener("touchstart", e => e.stopPropagation());
		}
	}, [subcategory, selectedCategory]);

	// фикс бага с ontouch ивентом
	useEffect(() => {
		if (swiperElRef) {
			swiperElRef?.current?.addEventListener("touchstart", e => e.stopPropagation());
		}
	}, [swiperElRef]);

	// фикс бага с ontouch ивентом
	useEffect(() => {
		if (swiperBalloons) {
			swiperBalloons?.current?.addEventListener("touchstart", e => e.stopPropagation());
		}
	}, [swiperBalloons]);

	// register Swiper custom elements
	registerSwiper();

	return (
		<>
			{device === "Mobile" && <CategoryHeader />}
			<div className={`container ${styles.container}`}>
				<div className={styles.left}>
					<div className={styles.categoryWrapper}>
						<div className={styles.top}>
							{device === "Desktop" && <Breadcrumbs
								current={subcategory?.name || category.name}
								links={[
									{
										to: "/",
										name: "Главная"
									},
									...breadcrumbs
								]}
							/>}
							<div className={styles.topContent}>
								<h2 className={styles.title}>{category.name}</h2>
								{category.description && <div className={styles.descriptionButton} onClick={() => setShowPopup(true)}><img src={questionIcon} alt="question" /></div>}
							</div>
						</div>
						{category.description && showPopup && <CategoryPopup title={category.name} closeEvent={() => setShowPopup(false)} description={category.description} />}
						<div className={styles.categories}>
							<swiper-container class={styles.baloons} space-between="8" slides-per-view="auto" ref={swiperBalloons}>
								{categories.map(cat =>
									<swiper-slide
										class={`${styles.baloon} ${selectedCategory === cat ? styles.active : ""}`}
										key={cat.term_id}
									>
										<Link to={cat.link}>{cat.name}</Link>
									</swiper-slide>
								)}
							</swiper-container>
							{subcategory && <swiper-container class={styles.subcategories} space-between="8" slides-per-view="auto" ref={swiperElRef}>
								{selectedCategory?.children.map(subcat =>
									<swiper-slide class={styles.subcategory} key={subcat.term_id}>
										<Link
											className={styles.subcategoryLink}
											to={subcat.link}
										>
											{subcat.image ?
												<img src={subcat.image} alt={subcat} className={styles.subcategoryImage} /> :
												<div className={styles.placeholder}>Нет изображения</div>
											}
											<div className={styles.subcategoryName}>{subcat.name}</div>
										</Link>
									</swiper-slide>
								)}
							</swiper-container>}
						</div>
					</div>
					{<AdsList category={selectedCategory ? selectedCategory?.term_id : category?.term_id} parentStyles={styles} propSort={filtersDrawer.sort} />}
				</div>
				{
					device === "Desktop" &&
					<div className={styles.right}>
						<AdsLast styles={styles} />
					</div>
				}
			</div>
		</>
	);
}