import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import dotsIcon from "assets/images/dots-header.svg";
import starEmptyIcon from "assets/images/star-empty.svg";
import starFillIcon from "assets/images/star-fill.svg";
import styles from "../profile.module.css";
import ReviewAnswer from "components/popups/ReviewAnswer";
import DeleteReview from "components/popups/DeleteReview";
import { ReviewsContext } from "../ProfileReviews";
import { DeviceContext } from "context/DeviceContext";

export function _getMonth(month) {
	switch(month) {
	case 0: 
		return "января";
			
	case 1: 
		return "февраля";
				
	case 2: 
		return "марта";
			
	case 3: 
		return "апреля";
				
	case 4: 
		return "мая";
			
	case 5: 
		return "июня";
				
	case 6: 
		return "июля";
			
	case 7: 
		return "августа";
				
	case 8: 
		return "сентября";
			
	case 9: 
		return "октября";
				
	case 10: 
		return "октября";
			
	case 11: 
		return "декабря";
	}
}

export default function ProfileReviewCard({item, isMy, setListState, isUser = false, fullOpen = false, isUserReviews = false}) {
	const [showMenu, setShowMenu] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState();
	const ref = useRef(null);
	const localUser = JSON.parse(localStorage.getItem("user"));
	const [state, setState] = useState(item);

	const textRef = useRef(null);
	const [textHide, setTextHide] = useState(false);
	const [hiddeble, setHiddeble] = useState(false);

	const {path} = useContext(ReviewsContext);

	const navigate = useNavigate();

	const device = useContext(DeviceContext);
	const isMobile = device === "Mobile";

	useEffect(() => {
		if(!fullOpen) {
			if(Math.round(textRef.current.offsetHeight / 16 * 1.4) > 3 || state.review.answers.length !== 0) {
				setTextHide(true);
				setHiddeble(true);
			}
		}
	}, [textRef, state]);

	useEffect(() => {
		const documentClickHandler = (e) => {
			let isOver = (rf) => rf?.current?.contains(e.target);
			let checkOutside = function (flag, rp) {
				if (flag && !isOver(rp) && !isOver(rp)) {
					setShowMenu(false);
				}
			};
			// Вызываем функции передавая состояние и ref с ссылкой на элемент
			checkOutside(showMenu, ref);
		};
		showMenu && document.addEventListener("mousedown", documentClickHandler);
		return () => document.removeEventListener("mousedown", documentClickHandler);
	}, [showMenu, ref]);
	
	return (
		<>
			<div style={{ borderRadius: 12, padding: isMobile ? 12 : 16, border: "1px solid var(--main-color-gray)", display: "flex", flexDirection: "column", gap: isMobile ? 8 : 12, position: "relative", background: "var(--main-color-white)", height: isUser ? "100%" : "auto"}} >
				<div style={{ display: "flex", gap: 12, height: isMobile ? 42 : 48}} >
					<Link to={`/user/${state.author.id}`}>
						<img src={state.author.avatar} alt="" style={{ width: isMobile ? 42 : 48, height: isMobile ? 42 : 48, borderRadius: 24, objectFit: "cover" }} />
					</Link>
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", flex: 1 }} >
						<Link to={`/user/${state.author.id}`} style={{ ontWeight: 600, lineHeight: "140%", fontSize: isMobile ? 13 : 16 }} >
							{state.author.name}
						</Link>
						<div style={{ fontWeight: 300, lineHeight: "140%", color: "var(--main-color-placeholder)", fontSize: isMobile ? 13 : 16  }} >
							{
								(() => {
									let d = new Date(Date.parse(state.review.date));
									return `${d.getDate()} ${_getMonth(d.getMonth())}, в ${(d.getHours()).toString().length === 1 ? 0 : ""}${(d.getHours())}:${(d.getMinutes()).toString().length === 1 ? 0 : ""}${(d.getMinutes())}`;
								})()
							}
						</div>
					</div>
					{!isUser && <img src={dotsIcon} alt="" style={{width: 20, height: 20, alignSelf: "center", cursor: "pointer"}} onClick={() => setShowMenu(true)}/>}
				</div>
				<div>
					<div style={{display: "flex", gap: isMobile ? 4 : 7, alignItems: "center"}}>
						{[1, 2, 3, 4, 5].map((value) => (
							<img
								key={value}
								src={value <= state.review.rating ? starFillIcon : starEmptyIcon}
								alt=""
								style={{width: isMobile ? 10 : 16, height: isMobile ? 10 : 16}}
							/>
						))}
						<div style={{fontWeight: 300, lineHeight: 1.4, color: "var(--main-color-placeholder)", fontSize: isMobile ? 12 : 16}}>{state.review.status === "complete" ? "Сделка состоялась" : "Сделка не состоялась"}</div>
					</div>
					<Link to={`/ads/${state.ads.id}`} style={{fontWeight: 500, lineHeight: 1.4, color: "var(--main-color-placeholder)", fontSize: isMobile ? 12 : 16}}>{state.ads.title}</Link>
				</div>
				<div style={{overflowY: "auto", maxHeight: 400, display: "flex", flexDirection: "column", gap: 12}}>
					<div style={{display: "flex", flexDirection: "column", gap: 4}}>
						<h2 style={{fontSize: isMobile ? 13 : 16, fontWeight: 600, lineHeight: 1.4, margin: 0}}>Комментарий</h2>
						<div ref={textRef} style={{fontSize: isMobile ? 13 : 16, fontWeight: 300, lineHeight: 1.4, display: textHide ? "-webkit-box" : "block", overflow: "hidden"}} className={styles.comment}>{state.review.comment}</div>
					</div>
					{!textHide && state.review.answers.map(answer =>
						<div key={answer.id}>
							<h2 style={{fontSize: isMobile ? 13 : 16, fontWeight: 600, lineHeight: 1.4, margin: 0}}>{parseInt(answer.recipient_id) === parseInt(localUser?.id) ? isUser ? "Ответ комментатора" : "Ваш ответ" : "Ответ пользователя"}</h2>
							<div style={{fontSize: isMobile ? 13 : 16, fontWeight: 300, lineHeight: 1.4}}>{answer.comment}</div>
						</div>	
					)}
				</div>
				{hiddeble && <div onClick={() => navigate(`${path}/${state.review.id}`, {preventScrollReset: !isUser, replace: !isUser || isUserReviews})} style={{color: "var(--main-color-blue)", fontWeight: 600, textDecoration: "underline", cursor: "pointer", fontSize: isMobile ? 13 : 16}}>{textHide ? "Раскрыть" : "Скрыть"}</div>}
				{showMenu && !isUser && 
					<div style={{position: "absolute", top: 16, right: 16, background: "var(--main-color-white)", boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.15)", borderRadius: 8, padding: "8px 0", width: 160, fontSize: isMobile ? 13 : 16}} ref={ref}>
						{!fullOpen && <Link className={styles.reviewMenuItem} to={`${path}/${state.review.id}`} preventScrollReset={!isUser} replace={!isUser || isUserReviews}>Раскрыть</Link>}
						<div className={styles.reviewMenuItem} onClick={() => {
							setShowPopup(true);
							setShowMenu(false);
						}}>Ответить</div>
						{!isMy && <Link to={`/user/${state.author.id}`}  className={styles.reviewMenuItem}>Пользователь</Link>}
						{isMy && <div className={styles.reviewMenuItem} onClick={() => {
							setShowDeletePopup(true);
							setShowMenu(false);
						}}>Удалить отзыв</div>}
					</div>
				}
			</div>
			{showPopup && <ReviewAnswer id={state.review.id} setShowPopup={setShowPopup} setItemState={setState} setListState={setListState} />}
			{isMy && showDeletePopup && <DeleteReview id={state.review.id} setShowPopup={setShowDeletePopup} setListState={setListState} />}
		</>
	);
}