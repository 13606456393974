import React from "react";
import styles from "../header-mobile.module.css";
import notificationIcon from "assets/images/notification-blue.svg";
import { Link } from "react-router-dom";

export default function ProfileHeader({ title }) {
	return (
		<header className={styles.header}>
			<div className={`${styles.headerContainer} ${styles.line}`} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
				<h2 className={`${styles.headerTitle} ${styles.blue}`}>{title}</h2>
				<Link to="/profile/notifications"><img src={notificationIcon} alt="" style={{padding: 10}}/></Link>
			</div>
		</header>
	);
}